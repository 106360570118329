<template>
  <div
    v-if="directions"
    class="container"
  >
    <div class="d-flex justify-content-between align-items-center mb-5">
      <h1 class="h2 font-weight-bold">Направления курсов</h1>

      <div
        v-if="hasChanges"
        class="d-flex"
      >
        <button
          class="btn btn-outline-secondary me-2"
          @click="$store.dispatch('directions/cancelCurrentDirectionsChanges')"
        >
          Отменить
        </button>
        <button
          :disabled="hasErrors"
          class="btn btn-primary"
          @click="saveChanges"
        >
          Сохранить
        </button>
      </div>
    </div>

    <div
      v-if="canEdit"
      class="row mb-5"
    >
      <DirectionsCreateForm class="col-6" />
    </div>

    <div class="row">
      <DirectionsList class="col-6" />
    </div>
    <p
      v-if="!canEdit"
      class="text-muted"
    >
      У вас нет прав на создание и редактирование направлений. Если вам нужно
      добавить, удалить или изменить направление, обратитесь в отдел разработки.
    </p>
  </div>
  <div
    v-else
    class="loader text-center"
  >
    <b-spinner />
  </div>
</template>

<script>
import DirectionsCreateForm from '@/components/directions/DirectionsCreateForm';
import DirectionsList from '@/components/directions/DirectionsList';
import { makeToast } from '../../custom_functions/custom_functions';

export default {
  name: 'DirectionsIndex',

  components: { DirectionsCreateForm, DirectionsList },

  computed: {
    directions() {
      return this.$store.getters['directions/getCurrentDirections'];
    },

    canEdit() {
      return this.$store.getters['profile/isAdmin'];
    },

    hasChanges() {
      return this.$store.getters['directions/getHasChanges'];
    },

    hasErrors() {
      return this.$store.getters['directions/getHasErrors'];
    },
  },

  created() {
    this.$store.dispatch('directions/fetchDirections');
    this.$store.dispatch('profile/fetchCurrentUser');
  },

  methods: {
    async saveChanges() {
      this.$store.dispatch('directions/validateAllDirections');
      if (!this.hasErrors) {
        await this.$store.dispatch('directions/editDirections');
        makeToast('Изменения сохранены', 'success');
      }
    },
  },
};
</script>
