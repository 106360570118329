<template>
  <div
    v-if="questions.length"
    class="w-50"
  >
    <div
      v-for="(question, index) in questions"
      :key="index"
      class="mb-4"
    >
      <div>
        <span
          v-if="question.author.full_name"
          class="fw-bold mb-3 me-3"
        >
          {{ question.author.full_name }}
        </span>
        <a
          v-if="question.author.email"
          :href="`mailto:${question.author.email}`"
          class="text-muted"
        >
          {{ question.author.email }}
        </a>
      </div>
      <p>{{ question.text }}</p>
    </div>
  </div>
  <div v-else>
    <span>Нет вопросов</span>
  </div>
</template>

<script>
export default {
  name: 'WebinarsQuestions',

  props: { questions: Array },
};
</script>
