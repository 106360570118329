import moment from 'moment';

function formatCount(value, words) {
  if (!isNaN(value) && words && words.length === 3) {
    let result = '';
    if (value % 10 === 1 && Math.floor((value % 100) / 10) !== 1)
      result = words[0];
    else if (
      value % 10 > 1 &&
      value % 10 <= 4 &&
      Math.floor((value % 100) / 10) !== 1
    )
      result = words[1];
    else result = words[2];
    return `${value} ${result}`;
  }
}

export function formatTariffsCount(value) {
  return formatCount(value, ['тариф', 'тарифа', 'тарифов']);
}

export function formatPublishedCount(value) {
  return formatCount(value, ['опубликован', 'опубликовано', 'опубликовано']);
}

export function formatCoursesCount(value) {
  return formatCount(value, ['курс', 'курса', 'курсов']);
}

export function formatDateTimeInterval(startDate, endDate) {
  if (startDate && endDate) {
    moment.locale('ru');
    const mStartDate = moment(startDate).utcOffset('GMT+03:00');
    const mEndDate = moment(endDate).utcOffset('GMT+03:00');

    if (
      mStartDate.date() === mEndDate.date() &&
      mStartDate.month() === mEndDate.month() &&
      mStartDate.hour() === mEndDate.hour() &&
      mStartDate.minute() === mEndDate.minute()
    )
      return mEndDate.format('D MMM HH:mm');

    if (
      mStartDate.date() === mEndDate.date() &&
      mStartDate.month() === mEndDate.month()
    ) {
      return `${mEndDate.format('D MMMM')} ${mStartDate.format(
        'HH:mm',
      )} — ${mEndDate.format('HH:mm')}`;
    }

    if (
      mStartDate.hour() === 0 &&
      mStartDate.minute() === 0 &&
      mEndDate.hour() === 0 &&
      mEndDate.minute() === 0
    )
      return `${mStartDate.format('D MMMM')} — ${mEndDate.format('D MMMM')}`;
    return `${mStartDate.format('D MMMM HH:mm')} — ${mEndDate.format(
      'D MMMM HH:mm',
    )}`;
  }
  return '';
}

export function formatDateWithoutYear(value) {
  if (value) {
    moment.locale('ru');
    return moment(value).utcOffset('GMT+03:00').format('DD MMMM HH:mm');
  }
  return '';
}
