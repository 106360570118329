import validateNotEmptyField from './validate_not_empty_field';
import validateUniqueValue from './validate_unique_value';

export default function validateUniqueName(
  value,
  { otherNames = [] } = { otherNames: [] },
) {
  let errors = validateNotEmptyField(value);
  if (!errors.length)
    errors = validateUniqueValue(value, { otherValues: otherNames });
  return errors;
}
