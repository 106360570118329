import { apiClient } from '@/custom_functions/api_client';

export default {
  namespaced: true,

  state: { lesson: null },

  getters: {
    getLesson: (state) => state.lesson,

    getStep: (state) => (id) =>
      state.lesson && state.lesson.steps
        ? state.lesson.steps.find((step) => +step.id === +id)
        : null,
  },

  actions: {
    async fetchLesson({ commit }, payload) {
      let response = await apiClient.get(
        `/v2/courses/${payload.courseUrl}/units/${payload.unitId}/lessons/${payload.id}`,
      );
      commit('updateLesson', response.data.lesson);
    },
  },

  mutations: {
    updateLesson(state, lesson) {
      state.lesson = lesson;
    },

    updateStep(state, newStep, _id) {
      state.lesson = {
        ...state.lesson,
        steps: state.lesson.steps.map((step) =>
          +step.id === +newStep.id ? newStep : step,
        ),
      };
    },
  },
};
