import validateNotEmptyField from './helpers/validate_not_empty_field';

export default function validateParticipation(participation) {
  let result = {};

  if (!participation) {
    // TODO: Throw exeption if value null or undefined
    result.participation = ['Нельзя добавить курс без данных'];
    return result;
  }

  const courseId = validateNotEmptyField(participation.courseId);
  if (courseId.length) result.courseId = courseId;

  const userId = validateNotEmptyField(participation.userId);
  if (userId.length) result.userId = userId;

  return result;
}
