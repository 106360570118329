<template>
  <div
    v-if="campaigns"
    class="container"
  >
    <div class="mb-4">
      <div class="d-flex no-block align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <h2 class="mb-0 me-4">Промокоды</h2>

          <form
            class="input-group campaign-search"
            @submit.prevent="getCampaigns"
          >
            <input
              v-model="search"
              placeholder="Поиск по названию, промокоду, курсу"
              type="search"
              class="form-control"
            />
            <button
              type="submit"
              class="btn btn-primary"
            >
              Найти
            </button>
          </form>
        </div>

        <router-link
          class="btn btn-primary"
          to="/promo_codes/new"
        >
          Добавить
        </router-link>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table table-hover table-responsive-lg">
        <thead>
          <tr>
            <th scope="col">Название</th>
            <th scope="col">Скидка</th>
            <th scope="col">Курсы</th>
            <th scope="col">Период действия (мск)</th>
            <th
              scope="col"
              class="text-center"
            >
              Использовано
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(campaign, index) in campaigns"
            :key="index"
            :class="{ 'text-muted': checkDisabledCampaign(campaign) }"
          >
            <td>
              <router-link
                :to="{ name: 'promo_codes_edit', params: { id: campaign.id } }"
              >
                {{ campaign.name }}
              </router-link>
            </td>
            <td>{{ formatDiscount(campaign.discount, campaign.unit) }}</td>
            <td>{{ formatCampaignCourses(campaign.courses) }}</td>
            <td>{{ formatCampaignPeriod(campaign) }}</td>
            <td class="text-center">{{ formatPromoCodesUsage(campaign) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <b-pagination
      v-if="totalPages > 1"
      v-model="$route.params.page"
      :total-rows="campaigns.length * totalPages"
      :per-page="campaigns.length"
      class="position-sticky"
      style="bottom: 10px"
      @change="goToPage($event)"
    />
  </div>

  <div
    v-else
    class="loader text-center"
  >
    <b-spinner />
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'PromoCodesIndex',

  data() {
    return {
      campaigns: null,
      search: '',
      coursesCount: 0,
      totalPages: null,
    };
  },

  created() {
    this.init();
  },

  methods: {
    async init() {
      await this.getCampaigns();
    },

    async getCampaigns() {
      let params = {};
      params.page = this.$route.params.page;
      params.search = this.search;

      let response = await this.$apiClient.get('v2/campaigns', { params });

      this.campaigns = response.data.campaigns;
      this.coursesCount = response.data.meta.courses_count;
      this.totalPages = response.data.meta.total_pages;
    },

    formatCampaignCourses(courses) {
      if (courses.length === this.coursesCount) {
        return 'Все';
      } else if (courses.length > 1) {
        return 'Несколько';
      } else if (courses.length === 1) {
        return courses[0].name;
      }
      return '';
    },

    checkDisabledCampaign(campaign) {
      return (
        (campaign.end_date &&
          new Date().setDate(new Date().getDate() - 1) >
            new Date(campaign.end_date)) || // eslint-disable-line no-mixed-operators
        (campaign.usage_count !== 0 &&
          campaign.usage_count >= campaign.global_limit)
      ); // eslint-disable-line no-mixed-operators
    },

    async goToPage(page) {
      if (this.$route.params.page != page) {
        await this.$router.replace({ params: { page: page } });
        this.getCampaigns();
      }
    },

    formatDiscount(discount, unit) {
      if (discount) {
        return discount + (unit === 'rubles' ? '₽' : '%');
      }
      return '';
    },

    formatCampaignPeriod(campaign) {
      if (campaign.start_date && campaign.end_date) {
        moment.locale('ru');
        const startDate = moment(campaign.start_date).format('D MMM YYYY');
        const endDate = moment(campaign.end_date).format('D MMM YYYY');
        return `${startDate} - ${endDate}`;
      } else if (campaign.end_date) {
        const endDate = moment(campaign.end_date).format('D MMM YYYY');
        return `до ${endDate}`;
      }
      return 'Бессрочно';
    },

    formatPromoCodesUsage(campaign) {
      if (campaign) {
        if (campaign.global_limit) {
          return `${campaign.usage_count} / ${campaign.global_limit}`;
        }
        return campaign.usage_count;
      }
    },
  },
};
</script>

<style scoped>
.campaign-search {
  width: 400px;
}
</style>
