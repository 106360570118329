const statuses = [
  { value: 'published', title: 'Опубликован' },
  { value: 'draft', title: 'Черновик' },
  { value: 'hidden', title: 'Приватный' },
  { value: 'archived', title: 'В архиве' },
];

export function getPublicationStatuses() {
  return statuses.map((status) => status.value);
}

export function getPublicationStatusesForSelect(payload = {}) {
  if (payload.except) {
    const allStatuses = statuses.map((status) => ({
      value: status.value,
      name: status.title,
    }));
    return allStatuses.filter(
      (status) => !payload.except.includes(status.value),
    );
  }
  return statuses.map((status) => ({
    value: status.value,
    name: status.title,
  }));
}

export function formatPublicationStatus(value) {
  const currentStatus = statuses.find((status) => status.value === value);
  return currentStatus ? currentStatus.title : '';
}
