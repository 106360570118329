<template>
  <b-pagination
    v-if="totalPages > 1"
    v-model="route.query.page"
    :total-rows="itemsCount * totalPages"
    :per-page="itemsCount"
    class="position-sticky ms-3 pagination"
    @change="goToPage"
  />
</template>

<script setup>
import { defineProps } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

defineProps({
  totalPages: { default: null },
  itemsCount: { default: null },
  disabled: { type: Boolean, default: false },
});

function goToPage(page) {
  if (Number(route.params.page) !== Number(page))
    router.push({ query: { ...route.query, page } });
}
</script>

<style>
.pagination {
  bottom: 10px;
  left: 16px;
  z-index: 1;
}
</style>
