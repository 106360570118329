<template>
  <div class="row">
    <div class="mb-3 col-6">
      <label
        for="file-name"
        class="mb-2"
      >
        Имя файла
      </label>
      <input
        id="file-name"
        v-model="report.name"
        type="text"
        class="form-control"
      />
    </div>
    <div class="mb-3 col-4">
      <label
        for="cron"
        class="mb-2"
      >
        Периодичность(в cron-формате)
      </label>
      <input
        id="cron"
        v-model.lazy="report.cron"
        type="text"
        class="form-control"
        :class="cronClasses.inputClass"
      />
      <label
        v-if="report.cron"
        :class="cronClasses.feedbackClass"
        for="cron"
      >
        {{ parsedCron }}
      </label>
    </div>
    <div class="mb-3 col-12">
      <label
        for="sql"
        class="mb-2"
      >
        SQL запрос
      </label>
      <pre><textarea id="sql" v-model="report.sql" class="form-control"/></pre>
    </div>
  </div>
</template>

<script>
import { toString } from 'cronstrue/i18n';
export default {
  name: 'ReportsForm',

  props: { report: Object },

  computed: {
    parsedCron() {
      return toString(this.report.cron, {
        locale: 'ru',
        throwExceptionOnParseError: false,
      });
    },

    cronClasses() {
      if (this.report.cron) {
        if (this.parsedCron.includes('ошибка'))
          return {
            inputClass: 'is-invalid',
            feedbackClass: 'invalid-feedback',
          };
        else return { inputClass: 'is-valid', feedbackClass: 'valid-feedback' };
      } else return { inputClass: null, feedbackClass: null };
    },
  },
};
</script>
