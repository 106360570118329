<template>
  <div>
    <div class="mb-3">
      <label
        for="step-regexp"
        class="mb-2"
      >
        Верный ответ:
      </label>
      <textarea
        id="step-regexp"
        v-model="step.data.regexp"
        class="form-control"
        placeholder="Ответ"
      />
    </div>

    <div class="form-check">
      <input
        id="step-use-regexp"
        v-model.lazy="step.data.settings.use_regexp"
        class="form-check-input"
        type="checkbox"
      />
      <label
        class="form-check-label"
        for="step-use-regexp"
      >
        Проверять ответ студента с помощью регулярного выражения
      </label>
    </div>
    <div class="form-check">
      <input
        id="step-ignore-case"
        v-model.lazy="step.data.settings.ignore_case"
        class="form-check-input"
        type="checkbox"
      />
      <label
        class="form-check-label"
        for="step-ignore-case"
      >
        Не различать прописные и заглавные буквы
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextTaskStep',

  props: { step: { type: Object, required: true } },
};
</script>
