<template>
  <span>
    <button
      v-for="(step, index) in lesson.steps"
      :key="step.id"
      class="btn btn-sm me-2 step-nav-btn"
      :class="buttonStepClass(step.id)"
      @click="toOtherStep(step.id)"
    >
      {{ index + 1 }}
    </button>
    <template v-if="!stepChanged">
      <button
        class="btn btn-outline-primary btn-sm me-2 step-nav-btn"
        @click="showModalCreateStep = true"
      >
        <font-awesome-icon icon="plus" />
      </button>

      <b-modal
        v-model="showModalCreateStep"
        hide-footer
        title="Выберите тип нового шага"
      >
        <div class="d-grid gap-2">
          <b-button
            v-for="(type, index) in stepTypes"
            :key="index"
            block
            variant="outline-primary"
            @click="newStep(type)"
          >
            {{ formatStepType(type) }}
          </b-button>
          <b-button
            block
            variant="outline-secondary"
            @click="showModalCreateStep = false"
          >
            Отменить
          </b-button>
        </div>
      </b-modal>

      <button
        v-if="lesson.steps.length > 1"
        class="btn btn-outline-primary ms-2"
        @click="showModalChangeOrder = true"
      >
        Изменить порядок
      </button>

      <b-modal
        v-model="showModalChangeOrder"
        hide-footer
        title="Порядок шагов"
      >
        <draggable
          v-model:model-value="stepsOrder"
          item-key="id"
        >
          <template #item="{ element }">
            <div class="card mb-2 d-flex flex-row align-items-center p-4">
              <font-awesome-icon
                icon="grip-vertical"
                class="text-muted drag-icon me-3"
              />
              <span>
                {{ element.index + 1 }} шаг: {{ formatStepType(element.type) }}
              </span>
            </div>
          </template>
        </draggable>
        <button
          class="btn btn-primary mt-3 btn-block"
          @click="changeStepsOrder"
        >
          Сохранить
        </button>
      </b-modal>
    </template>
  </span>
</template>

<script>
import { formatStepType, makeToast } from '@/custom_functions/custom_functions';

export default {
  name: 'StepsList',

  props: { stepChanged: { type: Boolean, default: true } },

  data() {
    return {
      showModalCreateStep: false,
      showModalChangeOrder: false,
      stepTypes: [
        'TextStep',
        'VideoStep',
        'TextTaskStep',
        'QuizStep',
        'SortStep',
        'MatchStep',
        'InteractStep',
        'DeferredStep',
      ],
      stepsOrder: null,
    };
  },

  computed: {
    lesson() {
      return this.$store.getters['lesson/getLesson'];
    },
  },

  watch: {
    showModalChangeOrder(value) {
      if (value)
        this.stepsOrder = this.lesson.steps.map((step, index) => ({
          ...step,
          index,
        }));
    },
  },

  methods: {
    formatStepType,

    buttonStepClass(id) {
      if (id == +this.$route.params.stepId)
        return this.stepChanged ? 'btn-danger' : 'btn-primary';
      return 'btn-outline-secondary';
    },

    toOtherStep(id) {
      if (id !== +this.$route.params.stepId) {
        if (this.stepChanged)
          makeToast(
            'Пожалуйста, сохраните текущий шаг для перехода к следующему.',
            'danger',
          );
        else
          this.$router.push(
            `/lessons/${this.$route.params.id}/step/${id}?course=${this.$route.query.course}&unit=${this.$route.query.unit}`,
          );
      }
    },

    async newStep(stepType) {
      let stepData;

      if (
        stepType === 'TextStep' ||
        stepType === 'VideoStep' ||
        stepType === 'DeferredStep'
      )
        stepData = null;
      else if (stepType === 'TextTaskStep')
        stepData = {
          regexp: 'answer',
          settings: { ignore_case: true, use_regexp: false },
        };
      else if (stepType === 'QuizStep')
        stepData = { variants: [], multipleAnswers: false };
      else if (stepType === 'SortStep') stepData = { variants: [] };
      else if (stepType === 'MatchStep')
        stepData = { variants: { questions: [], answers: [] } };
      else if (stepType === 'InteractStep')
        stepData = { script_check: '', settings: { show_textarea: false } };

      const response = await this.$apiClient.post(
        `/v2/lessons/${this.lesson.id}/steps`,
        { step: { type: stepType, text: '', data: stepData } },
      );
      this.$store.commit('lesson/updateLesson', {
        ...this.lesson,
        steps: [...this.lesson.steps, response.data.step],
      });

      const { id } = this.$route.params;
      const { course, unit } = this.$route.query;
      this.$router.push(
        `/lessons/${id}/step/${response.data.step.id}?course=${course}&unit=${unit}`,
      );

      this.showModalCreateStep = false;
    },

    async changeStepsOrder() {
      const steps = this.stepsOrder.map((step, index) => ({
        id: step.id,
        weight: index,
      }));
      await this.$apiClient.patch(
        `/v2/lessons/${this.lesson.id}/steps/reorder`,
        { steps },
      );
      this.$store.commit('lesson/updateLesson', {
        ...this.lesson,
        steps: this.stepsOrder,
      });
      this.showModalChangeOrder = false;
      makeToast('Новый порядок шагов успешно сохранен.', 'success');
    },
  },
};
</script>

<style>
.step-nav-btn {
  width: 33px;
  height: 31px;
}

.drag-icon {
  cursor: grab;
}
</style>
