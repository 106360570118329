<template>
  <div class="container">
    <h2 class="mb-4">Редактирование компании</h2>

    <form @submit.prevent="editCompany">
      <div class="mb-3">
        <label>Название</label>

        <input
          v-model="company.name"
          type="text"
          class="form-control"
        />
      </div>

      <button
        type="submit"
        class="btn btn-outline-primary"
      >
        Сохранить
      </button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'companies_edit', // eslint-disable-line vue/component-definition-name-casing

  data() {
    return {
      company: {},
    };
  },

  created() {
    this.getCompany();
  },

  methods: {
    async getCompany() {
      const response = await this.$apiClient.get(
        `/v2/companies/${this.$route.params.id}`,
      );

      this.company = response.data.company;
    },

    async editCompany() {
      let data = {
        company: this.company,
      };

      await this.$apiClient.patch(
        `/v2/companies/${this.$route.params.id}`,
        data,
      );

      await this.$router.replace('/companies');

      this.$root.$bvToast.toast('Компания отредактирована', {
        title: 'Уведомление',
        variant: 'primary',
        autoHideDelay: 3000,
      });
    },
  },
};
</script>
