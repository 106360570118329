<template>
  <div
    v-if="stepIndex !== null"
    class="input-group"
  >
    <div>
      <router-link
        class="btn btn-outline-primary"
        :to="preStepLink"
        :class="!preStepLink && 'disabled'"
      >
        <font-awesome-icon icon="chevron-left" />
      </router-link>
    </div>
    <span class="form-control text-center">
      Урок {{ lessonTitle }}, шаг {{ step.index }}
    </span>
    <div>
      <router-link
        class="btn btn-outline-primary"
        :to="nextStepLink"
        :class="!nextStepLink && 'disabled'"
      >
        <font-awesome-icon icon="chevron-right" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReviewerAnswersPagination',

  computed: {
    step() {
      return this.$store.getters['reviewers/getStep'];
    },

    lessonTitle() {
      return this.$store.getters['reviewers/getStep'].lesson.title;
    },

    course() {
      const reviewer = this.$store.getters['reviewers/getReviewer'];
      return reviewer ? reviewer.course : null;
    },

    courseAllSteps() {
      if (this.course) {
        let courseAllSteps = [];
        this.course.units.forEach((unit) => {
          unit.lessons.forEach((lesson) => {
            courseAllSteps = courseAllSteps.concat(lesson.steps);
          });
        });
        return courseAllSteps;
      }
      return null;
    },

    stepIndex() {
      if (this.courseAllSteps)
        return this.courseAllSteps.findIndex(
          (step) => +step.id === +this.$route.params.stepId,
        );
      return null;
    },

    nextStepLink() {
      if (
        this.stepIndex !== null &&
        this.courseAllSteps &&
        this.stepIndex + 1 < this.courseAllSteps.length
      ) {
        return `/reviewers/${this.$route.params.reviewerId}/steps/${
          this.courseAllSteps[this.stepIndex + 1].id
        }`;
      }
      return '';
    },

    preStepLink() {
      if (
        this.stepIndex !== null &&
        this.courseAllSteps &&
        this.stepIndex > 0
      ) {
        return `/reviewers/${this.$route.params.reviewerId}/steps/${
          this.courseAllSteps[this.stepIndex - 1].id
        }`;
      }
      return '';
    },
  },
};
</script>

<style></style>
