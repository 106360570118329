<template>
  <div class="container">
    <h2 class="mb-4">
      Выберите тег
      <Instruction block="main" />
    </h2>

    <select
      v-if="getTagsComplete"
      v-model="tag"
      @change="getPolicty()"
    >
      <option
        v-for="(tag, index) in tags"
        v-bind:key="index"
        v-bind:value="tag"
      >
        {{ tag }}
      </option>
    </select>

    <div
      v-if="policy_object"
      class="radio-group"
    >
      <h2 class="mb-4">Политика тега</h2>

      <div class="form-check">
        <input
          v-model="policy_object.policy"
          name="form-check-input"
          access="false"
          class="form-check-input"
          value="any_not_bought"
          type="radio"
        />
        <label class="form-check-label">Любые не купленные</label>
      </div>

      <div class="form-check">
        <input
          v-model="policy_object.policy"
          name="form-check-input"
          access="false"
          class="form-check-input"
          value="latest_not_bought"
          type="radio"
        />
        <label class="form-check-label">Только последний не купленный</label>
      </div>

      <div class="form-check">
        <input
          v-model="policy_object.policy"
          name="form-check-input"
          access="false"
          class="form-check-input"
          value="except_if_bought"
          type="radio"
        />
        <label class="form-check-label">
          Не показывать, если куплен любой курс
        </label>
      </div>

      <div class="form-check">
        <input
          v-model="policy_object.policy"
          name="form-check-input"
          access="false"
          class="form-check-input"
          value="ignore"
          type="radio"
        />
        <label class="form-check-label">Игнорировать</label>
      </div>
    </div>

    <div v-if="policy_object">
      <h2 class="mb-4">
        Исключить теги из выборки, если есть курсы по данному тегу
      </h2>

      <div
        v-for="(tag, index) in tags"
        v-bind:key="index"
        class="form-check"
      >
        <input
          v-model="policy_object.exclude"
          class="form-check-input"
          type="checkbox"
          v-bind:value="tag"
        />
        <label
          class="form-check-label"
          for="defaultCheck1"
        >
          {{ tag }}
        </label>
      </div>
    </div>

    <br />
    <button
      class="btn btn-primary"
      @click="savePolicy()"
    >
      Сохранить
    </button>
  </div>
</template>

<script>
import Instruction from '../../components/Instruction';

export default {
  name: 'TagsEdit',

  components: {
    Instruction,
  },

  data() {
    return {
      getTagsComplete: false,
      tag: null,
      tags: [],
      policy_object: null,
    };
  },

  mounted() {
    this.getTags();
  },

  methods: {
    async savePolicy() {
      let requestData = {
        policy: this.policy_object.policy,
        exclude: this.policy_object.exclude,
      };

      await this.$apiClient.post(`/v2/tags/${this.tag}/policy`, requestData);
    },

    async getTags() {
      const response = await this.$apiClient.get('/v2/tags');

      this.tags = response.data.tags.sort();
      this.getTagsComplete = true;
    },

    async getPolicty() {
      const response = await this.$apiClient.get(`/v2/tags/${this.tag}/policy`);

      this.policy_object = response.data;

      if (!response.data.id) {
        alert(
          'Настройки для данного тега не сохранены! Выберите политику и нажмите сохранить!',
        );
      }
    },
  },
};
</script>
