<template>
  <div>
    <div class="mb-3">
      <input
        v-model.lazy="limit"
        type="number"
        class="form-control w-25"
        :required="!withoutLimit"
        :disabled="withoutLimit"
        placeholder="Количество попыток"
      />
    </div>
    <div class="form-check">
      <input
        id="without-limit"
        v-model="withoutLimit"
        class="form-check-input"
        type="checkbox"
      />
      <label
        class="form-check-label"
        for="without-limit"
      >
        Не ограничивать попытки
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepAttemptsLimit',

  data() {
    return {
      withoutLimit: false,
      limit: null,
    };
  },

  props: ['modelValue'],

  created() {
    this.limit = this.modelValue;
    this.withoutLimit = !this.modelValue;
  },

  watch: {
    withoutLimit(value) {
      if (value) {
        this.limit = null;
      }
    },

    limit(value) {
      this.$emit('update:modelValue', value);
    },

    modelValue(value) {
      this.limit = value;
      this.withoutLimit = !value;
    },
  },
};
</script>
