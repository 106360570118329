import { apiClient } from '@/custom_functions/api_client';
import { validateOrder } from '@/custom_functions/validation_functions/index';
export default {
  namespaced: true,

  state: {
    tinkoffLink: null,
    errors: {},
  },

  getters: {
    getTinkoffLink: (state) => state.tinkoffLink,
    getErrors: (state) => state.errors,
  },

  actions: {
    async createOrder({ commit, dispatch, state }, order) {
      commit('updateTinkoffLink', null);
      dispatch('validateOrder', order);

      let params = {
        email: order.userEmail,
        tinkoffPromoCode: order.promoCode,
        success_url: 'https://edu.slurm.io/catalog?status=success',
        failure_url: 'https://edu.slurm.io/catalog?status=fail',
        course_ids: order.courseIds,
        tinkoffReloadLink: order.isReloadLink,
      };
      if (order.isCustomAmount) params['custom_amount'] = order.amount;

      if (!Object.keys(state.errors).length) {
        const response = await apiClient.post('v2/tinkoff/installment', params);
        commit('updateTinkoffLink', response.data.payment_url);
      }
    },

    cleanTinkoffLink({ commit }) {
      commit('updateTinkoffLink', null);
    },

    validateOrder({ commit }, order) {
      commit('updateErrors', validateOrder(order));
    },
  },

  mutations: {
    updateTinkoffLink(state, link) {
      state.tinkoffLink = link;
    },

    updateErrors(state, errors) {
      state.errors = errors;
    },
  },
};
