<template>
  <div>
    <CustomSelect
      id="course-id"
      v-model:model-value="params.course_id"
      :options="coursesOptions"
      label="Тариф"
      placeholder="Можно найти по названию"
      search
      required
      class="mb-3"
    />
  </div>
</template>

<script setup>
import { computed, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { useCustomSelectOptions } from '@/composables/use_custom_select_options';
import CustomSelect from '@/components/CustomSelect.vue';

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({}),
  },
  report: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const store = useStore();
store.dispatch('courses/fetchCoursesOmitPagination', {
  withoutContainers: true,
});

const params = ref({ ...props.modelValue });

watch(
  params.value,
  (newValue) => {
    emit('update:modelValue', newValue);
  },
  { deep: true },
);

const coursesOptions = computed(() => {
  const courses = store.getters['courses/getCoursesOmitPagination'];
  return useCustomSelectOptions(courses || [], 'id', 'name');
});
</script>
