import { apiClient } from '@/custom_functions/api_client';
import containers from '@/store/modules/courses/containers';
import course from '@/store/modules/courses/course';
import courses from '@/store/modules/courses/courses';
import product from '@/store/modules/courses/product';
import products from '@/store/modules/courses/products';
import container from '@/store/modules/courses/container';
import directions from '@/store/modules/directions';
import lesson from '@/store/modules/lesson';
import myCoursesRecommendations from '@/store/modules/my_courses_recommendations';
import notifications from '@/store/modules/notifications';
import orders from '@/store/modules/orders';
import reports from '@/store/modules/reports';
import reviewers from '@/store/modules/reviewers';
import reviews from '@/store/modules/reviews';
import speakers from '@/store/modules/speakers';
import standsSessions from '@/store/modules/standsSessions';
import support from '@/store/modules/support';
import tags from '@/store/modules/tags';
import toast from '@/store/modules/toast';
import unit from '@/store/modules/unit';
import userParticipations from '@/store/modules/userParticipations';
import userSessions from '@/store/modules/userSessions';
import users from '@/store/modules/users';
import webinars from '@/store/modules/webinars';
import profile from '@/store/modules/profile';
import { createStore } from 'vuex';

export default createStore({
  modules: {
    notifications,
    reports,
    reviews,
    support,
    userSessions,
    userParticipations,
    speakers,
    standsSessions,
    webinars,
    lesson,
    course,
    reviewers,
    tags,
    myCoursesRecommendations,
    unit,
    toast,
    directions,
    courses,
    products,
    containers,
    container,
    product,
    orders,
    users,
    profile,
  },

  state: {
    companies: null,
    currentUserRights: null,
    currentUser: null,
  },

  getters: {
    getCompanies(state) {
      return state.companies;
    },
  },

  actions: {
    async fetchCompanies({ commit }) {
      const response = await apiClient.get('/v2/companies');
      commit('updateCompanies', response.data.companies);
    },
  },

  mutations: {
    updateCompanies(state, companies) {
      state.companies = companies;
    },

    updateCurrentUser(state, user) {
      state.currentUser = user;
    },

    updateCurrentUserRights(state, rights) {
      state.currentUserRights = rights;
    },
  },
});
