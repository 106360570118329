<template>
  <div class="container-fluid">
    <div class="mb-4 d-flex justify-content-between align-items-baseline">
      <h2>Выгрузки и отчёты</h2>
      <router-link
        v-if="currentTab.showHeaderButton && canEdit"
        to="/reports/new"
        class="btn btn-primary"
      >
        Создать выгрузку
      </router-link>
    </div>

    <b-tabs
      v-model="tabValue"
      class="px-3 mb-4"
    >
      <b-tab
        v-for="tab in tabs"
        :key="tab.value"
      >
        <template #title>
          {{ tab.title }}
        </template>
      </b-tab>
    </b-tabs>
    <transition
      name="fade"
      mode="out-in"
    >
      <div :key="currentTab.value">
        <component
          :is="currentTab.component"
          ref="tabComponent"
        />
      </div>
    </transition>
  </div>
</template>

<script setup>
import { computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useRouteTabs } from '@/composables/use_route_tabs';
import ReportsTable from '@/components/reports/ReportsTable';
import SqlReportsTable from '@/components/reports/SqlReportsTable';

const store = useStore();
const route = useRoute();
const router = useRouter();

const canEdit = computed(() => store.getters['profile/isAdmin']);

onMounted(() => {
  store.dispatch('profile/fetchCurrentUser');
});

const tabs = [
  {
    value: 'sql_reports',
    title: 'Выгрузки',
    showHeaderButton: true,
    component: SqlReportsTable,
  },
  {
    value: 'reports',
    title: 'Методические отчёты',
    component: ReportsTable,
  },
];

const defaultTab = 'sql_reports';

const { tabValue } = useRouteTabs(tabs, defaultTab);
const currentTab = computed(() => tabs[tabValue.value]);

init();
watch(route, init);

function init() {
  setQuery();
}

function setQuery() {
  const query = { ...route.query };

  if (!currentTab.value) query.tab = defaultTab;

  router.replace({ query });
}
</script>
