<template>
  <div class="container">
    <h2 class="mb-4">Редактирование зоны</h2>
    <b-form>
      <b-form-group
        label="Значение"
        label-for="zone_value"
        :invalid-feedback="zoneValue.error"
        :state="zoneValue.valid"
      >
        <b-form-input
          id="zone_value"
          v-model="zoneValue.value"
          type="text"
          :state="zoneValue.valid"
          trim
          @blur="validZoneValue"
        />
      </b-form-group>
      <b-form-group
        label="Расположение"
        label-for="zone_location"
        :invalid-feedback="zoneLocation.error"
        :state="zoneLocation.valid"
      >
        <b-form-input
          id="zone_location"
          v-model="zoneLocation.value"
          type="text"
          :state="zoneLocation.valid"
          trim
          @blur="validZoneLocation"
        />
      </b-form-group>
      <b-form-group
        label="Лимит площадок"
        label-for="zone_limit"
        :invalid-feedback="zoneLimit.error"
        :state="zoneLimit.valid"
        class="mb-2"
      >
        <b-form-input
          id="zone_limit"
          v-model="zoneLimit.value"
          min="1"
          type="number"
          :state="zoneLimit.valid"
          trim
          @blur="validZoneLimit"
        />
      </b-form-group>
      <button
        class="btn btn-outline-primary"
        type="submit"
        @click.prevent="editZone"
      >
        Сохранить
      </button>
    </b-form>
  </div>
</template>
<script>
export default {
  name: 'ZonesNew',

  data() {
    return {
      zoneValue: {
        value: null,
        valid: null,
        error: null,
      },
      zoneLocation: {
        value: null,
        valid: null,
        error: null,
      },
      zoneLimit: {
        value: null,
        valid: null,
        error: null,
      },
      zone: null,
      zones: [],
    };
  },

  created() {
    this.init();
  },

  methods: {
    async init() {
      await this.getZone();
      await this.getZones();
    },

    validZoneValue() {
      if (!this.zoneValue.value)
        this.zoneValue.error = 'Значение не может быть пустым';
      else if (
        this.zone.value != this.zoneValue.value &&
        this.zones.find((zone) => {
          return zone.value == this.zoneValue.value;
        })
      ) {
        this.zoneValue.error = 'Зона с таким значением уже существует';
      } else {
        this.zoneValue.valid = true;
        return true;
      }
      this.zoneValue.valid = false;
      return false;
    },
    validZoneLocation() {
      if (!this.zoneLocation.value) {
        this.zoneLocation.valid = false;
        this.zoneLocation.error = 'Расположение не может быть пустым';
        return false;
      } else {
        this.zoneLocation.valid = true;
        return true;
      }
    },
    validZoneLimit() {
      if (!this.zoneLimit.value)
        this.zoneLimit.error = 'Лимит площадок не может быть пустым';
      else if (isNaN(this.zoneLimit.value))
        this.zoneLimit.error = 'Лимит площадок должен быть числом';
      else if (Number(this.zoneLimit.value) <= 0)
        this.zoneLimit.error = 'Лимит площадок должен быть больше нуля';
      else {
        this.zoneLimit.valid = true;
        return true;
      }
      this.zoneLimit.valid = false;
      return false;
    },

    async getZone() {
      if (this.$route.params.id) {
        const response = await this.$apiClient.get(
          `/v2/stand_zones/${this.$route.params.id}`,
        );

        this.zone = response.data.stand_zone;

        localStorage.setItem(
          'currentZone',
          JSON.stringify(response.data.stand_zone),
        );
      } else {
        this.zone = JSON.parse(localStorage.getItem('currentZone'));
      }
      this.zoneValue.value = this.zone.value;
      this.zoneLocation.value = this.zone.location;
      this.zoneLimit.value = this.zone.limit;
    },

    async editZone() {
      const validZoneValue = this.validZoneValue();
      const validZoneLocation = this.validZoneLocation();
      const validZoneLimit = this.validZoneLimit();

      if (validZoneValue && validZoneLocation && validZoneLimit) {
        let postData = {
          stand_zone: {
            value: this.zoneValue.value,
            location: this.zoneLocation.value,
            limit: this.zoneLimit.value,
          },
        };

        await this.$apiClient.patch(
          `/v2/stand_zones/${this.zone.id}`,
          postData,
        );

        await this.$router.replace('/zones');

        this.$root.$bvToast.toast('Изменения сохранены', {
          title: 'Уведомление',
          variant: 'primary',
          autoHideDelay: 3000,
        });

        localStorage.removeItem('currentZone');
      }
    },

    async getZones() {
      const response = await this.$apiClient.get('/v2/stand_zones');

      this.zones = response.data.stand_zones;
    },
  },
};
</script>
