<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ `${content} - Слёрм` }}</template>
  </metainfo>
  <div id="app">
    <AdminSidebar v-if="route.meta.requiresAuth" />

    <main
      class="app-content py-4"
      :class="{ 'app-content-auth': route.meta.requiresAuth }"
    >
      <router-view />
      <ToastsWrapper />
    </main>
  </div>
</template>

<script setup>
import AdminSidebar from '@/components/AdminSidebar';
import ToastsWrapper from '@/components/ToastsWrapper';

import { useRoute } from 'vue-router';
import { useMeta } from 'vue-meta';

const route = useRoute();

useMeta({ title: 'Админка' });
</script>

<style>
h2 {
  font-weight: 600 !important;
}

body,
#app,
.app-layout {
  height: 100%;
}

.sidebar {
  width: 240px;
  min-height: 100%;
  position: fixed;
}

.app-content {
  height: 100%;
}

.app-content-auth {
  margin-left: 240px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.loader {
  margin-top: 15%;
}

.tox-notification {
  display: none !important;
}

.v-select-style .vs__search {
  height: 28px;
}
</style>
