<template>
  <div
    v-if="conversations"
    class="border-top conversations-list list-group list-group-flush"
    @scroll="getConversationsOnScroll"
  >
    <button
      v-for="conversation in conversations"
      :key="conversation.id"
      class="border-bottom p-2 d-flex list-group-item w-100"
      :class="conversationClass(conversation)"
      @click="changeCurrentConversation(conversation.id)"
    >
      <b-avatar
        :src="conversation.user_avatar"
        class="me-2"
      />
      <div class="flex-grow-1 d-flex flex-column">
        <div class="d-flex justify-content-between align-items-baseline mb-2">
          <span class="fw-bold">
            {{ conversation.user_full_name || conversation.user_email }}
          </span>
          <span class="text-muted">
            {{ formatConversationDateTime(conversation.updated_at) }}
          </span>
        </div>
        <p class="mb-2 text-start">
          {{ conversation.last_message_text_short }}
        </p>
        <span
          class="badge rounded-pill align-self-end"
          :class="
            conversation.state === 'awaiting'
              ? 'text-bg-primary'
              : 'text-bg-light'
          "
        >
          {{ badgeText(conversation) }}
        </span>
      </div>
    </button>
    <span ref="conversations" />
  </div>
  <div
    v-else
    class="loader text-center"
  >
    <b-spinner />
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  name: 'SupportConversationsList',

  data() {
    return {
      showLoading: true,
    };
  },

  computed: {
    ...mapGetters('support', {
      conversations: 'getConversations',
      currentConversationId: 'getCurrentConversationId',
      params: 'getConversationsParams',
    }),
  },

  watch: {
    $route() {
      if (
        Number(this.$route.params.id) !== this.currentConversationId &&
        !this.$route.query.user_email
      ) {
        this.$store.dispatch(
          'support/changeCurrentConversation',
          Number(this.$route.params.id),
        );
      }
    },
    conversations: {
      handler(newValue, oldValue) {
        if (newValue && oldValue)
          this.showLoading = newValue.length !== oldValue.length;
      },
      deep: true,
    },
  },

  methods: {
    formatConversationDateTime(value) {
      moment.locale('ru');
      const conversationsDate = moment(value).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      const currentDate = moment(new Date()).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      if (conversationsDate === currentDate)
        return moment(value).format('HH:mm');
      return moment(value).format('D MMM');
    },

    badgeText(conversation) {
      let text;
      if (conversation.state === 'awaiting') text = 'Новое';
      else if (conversation.state === 'in_work')
        text = conversation.responsible_full_name || 'В работе';
      else if (conversation.state === 'resolved') text = 'Решено';
      return text;
    },
    conversationClass(conversation) {
      if (this.currentConversationId === conversation.id) return 'bg-active';
      if (conversation.state === 'awaiting') return 'bg-new';
    },

    changeCurrentConversation(id) {
      if (id !== Number(this.$route.params.id))
        this.$router.push(`/support/${id}`);
    },

    getConversationsOnScroll() {
      const list = this.$refs.conversations;
      if (list) {
        if (
          list.getBoundingClientRect().top <
          document.documentElement.clientHeight + 100
        ) {
          if (
            this.params.before !==
            this.conversations[this.conversations.length - 1].updated_at
          ) {
            let params = this.params;
            params.before =
              this.conversations[this.conversations.length - 1].updated_at;
            this.$store.dispatch('support/fetchMoreConversations', params);
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.conversations-list {
  overflow: auto;
  height: calc(100% - 100px);
}
.bg-new {
  background: rgba(61, 133, 247, 0.1);
}
.bg-active {
  background: #fbf6f2;
}
</style>
