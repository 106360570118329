<template>
  <div
    v-if="campaign"
    class="container"
  >
    <h2 class="mb-4">Редактирование промокодов</h2>

    <CampaignParams :campaign="campaign" />

    <CampaignCourses
      :courses="courses"
      :selectedCourses="selectedCourses"
      @updateCourses="update"
    />

    <CampaignPromoStatistics :campaign="campaign" />

    <div>
      <button
        class="btn btn-outline-primary me-3"
        @click.prevent="updateCampaign"
      >
        Сохранить
      </button>

      <button
        class="btn btn-outline-danger"
        @click.prevent="$bvModal.show('delete-campaign-modal')"
      >
        Удалить
      </button>

      <b-modal
        id="delete-campaign-modal"
        hide-footer
      >
        <template v-slot:modal-title>Подтвердите действие</template>
        <div class="d-block text-center">
          <h4>Кампания «{{ campaign.name }}» будет удалена</h4>
        </div>
        <b-button
          class="mt-3"
          block
          variant="danger"
          @click="deleteCampaign"
        >
          Удалить
        </b-button>
        <b-button
          class="mt-3"
          block
          @click="$bvModal.hide('delete-campaign-modal')"
        >
          Отменить
        </b-button>
      </b-modal>
    </div>
  </div>

  <div
    v-else
    class="loader text-center"
  >
    <b-spinner />
  </div>
</template>

<script>
import CampaignParams from '@/components/promo_codes/CampaignParams';
import CampaignCourses from '@/components/promo_codes/CampaignCourses';
import CampaignPromoStatistics from '@/components/promo_codes/CampaignPromoStatistics';

export default {
  name: 'PromoCodesEdit',

  components: {
    CampaignParams,
    CampaignCourses,
    CampaignPromoStatistics,
  },

  data() {
    return {
      campaign: null,
      courses: null,
      selectedCourses: [],
      promoCodes: '',
    };
  },

  created() {
    this.getCampaign();
    this.$store.dispatch('courses/fetchCoursesOmitPagination');
  },

  computed: {
    courses() {
      return this.$store.getters['courses/getCoursesOmitPagination'];
    },
  },

  methods: {
    async getCampaign() {
      let response = await this.$apiClient.get(
        `v2/campaigns/${this.$route.params.id}`,
      );

      this.campaign = response.data.campaign;

      let selectedCourses = [];

      this.campaign.courses.forEach(function (course) {
        selectedCourses.push(course.id);
      });

      this.selectedCourses = selectedCourses;
    },

    async updateCampaign() {
      if (!this.campaign.name || !this.campaign.discount) {
        return this.$root.$bvToast.toast(
          'Пожалуйста, заполните название и скидку для кампании',
          {
            title: 'Уведомление',
            variant: 'danger',
            autoHideDelay: 3000,
          },
        );
      }

      if (this.campaign.unit === 'rubles') {
        if (this.campaign.discount <= 0) {
          return this.$root.$bvToast.toast(
            'Размер скидки должен быть больше 0₽',
            {
              title: 'Уведомление',
              variant: 'danger',
              autoHideDelay: 3000,
            },
          );
        }
      } else {
        if (this.campaign.discount <= 0 || this.campaign.discount > 100) {
          return this.$root.$bvToast.toast(
            'Размер скидки должен быть в диапозоне от 1% до 100%',
            {
              title: 'Уведомление',
              variant: 'danger',
              autoHideDelay: 3000,
            },
          );
        }
      }

      let campaign = this.campaign;
      campaign.course_ids = this.selectedCourses;

      let data = {
        campaign,
      };

      await this.$apiClient.patch(
        `/v2/campaigns/${this.$route.params.id}`,
        data,
      );

      await this.$router.replace({
        name: 'promo_codes_index',
        params: { page: 1 },
      });

      this.$root.$bvToast.toast('Кампания отредактирована', {
        title: 'Уведомление',
        variant: 'primary',
        autoHideDelay: 3000,
      });
    },

    update(courses) {
      this.selectedCourses = courses;
    },

    async deleteCampaign() {
      await this.$apiClient.delete(`v2/campaigns/${this.$route.params.id}`);
      await this.$router.replace({
        name: 'promo_codes_index',
        params: { page: 1 },
      });
    },
  },
};
</script>
