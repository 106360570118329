import { apiClient } from '../../custom_functions/api_client';
import Consumer from '../../custom_functions/action_cable';

export default {
  namespaced: true,

  state: {
    conversations: null,
    conversationsCunsomer: null,
    conversationsParams: null,
    currentConversationId: null,
    messages: null,
    messagesCurrentPage: null,
    messagesTotalPages: null,
    messagesCunsomer: null,
  },

  getters: {
    getConversations(state) {
      return state.conversations;
    },

    getMessages(state) {
      return state.messages;
    },

    getCurrentConversation(state) {
      if (state.conversations)
        return state.conversations.find(
          (conversation) => conversation.id === state.currentConversationId,
        );
      else null;
    },

    getCurrentConversationId(state) {
      return state.currentConversationId;
    },

    getConversationsParams(state) {
      return state.conversationsParams;
    },

    getMessagesCurrentPage(state) {
      return state.messagesCurrentPage;
    },

    getMessagesTotalPages(state) {
      return state.messagesTotalPages;
    },
  },

  actions: {
    async fetchConversations({ commit }, params) {
      const response = await apiClient.get('v2/conversations', { params });
      commit('updateConversationsParams', params);
      commit('updateConversations', response.data.conversations);
    },

    async fetchMoreConversations({ commit }, params) {
      const response = await apiClient.get('v2/conversations', { params });
      commit('updateConversationsParams', params);
      commit('addConversations', response.data.conversations);
    },

    connectConversationsCunsomer({ commit, state }) {
      commit(
        'updateConversationsCunsomer',
        new Consumer(
          (message) => {
            commit('updateFirstConversation', message.conversation);
          },
          { channel: 'ConversationsChannel' },
        ),
      );
      state.conversationsCunsomer.connect();
    },

    disconnectConversationsCunsomer({ commit, state }) {
      state.conversationsCunsomer.disconnect();
      commit('updateConversationsCunsomer', null);
    },

    async fetchMessages({ commit }, id) {
      const response = await apiClient.get(
        `v2/conversations/${id}/support_messages`,
        { params: { page: 1 } },
      );
      commit('updateMessages', response.data.messages);
      commit('updateMessagesPage', response.data.meta);
    },

    async fetchMoreMessages({ commit, state }, id) {
      if (state.messagesCurrentPage + 1 <= state.messagesTotalPages) {
        const response = await apiClient.get(
          `v2/conversations/${id}/support_messages`,
          { params: { page: state.messagesCurrentPage + 1 } },
        );
        commit('addMessages', response.data.messages);
        commit('updateMessagesPage', response.data.meta);
      }
    },

    async changeCurrentConversation({ dispatch, commit, state }, id) {
      let conversations = state.conversations;
      if (conversations && id) {
        while (
          !conversations.find((conversation) => conversation.id === id) &&
          state.conversationsParams.before !==
            conversations[conversations.length - 1].updated_at
        ) {
          let params = state.conversationsParams;
          params.before = conversations[conversations.length - 1].updated_at;
          await dispatch('fetchMoreConversations', params);
          conversations = state.conversations;
        }
        commit('updateCurrentConversationId', id);
      }
    },

    connectMessagesCunsomer({ dispatch, commit, state }, id) {
      commit(
        'updateMessagesCunsomer',
        new Consumer(
          () => {
            dispatch('fetchMessages', id);
          },
          {
            channel: 'SupportChannelV2',
            conversation_id: state.currentConversationId,
          },
        ),
      );
      state.messagesCunsomer.connect();
    },

    disconnectMessagesCunsomer({ commit, state }) {
      state.messagesCunsomer.disconnect();
      commit('updateMessagesCunsomer', null);
    },
  },

  mutations: {
    updateConversations(state, conversations) {
      state.conversations = conversations;
    },

    addConversations(state, conversations) {
      state.conversations = state.conversations.concat(conversations);
    },

    updateFirstConversation(state, conversation) {
      let index = state.conversations.findIndex(
        (conversationOfList) => conversationOfList.id === conversation.id,
      );
      if (index >= 0) state.conversations.splice(index, 1);
      state.conversations.unshift(conversation);
    },

    updateConversationsCunsomer(state, cunsomer) {
      state.conversationsCunsomer = cunsomer;
    },

    updateConversationsParams(state, params) {
      state.conversationsParams = params;
    },

    updateCurrentConversationId(state, id) {
      state.currentConversationId = id;
    },

    updateMessages(state, messages) {
      state.messages = messages;
    },

    addMessages(state, messages) {
      state.messages = state.messages.concat(messages);
    },

    updateMessagesPage(state, data) {
      state.messagesCurrentPage = data.current_page;
      state.messagesTotalPages = data.total_pages;
    },

    updateMessagesCunsomer(state, cunsomer) {
      state.messagesCunsomer = cunsomer;
    },
  },
};
