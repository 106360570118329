<template>
  <tr
    v-show="!isProductHidden"
    class="table-row"
  >
    <td class="align-middle ps-3 position-relative">
      <router-link
        class="stretched-link text-decoration-none"
        :to="`/courses/products/${product.id}`"
      >
        <font-awesome-icon
          v-if="product.favorite"
          icon="heart"
          class="text-danger me-2"
        />
        {{ product.name }}
      </router-link>
    </td>
    <td class="align-middle">
      {{ productCoursesInfo }}
      <template v-if="hasWrongPublicationStatus">
        <font-awesome-icon
          :id="`product-alert-${product.id}`"
          icon="circle-exclamation"
          class="ms-2 text-warning"
        />
        <b-tooltip
          :target="`product-alert-${product.id}`"
          title="Курс не опубликован, опубликованные тарифы не отображаются в каталоге"
        />
      </template>
    </td>
    <td
      :class="currentPublicationStatusClass"
      class="align-middle"
    >
      {{ publicationStatusString }}
    </td>
    <td class="align-middle break-word">{{ productOwnerName }}</td>
    <td class="align-middle text-end pe-3">
      <b-dropdown
        variant="link"
        toggle-class="text-decoration-none text-body"
        no-caret
        right
      >
        <template #button-content>
          <font-awesome-icon icon="ellipsis" />
        </template>
        <b-dropdown-item :to="`/courses/products/${product.id}`">
          <font-awesome-icon
            icon="folder-open"
            class="me-2"
          />
          Открыть
        </b-dropdown-item>
        <b-dropdown-item :to="`/courses/products/${product.id}/edit`">
          <font-awesome-icon
            icon="pen-to-square"
            class="me-2"
          />
          Изменить
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item-button
          :disabled="httpProcess"
          @click="toggleFavoriteProduct"
        >
          <font-awesome-icon
            :icon="favoriteIcon"
            class="me-2"
          />
          {{ favoriteButtonText }}
        </b-dropdown-item-button>
        <b-dropdown-divider />
        <b-dropdown-item-button
          :disabled="deleteButtonDisabled"
          @click="removeProduct"
        >
          <font-awesome-icon
            icon="trash"
            class="me-2"
          />
          Удалить
        </b-dropdown-item-button>
      </b-dropdown>
    </td>
  </tr>
</template>

<script setup>
import { computed, toRefs } from 'vue';
import { useStore } from 'vuex';

import { usePublicationStatusFilter } from '@/composables/courses/use_publication_status_filter';
import { useProductOwners } from '@/composables/courses/use_product_owners';

import {
  formatPublishedCount,
  formatTariffsCount,
} from '@/custom_functions/format_functions';
import { makeToast } from '@/custom_functions/custom_functions';

const store = useStore();

const props = defineProps({ product: { type: Object, required: true } });
const { product } = toRefs(props);

const productCoursesInfo = computed(() => {
  const publishedCourses = product.value.courses.filter((course) => {
    return course.publication_status === 'published';
  });

  const notArchividCourses = product.value.courses.filter((course) => {
    return course.publication_status !== 'archived';
  });

  const tariffsCountString = formatTariffsCount(notArchividCourses.length);
  const publishedCountString = formatPublishedCount(publishedCourses.length);
  return `${tariffsCountString}, ${publishedCountString}`;
});

const hasWrongPublicationStatus = computed(() => {
  const hasPublishedCourses = product.value.courses.some((course) => {
    return course.publication_status === 'published';
  });

  return (
    product.value.publication_status !== 'published' && hasPublishedCourses
  );
});

const { publicationStatusClass, formatPublicationStatus } =
  usePublicationStatusFilter();

const currentPublicationStatusClass = computed(() => {
  return publicationStatusClass(product.value.publication_status);
});

const publicationStatusString = computed(() => {
  return formatPublicationStatus(product.value.publication_status);
});

const { getProductOwnerNameById } = useProductOwners();

const productOwnerName = getProductOwnerNameById(product.value.po_user_id);

const httpProcess = computed(() => store.getters['products/getHttpProcess']);

async function toggleFavoriteProduct() {
  const actionName = product.value.favorite
    ? 'products/deleteFavoriteProduct'
    : 'products/postFavoriteProduct';

  await store.dispatch(actionName, product.value.id);

  const actionString = product.value.favorite
    ? 'добавлен в избранное'
    : 'удален из избранного';

  makeToast(`Курс "${product.value.name}" ${actionString}`, 'success');
}

const isProductHidden = computed(() => {
  return store.getters['products/getHiddenProductIds'].includes(
    product.value.id,
  );
});

async function removeProduct() {
  store.commit('products/addIdToHiddenProductIds', product.value.id);

  store.commit('toast/addRevertToast', {
    comment: `Курс «${product.value.name}» удален`,
    buttonText: 'Восстановить курс',
    action: () => store.dispatch('products/deleteProduct', product.value.id),
    revert: () =>
      store.commit('products/removeIdFromHiddenProductIds', product.value.id),
  });
}

const favoriteIcon = computed(() => [
  product.value.favorite ? 'fas' : 'far',
  'heart',
]);

const favoriteButtonText = computed(() => {
  return product.value.favorite ? 'Убрать из избранного' : 'В избранное';
});

const deleteButtonDisabled = computed(() => {
  return (
    product.value.courses.some((course) => course.participations_count) ||
    httpProcess.value
  );
});
</script>

<style lang="scss" scoped>
.table-row {
  height: 62px;
}
</style>
