<template>
  <div class="d-flex no-block align-items-center justify-content-between">
    <div class="d-flex align-items-center">
      <h1 class="mb-1 me-2 h2">Курсы и тарифы</h1>
      <a
        id="docs"
        href="http://surl.li/huupa"
        target="_blank"
      >
        <font-awesome-icon
          icon="circle-question"
          size="lg"
          class="text-secondary"
        />
        <b-tooltip
          target="docs"
          placement="right"
          boundary-padding="0"
          title="Инструкция"
        />
      </a>
    </div>
    <div class="flex-grow-1 d-flex align-items-center justify-content-end">
      <router-link
        class="btn btn-outline-primary me-3"
        to="/courses/reviews/page_1"
      >
        Отзывы
      </router-link>
      <router-link
        class="btn btn-primary"
        :to="newLink.to"
      >
        {{ newLink.text }}
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const newLink = computed(() => {
  if (route.query.tab === 'products')
    return { to: '/courses/products/new', text: 'Добавить курс' };
  if (route.query.tab === 'containers') {
    return {
      to: '/courses/containers/new',
      text: 'Добавить комплект или подписку',
    };
  }
  return { to: '/courses/new', text: 'Добавить тариф' };
});
</script>
