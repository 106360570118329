<template>
  <div class="d-flex align-items-center direction row">
    <div class="form-group col-9 mb-0">
      <input
        v-model.trim="direction.name"
        :class="errors.name && 'is-invalid'"
        class="form-control"
        aria-label="direction-name"
        type="text"
      />
      <template v-if="errors.name">
        <span
          v-for="error in errors.name"
          :key="error"
          class="invalid-feedback"
        >
          {{ error }}
        </span>
      </template>
    </div>
    <span class="col-2">
      {{ formatCoursesCount(direction.products_count || 0) }}
    </span>
    <button
      class="d-flex justify-content-end btn btn-link text-danger col-1"
      title="Удалить"
      @click="deleteDirection"
    >
      <font-awesome-icon icon="trash" />
    </button>
  </div>
</template>

<script setup>
import { formatCoursesCount } from '@/custom_functions/format_functions';

import { computed, watch, toRefs } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const props = defineProps({ direction: { type: Object, required: true } });
const { direction } = toRefs(props);

const errors = computed(() =>
  store.getters['directions/getDirectionErrors'](direction.value.id),
);

watch(
  direction,
  () => {
    store.dispatch('directions/validateDirection', direction.value);
    if (!Object.keys(errors.value).length) {
      store.commit('directions/changeCurrentDirection', direction.value);
    }
  },
  { deep: true },
);

function deleteDirection() {
  store.commit('directions/removeCurrentDirection', direction.value.id);
  store.dispatch('directions/validateAllDirections', direction.value);
}
</script>
