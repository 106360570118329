<template>
  <div>
    <b-button
      variant="primary"
      class="mb-3 me-2"
      @click="uploadCSV"
    >
      Импортировать CSV
    </b-button>
    <b-button
      variant="primary"
      class="mb-3 me-2"
      @click="downloadXlsx"
    >
      Скачать Xlsx
    </b-button>

    <b-button
      v-if="!promoCodesEnabled"
      variant="primary"
      class="mb-3 me-2"
      @click="togglePromoCodesAviability"
    >
      Включить промокоды
    </b-button>
    <b-button
      v-if="promoCodesEnabled"
      variant="warning"
      class="mb-3"
      @click="togglePromoCodesAviability"
    >
      Выключить промокоды
    </b-button>

    <table class="table table-striped">
      <thead>
        <tr>
          <th>Значение</th>
          <th>Студент</th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="promoCode in promoCodes"
          :key="promoCode.id"
        >
          <td>{{ promoCode.value }}</td>
          <td>
            <template v-if="promoCode.user">
              {{ promoCode.user.full_name || promoCode.user.email }}
            </template>
            <div
              v-else-if="
                currentPromoCode && currentPromoCode.id == promoCode.id
              "
              class="row"
            >
              <v-select
                v-model:model-value="user"
                class="col-8"
                :options="usersWithoutPromoCode"
                label="email"
                placeholder="Выберите студента"
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    v-bind="attributes"
                    @blur="events.blur"
                    @focus="events.focus"
                    @change="searchUsers"
                  />
                </template>
              </v-select>
              <div class="col-2">
                <button
                  class="btn btn-primary btn-sm w-100"
                  @click="assignPromoCodeToUser()"
                >
                  Сохранить
                </button>
              </div>
              <div class="col-2">
                <button
                  class="btn btn-secondary btn-sm w-100"
                  @click="
                    currentPromoCode = null;
                    user = null;
                  "
                >
                  Отмена
                </button>
              </div>
            </div>
            <button
              v-else
              class="btn btn-primary btn-sm"
              :disabled="currentPromoCode"
              @click="currentPromoCode = promoCode"
            >
              Назначить студенту
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { apiClient } from '@/custom_functions/api_client';
import { makeToast } from '@/custom_functions/custom_functions';

import { ref, computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const course = computed(() => store.getters['course/getCourse']);

const promoCodesEnabled = computed(
  () => course.value.external_promo_codes_available,
);
const courseId = computed(() => course.value.id);

const promoCodes = ref([]);
const currentPromoCode = ref(null);
const user = ref(null);

const users = computed(() => store.getters['users/getUsers']);

const usersWithoutPromoCode = computed(() => {
  return users.value.filter((user) => {
    return !promoCodes.value.find((promoCode) => promoCode.user_id === user.id);
  });
});

loadPromoCodes();

async function togglePromoCodesAviability() {
  await apiClient
    .post(`v2/courses/${courseId.value}/toggle_the_availability_of_promo_codes`)
    .then(() => {
      makeToast('Настройки внешних промокодов изменены', 'success');
      promoCodesEnabled.value = !promoCodesEnabled.value;
    });
}

async function loadPromoCodes() {
  const response = await apiClient.get(
    `v2/courses/${courseId.value}/external_promo_codes`,
  );
  promoCodes.value = response.data.external_promo_codes;
}

function uploadCSV() {
  const input = document.createElement('input');
  input.type = 'file';

  input.onchange = async (event) => {
    const axiosConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const postData = new FormData();
    postData.append('csv', event.target.files[0]);

    await apiClient.post(
      `v2/courses/${courseId.value}/external_promo_codes/import`,
      postData,
      axiosConfig,
    );
    input.remove();
    await loadPromoCodes.value();
  };

  input.click();
}

async function downloadXlsx() {
  const response = await apiClient.get(
    `v2/courses/${courseId.value}/external_promo_codes.xlsx`,
    { responseType: 'arraybuffer' },
  );

  const blob = new Blob([response.data], {
    type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const link = document.createElement('a');
  link.download = 'promo_codes.xlsx';
  link.href = URL.createObjectURL(blob);
  link.click();
  URL.revokeObjectURL(link.href);
  link.remove();
}

function searchUsers(event) {
  const query = event.target.value;
  store.value.dispatch('users/fetchUsersByQuery', query);
}

async function assignPromoCodeToUser() {
  await apiClient.patch(
    `v2/courses/${courseId.value}/external_promo_codes/${currentPromoCode.value.id}`,
    {
      external_promo_code: {
        user_id: user.value.id,
        value: currentPromoCode.value.value,
      },
    },
  );
  const promoCode = currentPromoCode.value.value;
  const userName = user.value.full_name || user.value.email;
  makeToast(`Промокод ${promoCode} назначен студенту ${userName}`, 'success');
  currentPromoCode.value.user = user.value;
  currentPromoCode.value = null;
  user.value = null;
}
</script>
