import validateNotEmptyField from '../validate_not_empty_field';

export default function validateCourseUrl(value) {
  let errors = validateNotEmptyField(value);
  if (!errors.length) {
    if (!/^[0-9\-_A-Za-z]*[A-Za-z]+[0-9\-_A-Za-z]*$/.test(value)) {
      errors.push(
        'url должен состоять из латинских букв, возможно доабвление символов _, - и цифры',
      );
    }
  }
  return errors;
}
