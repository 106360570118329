<template>
  <div>
    <draggable
      v-model:model-value="step.data.variants"
      itemKey="id"
    >
      <template #item="{ element }">
        <div
          class="d-flex card flex-row align-items-center justify-content-between mb-3 p-3"
        >
          <font-awesome-icon
            icon="grip-vertical"
            class="text-muted drag-icon"
          />
          <input
            v-model="element.text"
            class="form-control ms-2"
            placeholder="Вариант ответа"
          />

          <button
            class="ms-2 btn btn-link text-danger"
            @click.prevent="deleteVariant(element)"
          >
            <font-awesome-icon icon="xmark" />
          </button>
        </div>
      </template>
    </draggable>
    <button
      class="btn btn-outline-primary btn-sm"
      @click="addVariant"
    >
      + добавить вариант
    </button>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'SortStep',

  props: { step: { type: Object, required: true } },

  methods: {
    deleteVariant(element) {
      this.step.data.variants = this.step.data.variants.filter((variant) => {
        if (JSON.stringify(variant) !== JSON.stringify(element)) return variant;
      });
    },

    addVariant() {
      this.step.data.variants.push({
        id: uuidv4(),
        text: '',
        value: this.step.data.variants.length + 1,
      });
    },
  },
};
</script>

<style>
.drag-icon {
  cursor: grab;
}
</style>
