<template>
  <div class="container-fluid">
    <table class="table table-hover table-responsive-lg table-sm">
      <thead>
        <tr>
          <th>Отчёт</th>
          <th>Описание</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(report, index) in reports"
          :key="report.title"
        >
          <td class="align-middle p-3 fw-bold">{{ report.name }}</td>
          <td class="align-middle p-3">
            <div>{{ report.description }}</div>
          </td>
          <td class="align-middle p-3">
            <div class="d-flex justify-content-end">
              <button
                class="btn btn-outline-primary"
                @click.prevent="toggleModal(index)"
              >
                Скачать отчёт
              </button>
              <b-modal
                :id="`modal-report-${report.id}`"
                v-model="report.isOpen"
                hide-footer
                :title="report.name"
              >
                <p v-html="report.notes" />
                <component
                  :is="report.fieldsComponent"
                  v-model:model-value="downloadParams"
                  :report="report"
                />

                <button
                  class="btn btn-outline-primary mt-3 w-100"
                  @click="downloadReport(report)"
                >
                  Скачать отчёт
                </button>
              </b-modal>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { apiClient } from '../../custom_functions/api_client';
import SelectCourseFields from './SelectCourseFields.vue';
import SelectUnitFields from './SelectUnitFields.vue';
import SelectProductFields from './SelectProductFields.vue';

const store = useStore();

store.dispatch('courses/fetchCoursesOmitPagination', {
  withoutContainers: true,
});

const reports = ref([
  {
    isOpen: false,
    name: 'Процент прохождения модулей',
    description:
      'Процент прохождения по модулям и в курсе для каждого желающего',
    notes:
      'Выберите тариф, по которому хотите составить отчёт.<br/>Скачивание отчёта может занять некоторое время.',
    fieldsComponent: SelectCourseFields,
    downloadUrl: 'v2/reports/course_units_progress_report.xlsx',
    contentType: 'xlsx',
  },
  {
    isOpen: false,
    name: 'Полный отчёт об успеваемости',
    description:
      'Оценки и общий результат каждого учащегося по каждому заданию',
    notes:
      'Выберите тариф и модуль для создания отчёта.<br/>Скачивание отчёта может занять некоторое время.',
    fieldsComponent: SelectUnitFields,
    downloadUrl: 'v2/reports/full_unit_progress_report.xlsx',
    contentType: 'xlsx',
  },
  {
    isOpen: false,
    name: 'Основные метрики',
    description: 'Основные метрики: COR, SCI, статистика отзывов',
    notes:
      'Выберите курс для создания отчёта.<br/>Скачивание отчёта может занять некоторое время.',
    fieldsComponent: SelectProductFields,
    downloadUrl: 'v2/reports/product_progress_report.xlsx',
    contentType: 'xlsx',
  },
]);

const toggleModal = (index) => {
  reports.value[index].isOpen = !reports.value[index].isOpen;
};

const downloadParams = ref({});

const downloadReport = async (report) => {
  const { name, downloadUrl, contentType } = report;

  alert('Отчет будет скачиваться некоторое время, подождите');
  const response = await apiClient.get(downloadUrl, {
    responseType: 'arraybuffer',
    params: downloadParams.value,
  });
  const blob = new Blob([response.data], {
    type: response.headers['content-type'],
  });
  const link = document.createElement('a');
  const suffix = Object.entries(downloadParams.value)
    .map(([key, value]) => `${key}-${value}`)
    .join('_');
  link.download = `${name}_${suffix}.${contentType}`;
  link.href = URL.createObjectURL(blob);
  link.click();
  URL.revokeObjectURL(link.href);
  link.remove();

  // Close the modal after download
  report.isOpen = false;
};
</script>
