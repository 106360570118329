<template>
  <div
    v-if="
      container && courses && directions && productOwners && tags && containers
    "
    class="container"
  >
    <CoursesItemHeader
      class="mb-4"
      courses-item-type="container"
      is-new
      @save="saveContainer"
    />
    <ContainerTabs is-new />
  </div>
  <div
    v-else
    class="loader text-center"
  >
    <b-spinner />
  </div>
</template>

<script setup>
import CoursesItemHeader from '@/components/courses/CoursesItemHeader';
import ContainerTabs from '@/components/courses/ContainerTabs';

import { makeToast } from '@/custom_functions/custom_functions';

import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();

store.dispatch('container/createContainer');
const container = computed(() => store.getters['container/getContainer']);

store.dispatch('courses/fetchCoursesOmitPagination');
const courses = computed(
  () => store.getters['courses/getCoursesOmitPagination'],
);

const hasErrors = computed(() => store.getters['container/getHasErrors']);

async function saveContainer() {
  await store.dispatch('container/saveContainer');
  if (!hasErrors.value) {
    makeToast(
      container.value.course_type === 'subscription'
        ? 'Подписка сохранена'
        : 'Комплект сохранен',
      'success',
    );
    router.replace(`/courses/containers/${container.value.id}/edit`);
  }
}

watch(
  container,
  () => {
    if (hasErrors.value) store.dispatch('container/validateContainer');
  },
  { deep: true },
);

store.dispatch('directions/fetchDirections');
const directions = computed(() => store.getters['directions/getDirections']);

store.dispatch('course/fetchProductOwners');
const productOwners = computed(() => store.getters['course/getProductOwners']);

store.dispatch('tags/fetchTagsSettings');
const tags = computed(() => store.getters['tags/getTags']);

store.dispatch('containers/fetchContainers');
const containers = computed(() => store.getters['containers/getContainers']);
</script>
