<template>
  <b-modal
    v-model="isOpen"
    header-class="border-bottom-0"
    body-class="mb-2"
    hide-footer
  >
    <template v-slot:modal-title>{{ title }}</template>
    <div class="fs-5">
      {{ text }}
    </div>
    <div
      :class="text && 'mt-3'"
      class="d-flex flex-column gap-3"
    >
      <b-button
        variant="outline-primary"
        block
        @click="confirm"
      >
        {{ сonfirmButtonText }}
      </b-button>
      <b-button
        variant="outline-secondary"
        block
        @click="decline"
      >
        {{ declineButtonText }}
      </b-button>
    </div>
  </b-modal>
</template>

<script setup>
import { watch, ref, toRef } from 'vue';

const emit = defineEmits(['confirm', 'decline']);

const props = defineProps({
  modelValue: { type: Boolean, default: false },
  title: { type: String, required: true },
  text: { type: String },
  сonfirmButtonText: { type: String, default: 'Подтвердить' },
  declineButtonText: { type: String, default: 'Отменить' },
});

const modelValue = toRef(props, 'modelValue');

const isOpen = ref(modelValue.value);
watch(isOpen, (value) => {
  emit('update:modelValue', value);
});

watch(modelValue, (value) => {
  isOpen.value = value;
});

function confirm() {
  emit('confirm');
  isOpen.value = false;
}

function decline() {
  emit('decline');
  isOpen.value = false;
}
</script>
