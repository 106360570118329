<template>
  <div class="container-fluid">
    <table class="table table-hover table-responsive-lg table-sm">
      <thead>
        <tr>
          <th>Имя файла</th>
          <th>Периодичность</th>
          <th>SQL запрос</th>
          <th />
          <th />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(report, index) in reports"
          :key="report.id"
        >
          <td>{{ report.name }}</td>
          <td>{{ formatCron(report.cron) }}</td>
          <td>
            <pre>{{ report.sql }}</pre>
          </td>
          <td>
            <div class="d-flex justify-content-end">
              <router-link
                v-if="canEdit"
                :to="`/reports/${report.id}`"
                class="px-4"
              >
                <font-awesome-icon
                  icon="pen"
                  class="text-dark small"
                />
              </router-link>
              <a
                href="#"
                class="px-4"
                @click.prevent="downloadReport(index)"
              >
                Скачать
              </a>
              <a
                v-if="canEdit"
                href="#"
                class="px-4"
                @click.prevent="$bvModal.show(`modal-delete-${report.id}`)"
              >
                <font-awesome-icon
                  icon="trash"
                  class="text-danger small"
                />
              </a>
              <b-modal
                :id="`modal-delete-${report.id}`"
                hide-footer
                title="Подтвердите действие"
              >
                <div class="d-block text-center">
                  <h4>Выгрузка в {{ report.name }} будет приостанвлена</h4>
                </div>
                <button
                  class="btn btn-danger btn-block mt-3 w-100"
                  @click="deleteReport(index)"
                >
                  Удалить
                </button>
                <button
                  class="btn btn-secondary mt-3 w-100"
                  @click="$bvModal.hide(`modal-delete-${report.id}`)"
                >
                  Отмена
                </button>
              </b-modal>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { apiClient } from '../../custom_functions/api_client';
import { makeToast } from '../../custom_functions/custom_functions';
import { toString } from 'cronstrue/i18n';

const store = useStore();

const reports = computed(() => store.getters['reports/getReports']);
const canEdit = computed(() => store.getters['profile/isAdmin']);

onMounted(() => {
  store.dispatch('reports/fetchReports');
});

const formatCron = (value) => {
  return toString(value, { locale: 'ru' });
};

const deleteReport = async (index) => {
  const { id } = reports.value[index];
  await apiClient.delete(`/v2/google_reports/${id}`);
  reports.value.splice(index, 1);
  // Note: $bvModal is Bootstrap Vue specific. You might need to replace this with a different modal implementation in Vue 3.
  // modal.hide(`modal-delete-${id}`);
  makeToast('Выгрузка была остановлена, конфигурация удалена', 'success');
};

const downloadReport = async (index) => {
  const { id, name, content_type } = reports.value[index];
  const url =
    content_type === 'xlsx'
      ? `/v2/google_reports/${id}/report.xlsx`
      : `/v2/google_reports/${id}/report.csv`;
  alert('Отчет будет скачиваться некоторое время, подождите');
  const response = await apiClient.get(url, { responseType: 'arraybuffer' });
  const blob = new Blob([response.data], {
    type: response.headers['content-type'],
  });
  const link = document.createElement('a');
  link.download = `${name}.${content_type}`;
  link.href = URL.createObjectURL(blob);
  link.click();
  URL.revokeObjectURL(link.href);
  link.remove();
};
</script>
