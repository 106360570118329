import {
  formatPublicationStatus,
  getPublicationStatuses,
} from '@/custom_functions/publication_statuses';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export function usePublicationStatusFilter() {
  const route = useRoute();
  const router = useRouter();

  const publicationStatuses = getPublicationStatuses();

  const publicationStatus = computed({
    get() {
      return route.query.publication_status;
    },
    set(publication_status) {
      if (route.query.publication_status !== publication_status) {
        router.push({ query: { ...route.query, publication_status } });
      }
    },
  });

  function publicationStatusClass(status) {
    return status === 'published' ? 'text-success' : 'text-body';
  }

  return {
    publicationStatuses,
    publicationStatus,
    formatPublicationStatus,
    publicationStatusClass,
  };
}
