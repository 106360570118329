<template>
  <table class="table table-sm table-hover">
    <thead>
      <tr>
        <th
          scope="col"
          class="py-2 ps-3 align-middle"
        >
          Курс
        </th>
        <th
          scope="col"
          class="py-2 align-middle table-col-2"
        >
          Тарифы в курсе
        </th>
        <th
          scope="col"
          class="py-2 align-middle table-col-3"
        >
          <TableFilter
            v-model:model-value="publicationStatus"
            :values="publicationStatuses"
            :format-function="formatPublicationStatus"
            :disabled="httpProcess"
            title="Статус"
          />
        </th>
        <th
          scope="col"
          class="py-2 align-middle text-nowrap table-col-4"
        >
          Директор
        </th>
        <th
          scope="col"
          class="py-2 pe-3 align-middle table-col-5"
        >
          &nbsp;
        </th>
      </tr>
    </thead>
    <tbody v-if="products?.length && productOwners">
      <ProductsTableRow
        v-for="product in products"
        :key="product.id"
        :product="product"
      />
    </tbody>
  </table>
  <div
    v-if="products && !products.length"
    class="loader text-center fs-3 text-secondary"
  >
    Курсы не найдены
  </div>
  <div
    v-else-if="!products"
    class="loader text-center"
  >
    <b-spinner />
  </div>
  <Pagination
    v-if="products"
    :total-pages="totalPages"
    :items-count="products.length"
    :disabled="httpProcess"
  />
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import Pagination from '@/components/Pagination';
import TableFilter from '@/components/TableFilter';
import ProductsTableRow from '@/components/courses/products/ProductsTableRow';

import { useProductOwners } from '@/composables/courses/use_product_owners';
import { usePublicationStatusFilter } from '@/composables/courses/use_publication_status_filter';

const store = useStore();

const products = computed(() => store.getters['products/getProducts']);

const { publicationStatuses, publicationStatus, formatPublicationStatus } =
  usePublicationStatusFilter();

const { productOwners } = useProductOwners();

const totalPages = computed(
  () => store.getters['products/getProductsTotalPages'],
);

const httpProcess = computed(() => store.getters['products/getHttpProcess']);
</script>

<style scoped>
.table-col-2 {
  width: 265px;
}
.table-col-3 {
  width: 145px;
}
.table-col-4 {
  width: 150px;
}
.table-col-5 {
  width: 65px;
}
</style>
