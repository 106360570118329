import store from '@/store';
import { errorHandling } from '@/custom_functions/custom_functions';

export const fetchApiClient = {
  post: async (url, body, options = {}) =>
    await send(url, body, { ...options, method: 'POST' }),
  patch: async (url, body, options = {}) =>
    await send(url, body, { ...options, method: 'PATCH' }),
  get: async (url, options = {}) =>
    await send(url, null, { ...options, method: 'GET' }),
  delete: async (url, options = {}) =>
    await send(url, null, { ...options, method: 'DELETE' }),
};

async function send(url, body, options) {
  try {
    const response = await fetch(process.env.VUE_APP_API_URL + url, {
      headers: { 'X-Requested-By': 'admin' },
      credentials: 'include',
      body: body ? JSON.stringify(body) : null,
      ...options,
    });

    if (!response.ok) throw { response };

    if (response.headers.get('x-user-rights')) {
      const rights = JSON.parse(response.headers.get('x-user-rights')) || null;
      store.commit('updateCurrentUserRights', rights);
    }

    localStorage.userRole = response.headers.get('x-user-role');

    return response;
  } catch (error) {
    errorHandling(error);

    throw error;
  }
}
