<template>
  <b-tabs v-model="tabValue">
    <b-tab
      v-for="tab in tabs"
      :key="tab.value"
    >
      <template #title>
        <TabTitle
          :title="tab.title"
          :errors="errors"
          :keys="tab.fieldKeys"
        />
      </template>
      <component
        :is="tab.component"
        class="mt-3"
      />
    </b-tab>
  </b-tabs>
</template>

<script setup>
import TabTitle from '@/components/TabTitle';
import ProductMain from '@/components/courses/products/ProductMain';
import ProductPromotion from '@/components/courses/products/ProductPromotion';

import { computed } from 'vue';
import { useStore } from 'vuex';

import { useRouteTabs } from '@/composables/use_route_tabs';

const store = useStore();

const errors = computed(() => store.getters['product/getErrors']);

const tabs = [
  {
    value: 'promotion',
    fieldKeys: [
      'name',
      'description',
      'icon_url',
      'experience_level',
      'direction_ids',
      'external_url',
    ],
    title: 'Курс в каталоге',
    component: ProductPromotion,
  },
  {
    value: 'main',
    fieldKeys: ['po_user_id', 'courses_ids'],
    title: 'Основное',
    component: ProductMain,
  },
];

const { tabValue } = useRouteTabs(tabs, 'promotion');
</script>
