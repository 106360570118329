<template>
  <form @submit.prevent="createDirection">
    <label
      for="new-direction-name"
      class="mb-2"
    >
      Добавить новое направление
    </label>
    <div class="input-group">
      <input
        id="new-direction-name"
        v-model.trim="newDirection.name"
        :class="errors.name && 'is-invalid'"
        class="form-control"
        type="text"
      />
      <button
        :disabled="Object.keys(errors).length"
        class="btn btn-primary rounded-right"
        type="submit"
      >
        Добавить
      </button>
      <template v-if="errors.name">
        <span
          v-for="error in errors.name"
          :key="error"
          class="invalid-feedback"
        >
          {{ error }}
        </span>
      </template>
    </div>
  </form>
</template>

<script>
export default {
  name: 'DirectionsCreateForm',

  computed: {
    errors() {
      return this.$store.getters['directions/getDirectionErrors']('new');
    },
  },

  data() {
    return { newDirection: {} };
  },

  watch: {
    newDirection: {
      handler() {
        if (Object.keys(this.errors).length)
          this.$store.dispatch(
            'directions/validateDirection',
            this.newDirection,
          );
      },
      deep: true,
    },
  },

  methods: {
    createDirection() {
      this.$store.dispatch('directions/validateDirection', this.newDirection);
      if (!Object.keys(this.errors).length) {
        this.$store.commit('directions/addCurrentDirection', this.newDirection);
        this.newDirection = {};
      }
    },
  },
};
</script>
