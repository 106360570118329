<template>
  <div class="row">
    <div class="mb-3 col-6 mb-0">
      <label class="mb-2">{{ dateLabel }}</label>
      <b-datepicker
        :min="min || null"
        :max="max || null"
        :placeholder="datePlaceholder"
        :date-format-options="dateFormatOptions"
        :value="dateValue"
        :disabled="disabled"
        :class="errors.length && 'is-invalid'"
        locale="ru"
        start-weekday="1"
        @context="setDate"
      />
    </div>
    <div class="mb-3 col-6 mb-0">
      <label class="mb-2">{{ timeLabel }}</label>
      <b-timepicker
        :placeholder="timePlaceholder"
        :value="timeValue"
        :disabled="disabled"
        :class="errors.length && 'is-invalid'"
        minutes-step="5"
        locale="ru"
        @context="setTime"
      />
    </div>
    <div class="col-12">
      <span
        v-for="(error, index) in errors"
        :key="index"
        class="invalid-feedback d-block"
      >
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'DateTimePicker',

  props: {
    modelValue: { required: true },
    dateLabel: { type: String, default: 'Дата' },
    timeLabel: { type: String, default: 'Время' },
    datePlaceholder: { type: String, default: '' },
    timePlaceholder: { type: String, default: '' },
    min: { type: [Date, String] },
    max: { type: [Date, String] },
    disabled: { type: Boolean, default: false },
    errors: { type: Array, default: () => [] },
  },

  data() {
    return {
      dateFormatOptions: { year: 'numeric', month: 'short', day: 'numeric' },
    };
  },

  computed: {
    dateValue() {
      return this.modelValue
        ? moment(this.modelValue).utcOffset('GMT+03:00').format('YYYY-MM-DD')
        : null;
    },
    timeValue() {
      return this.modelValue
        ? moment(this.modelValue).utcOffset('GMT+03:00').format('HH:mm:ss')
        : null;
    },
  },

  methods: {
    async setDate(e) {
      if (e.selectedDate) {
        if (!this.modelValue) await this.$emit('update:modelValue', new Date());
        const mDate = moment(e.selectedDate);
        const mTime = moment(this.modelValue).utcOffset('GMT+03:00');
        const date = `${mDate.format('YYYY-MM-DD')}T${mTime.format(
          'HH:mm:ss',
        )}+03:00`;
        this.$emit('update:modelValue', date);
      }
    },
    async setTime(e) {
      if (e.value) {
        if (!this.modelValue) await this.$emit('update:modelValue', new Date());
        const date = `${moment(this.modelValue)
          .utcOffset('GMT+03:00')
          .format('YYYY-MM-DD')}T${e.value}+03:00`;
        this.$emit('update:modelValue', date);
      }
    },
  },
};
</script>
