<template>
  <div
    v-if="conversation"
    class="d-flex flex-column"
  >
    <div class="border-bottom p-2 d-flex">
      <b-avatar
        :src="conversation.user_avatar"
        class="me-2"
      />
      <div class="me-2 flex-grow-1">
        <div>
          <span class="fw-bold me-2">
            {{ conversation.user_full_name || conversation.user_email }}
          </span>
          <router-link
            :to="`/users/${conversation.user_id}/edit`"
            target="_blank"
            class="text-muted"
          >
            {{ conversation.user_login }}
          </router-link>
        </div>
        <div
          v-if="
            conversation.latest_source_url && conversation.latest_source_title
          "
        >
          <span class="text-muted">Отправлено из:</span>
          <a
            :href="linkToPageOfMessageSending()"
            target="_blank"
          >
            {{ conversation.latest_source_title }}
          </a>
        </div>
      </div>
      <button
        v-if="conversation.state === 'awaiting'"
        class="btn btn-primary"
        @click="takeInWork()"
      >
        Взять в работу
      </button>
      <template v-if="conversation.state === 'in_work'">
        <button
          v-if="conversation.responsible_id === currentUser.id"
          class="btn btn-success"
          @click="resolve()"
        >
          Закрыть обращение
        </button>
        <span
          v-else
          class="badge rounded-pill text-bg-light align-self-center"
        >
          {{ conversation.responsible_full_name }}
        </span>
      </template>
      <span
        v-else-if="conversation.state === 'resolved'"
        class="badge rounded-pill text-bg-light align-self-center"
      >
        Решено
      </span>
    </div>
    <div
      ref="messages"
      class="flex-grow-1 p-2 messages"
      @scroll="getMessagesOnScroll"
    >
      <div
        v-if="messages"
        class="d-flex flex-column-reverse"
      >
        <div
          v-for="message in messages"
          :key="message.id"
          class="mb-3 d-flex"
          :class="message.type !== 'Question' && 'flex-row-reverse'"
        >
          <div
            class="rounded p-2 message mx-1"
            :class="message.type === 'Question' ? 'question' : 'answer'"
          >
            <div v-html="message.text" />
            <div
              v-if="message.attachments.length"
              class="d-flex mt-2"
            >
              <div
                v-for="file in message.attachments"
                :key="file.id"
              >
                <a
                  v-if="file.preview_url"
                  :href="file.url"
                  target="_blank"
                  class="me-2"
                >
                  <img
                    :src="file.preview_url"
                    :alt="file.name"
                    class="rounded"
                  />
                </a>
                <a
                  v-else
                  class="attached-file rounded bg-light text-secondary text-center d-block me-2"
                  :href="file.url"
                  target="_blank"
                >
                  <small>{{ file.name }}</small>
                  <br />
                  <font-awesome-icon icon="download" />
                </a>
              </div>
            </div>
          </div>
          <small class="text-muted align-self-end mb-2 mx-1">
            {{ formatDateTime(message.created_at) }}
          </small>
        </div>
        <div
          v-if="totalPages !== currentPage && messages.length"
          ref="moreMessages"
          class="loader text-center"
        >
          <b-spinner />
        </div>
      </div>
      <div
        v-else
        class="loader text-center"
      >
        <b-spinner />
      </div>
    </div>
    <div class="p-2 border-top d-flex">
      <label
        v-if="!attachedFiles.length"
        class="file-btn align-self-center me-2 my-0"
      >
        <font-awesome-icon
          icon="paperclip"
          class="text-muted"
        />
        <input
          ref="filesInput"
          type="file"
          multiple
          class="d-none"
          @change="attachFiles"
        />
      </label>
      <button
        v-else
        class="file-btn align-self-center me-2 my-0"
        :class="hoverFilesBtn ? 'file-btn-danger' : 'file-btn-success'"
        @mouseover="hoverFilesBtn = true"
        @mouseleave="hoverFilesBtn = false"
        @click="removeFiles"
      >
        <font-awesome-icon
          v-if="hoverFilesBtn"
          icon="xmark"
        />
        <span v-else>{{ attachedFiles.length }}</span>
      </button>
      <textarea
        v-model="newMessageText"
        rows="2"
        class="flex-grow-1 form-control"
        placeholder="Написать сообщение"
        @keypress="handleKeyPress"
        @keydown="handleKeyDown"
      />
      <button
        class="btn align-self-center ms-2"
        :disabled="requestInProgress"
        @click="sendMessage"
      >
        <font-awesome-icon
          icon="paper-plane"
          class="text-primary"
        />
      </button>
    </div>
  </div>
  <div
    v-else
    class="d-flex justify-content-center align-items-center"
  >
    <font-awesome-icon
      icon="comments"
      class="text-light plug"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { apiClient } from '../../custom_functions/api_client';
import { makeToast } from '../../custom_functions/custom_functions';
import { nextTick } from 'vue';

export default {
  name: 'SuppportConversation',

  data() {
    return {
      attachedFiles: [],
      hoverFilesBtn: false,
      newMessageText: '',
      requestInProgress: false,
    };
  },

  computed: {
    ...mapGetters('support', {
      conversation: 'getCurrentConversation',
      messages: 'getMessages',
      currentConversationId: 'getCurrentConversationId',
      currentPage: 'getMessagesCurrentPage',
      totalPages: 'getMessagesTotalPages',
    }),

    currentUser() {
      return this.$store.getters['profile/getCurrentUser'];
    },
  },

  created() {
    if (this.currentConversationId) {
      this.$store.commit('support/updateMessages', null);
      this.$store.dispatch(
        'support/connectMessagesCunsomer',
        this.currentConversationId,
      );
      this.$store.dispatch('support/fetchMessages', this.currentConversationId);
    }
    if (this.$route.query.review_content && this.$route.query.course_name) {
      this.newMessageText = `Добрый день! Вы оставили отзыв на курс ${this.$route.query.course_name}:\n"${this.$route.query.review_content}"\n`;
    }
  },

  unmounted() {
    if (this.$store.state.support.messagesCunsomer)
      this.$store.dispatch('support/disconnectMessagesCunsomer');
  },

  watch: {
    messages: {
      handler() {
        if (this.currentPage === 1) this.scrollToBottom();
      },
      deep: true,
    },

    currentConversationId(value) {
      if (this.$store.state.support.messagesCunsomer)
        this.$store.dispatch('support/disconnectMessagesCunsomer');
      if (value) {
        this.$store.dispatch('support/connectMessagesCunsomer', value);
        this.$store.commit('support/updateMessages', null);
        this.$store.dispatch('support/fetchMessages', value);
      }
      if (this.$route.query.review_content && this.$route.query.course_name) {
        this.newMessageText = `Добрый день! Вы отсавили отзыв на курс ${this.$route.query.course_name}:\n"${this.$route.query.review_content}"\n`;
      } else this.newMessageText = '';
      this.attachedFiles = [];
    },
  },

  methods: {
    formatDateTime(value) {
      if (moment(value).date() === moment(new Date()).date())
        return moment(value).format('HH:mm');
      if (moment(value).year() === moment(new Date()).year())
        return moment(value).format('D MMM HH:mm');
    },

    scrollToBottom() {
      if (this.$refs.messages) {
        nextTick(() => {
          this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight;
        });
      }
    },

    async resolve() {
      await apiClient.post(
        `v2/conversations/${this.$store.state.support.currentConversationId}/resolve`,
      );
      this.conversation.state = 'resolved';
      this.conversation.responsible_full_name = null;
      this.conversation.responsible_id = null;
    },

    async takeInWork() {
      await apiClient.patch(
        `v2/support/${this.$store.state.support.currentConversationId}/take`,
      );
      this.conversation.state = 'in_work';
      this.conversation.responsible_full_name = this.currentUser.full_name;
      this.conversation.responsible_id = this.currentUser.id;
    },

    attachFiles(event) {
      if (
        Array.from(event.target.files).some(
          (file) => file.size > 10 * 1024 * 1024,
        )
      )
        return makeToast('Файл слишком большой', 'danger');
      if (event.target.files.length > 5)
        return makeToast('Нельзя прикрепить более 5 файлов', 'danger');
      this.attachedFiles = [];
      this.attachedFiles = event.target.files;
    },

    removeFiles() {
      this.attachedFiles = [];
      this.hoverFilesBtn = false;
    },

    handleKeyDown(event) {
      if (
        event.keyCode === 13 &&
        (event.shiftKey || event.metaKey || event.ctrlKey)
      ) {
        event.preventDefault();
        this.newMessageText = `${this.newMessageText}\n`;
      }
    },

    handleKeyPress(event) {
      if (
        event.keyCode === 13 &&
        !event.shiftKey &&
        !event.metaKey &&
        !event.ctrlKey
      ) {
        event.preventDefault();
        this.sendMessage();
      }
    },

    async sendMessage() {
      if (!this.newMessageText.trim()) {
        makeToast('Пожалуйста, введите сообщение', 'danger');
        return;
      }

      const axiosConfig = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const postData = new FormData();
      postData.append('text', this.newMessageText.trim());
      this.attachedFiles.forEach((file) => {
        postData.append('attachments[]', file);
      });

      this.requestInProgress = true;

      try {
        await apiClient.post(
          `v2/conversations/${this.currentConversationId}/answers`,
          postData,
          axiosConfig,
        );

        this.newMessageText = '';
        this.attachedFiles = [];
      } finally {
        this.requestInProgress = false;
      }
    },

    async getMessagesOnScroll() {
      const trigger = this.$refs.moreMessages;
      if (trigger) {
        if (
          trigger.getBoundingClientRect().bottom > 57 &&
          !this.requestInProgress
        ) {
          this.requestInProgress = true;
          await this.$store.dispatch(
            'support/fetchMoreMessages',
            this.currentConversationId,
          );
          this.requestInProgress = false;
        }
      }
    },

    linkToPageOfMessageSending() {
      const hostname = window.location.hostname;
      let url;
      if (hostname === 'lvh.me' || hostname === 'localhost')
        url = 'http://lvh.me:8080';
      else if (hostname === 'admin2.dev.slurm.io')
        url = 'https://frontend.dev.slurm.io';
      else url = 'https://edu.slurm.io';
      return url + this.conversation.latest_source_url;
    },
  },
};
</script>

<style scoped>
.plug {
  width: 20%;
  height: auto;
}
.messages {
  overflow-y: auto;
  background: #efebe7;
}
.message {
  max-width: 60%;
  width: fit-content;
  white-space: pre-line;
}
.question {
  background: white;
}
.answer {
  background: #e4e1de;
}

.file-btn {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
}

.file-btn-success {
  background-color: #c2f4df;
  color: #2dce89;
  font-weight: bold;
}

.file-btn-danger {
  background-color: #fee1e7;
  color: #f5365c;
}

.attached-file {
  width: 60px;
  height: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
}
</style>
