import validateProductDescription from './helpers/product/validate_product_description';
import validateProductExternalUrl from './helpers/product/validate_product_external_url';
import validateNotEmptyField from './helpers/validate_not_empty_field';
import validateUniqueName from './helpers/validate_unique_name';

export default function validateProduct(value, { otherProducts } = {}) {
  const errors = {};

  if (!value || !otherProducts) {
    errors.product = [];
    if (!value) errors.product.push('Курс не может быть пустым');
    if (!otherProducts) {
      errors.product.push(
        'Невозможно проверить курс, пока не загружены все курсы',
      );
    }

    return errors;
  }

  const name = validateUniqueName(value.name, {
    otherNames: otherProducts
      .filter((product) => product.id !== value.id)
      .map((product) => product.name),
  });
  if (name.length) errors.name = name;

  const description = validateProductDescription(value.description);
  if (description.length) errors.description = description;

  const direction_ids = validateNotEmptyField(value.direction_ids);
  if (direction_ids.length) errors.direction_ids = direction_ids;

  const external_url = validateProductExternalUrl(value.external_url, {
    publicationStatus: value.publication_status,
  });
  if (external_url.length) errors.external_url = external_url;

  const po_user_id = validateNotEmptyField(value.po_user_id);
  if (po_user_id.length) errors.po_user_id = po_user_id;

  if (value.publication_status === 'published') {
    const course_ids = validateNotEmptyField(value.course_ids);
    if (course_ids.length) errors.course_ids = course_ids;
  }

  return errors;
}
