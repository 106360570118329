<template>
  <div class="container">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <h2>Заказы</h2>
      <router-link
        class="btn btn-primary"
        :to="{ name: 'orders_new' }"
      >
        Новый заказ
      </router-link>
    </div>

    <div v-if="orders">
      <div class="row">
        <div class="col">
          <label class="mb-2">От</label>
          <b-form-datepicker
            v-model="dateFrom"
            placeholder=""
            locale="ru"
            class="form-control"
          />
        </div>
        <div class="col">
          <label class="mb-2">До</label>
          <b-form-datepicker
            v-model="dateTo"
            placeholder=""
            locale="ru"
            class="form-control"
          />
        </div>

        <div class="col">
          <label class="mb-2">Курсы</label>

          <v-select
            v-if="courses"
            v-model:model-value="courseIds"
            multiple
            taggable
            :filterable="false"
            label="name"
            :options="courses"
            :reduce="(course) => course.id"
            class="mb-3"
          >
            <template #search="{ attributes, events }">
              <input
                class="vs__search"
                v-bind="attributes"
                @blur="events.blur"
                @focus="events.focus"
                @change="events.input"
              />
            </template>
          </v-select>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col">
          <label class="mb-2">Источник</label>
          <select
            v-model="utmSource"
            class="form-select"
          >
            <option />
            <option value="edu">Каталог</option>
            <option value="tilda">Tilda</option>
          </select>
        </div>

        <div class="col">
          <label class="mb-2">Состояние</label>
          <select
            v-model="state"
            class="form-select"
          >
            <option />
            <option value="idle">Новый</option>
            <option value="processing">В обработке</option>
            <option value="completed">Завершён</option>
            <option value="waiting_for_payment">Ожидает оплаты</option>
          </select>
        </div>

        <div class="col">
          <label class="mb-2">Тип</label>
          <select
            v-model="commercial"
            class="form-select"
          >
            <option />
            <option value="true">Коммерческий</option>
            <option value="false">Бесплатный</option>
          </select>
        </div>

        <div class="col">
          <label class="mb-2">Email</label>
          <input
            v-model="email"
            type="text"
            class="form-control"
          />
        </div>
      </div>

      <table class="table table-sm table-hover table-responsive-lg mb-4">
        <thead>
          <tr>
            <th>ID</th>
            <th>Дата создания</th>
            <th>Email</th>
            <th>Сумма</th>
            <th>Оплачено</th>
            <th>Скидка</th>
            <th>Состояние</th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="order in orders"
            :key="order.id"
          >
            <td>
              <router-link
                :to="{ name: 'orders_show', params: { id: order.id } }"
              >
                {{ order.id }}
              </router-link>
            </td>
            <td>{{ formatDateTime(order.created_at) }}</td>
            <td>{{ order.user_email }}</td>
            <td>{{ formatPrice(order.total_amount) }}</td>
            <td>{{ formatPrice(order.total_payments) }}</td>
            <td>
              <template v-if="order.discount_percents">
                {{ order.discount_percents }} %
              </template>
            </td>
            <td>{{ localizeState(order.state) }}</td>
          </tr>
          <tr>
            <td colspan="3">
              <strong>Итого</strong>
            </td>
            <td>{{ formatPrice(totalPayments) }}</td>
            <td colspan="2" />
          </tr>
        </tbody>
      </table>

      <b-pagination
        v-if="totalPages > 1"
        v-model="page"
        :total-rows="orders.length * totalPages"
        :per-page="orders.length"
        class="position-sticky"
        style="bottom: 10px"
        @change="goToPage($event)"
      />
    </div>
    <div
      v-else
      class="loader text-center"
    >
      <b-spinner />
    </div>
  </div>
</template>

<script>
import {
  formatDateTime,
  formatPrice,
} from '@/custom_functions/custom_functions';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import { mapGetters } from 'vuex';

export default {
  name: 'OrdersIndex',

  data() {
    return {
      orders: null,
      totalPages: null,
      dateFrom: this.$route.query.date_from,
      dateTo: this.$route.query.date_to,
      page: this.$route.query.page || 1,
      state: this.$route.query.state,
      commercial: this.$route.query.commercial,
      utmSource: this.$route.query.utm_source,
      email: null,
      totalPayments: 0,
      courseIds: [],
    };
  },

  created() {
    this.fetchOrders();
    // тут с current_price - чтобы в orders/New сразу были нужные значения
    this.$store.dispatch('courses/fetchCoursesOmitPagination', {
      fields: ['current_price'],
    });
  },

  computed: {
    ...mapGetters('courses', { courses: 'getCoursesOmitPagination' }),

    params() {
      const params = {
        date_from: this.dateFrom,
        date_to: this.dateTo,
        state: this.state,
        commercial: this.commercial,
        utm_source: this.utmSource,
        course_ids: this.courseIds,
        email: this.email,
      };
      return pickBy(params, identity);
    },
  },

  watch: {
    params() {
      this.page = 1;
      this.fetchOrders();
    },

    page() {
      this.fetchOrders();
    },
  },

  methods: {
    formatDateTime,
    formatPrice,

    async fetchOrders() {
      const query = { ...this.params, page: this.page };
      await this.$router.replace({ query });
      const response = await this.$apiClient.get('v2/orders', {
        params: query,
      });
      this.orders = response.data.orders;
      this.totalPages = response.data.meta.total_pages;
      this.totalPayments = response.data.meta.total_payments;
    },

    goToPage(page) {
      this.page = page;
    },

    localizeState(name) {
      const mapping = {
        idle: 'Новый',
        processing: 'В обработке',
        completed: 'Завершён',
        waiting_for_payment: 'Ожидает оплаты',
      };
      return mapping[name];
    },
  },
};
</script>
