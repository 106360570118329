<template>
  <div>
    <template v-if="hasCalendarSettings">
      <h2 class="h4 mb-3">Даты и календарь</h2>
      <CourseCalendarSettings />
      <h2 class="h4 mb-3">Настройки</h2>
    </template>
    <CourseSettings />
  </div>
</template>

<script setup>
import CourseCalendarSettings from '@/components/courses/CourseCalendarSettings';
import CourseSettings from '@/components/courses/CourseSettings';

import { useStore } from 'vuex';
import { computed, watch } from 'vue';

const store = useStore();
const course = computed(() => store.getters['course/getCourse']);

const hasCalendarSettings = computed(() => {
  return (
    course.value.course_type === 'slurm' ||
    course.value.displayed_type === 'online_course'
  );
});

watch(hasCalendarSettings, () => {
  if (!hasCalendarSettings.value) {
    course.value.date_from = course.value.date_to = null;
    course.value.show_in_calendar = false;
  }
});

if (!hasCalendarSettings.value) {
  course.value.date_from = course.value.date_to = null;
  course.value.show_in_calendar = false;
}
</script>
