<template>
  <form>
    <div class="row">
      <CustomSelect
        id="product-po-user-id"
        v-model:model-value="product.po_user_id"
        :options="productOwnersOptions"
        :errors="errors.po_user_id"
        :search="(query) => store.dispatch('course/fetchProductOwners', query)"
        label="Директор"
        tip="Список пользователей с ролью slurm_manager"
        class="col-6 mb-3"
        required
      />
    </div>
    <div class="row">
      <CustomSelect
        id="product-courses-ids"
        v-model:model-value="product.course_ids"
        :options="coursesIdsOptions"
        :errors="errors.course_ids"
        label="Тарифы внутри курса"
        tip="Выберите из существующих тарифов"
        class="col-12 mb-3"
        multiple
        :required="product.publication_status === 'published'"
      />
    </div>
  </form>
</template>

<script setup>
import CustomSelect from '@/components/CustomSelect';

import { computed } from 'vue';
import { useStore } from 'vuex';

import { useProductOwners } from '@/composables/courses/use_product_owners';
import { useCustomSelectOptions } from '@/composables/use_custom_select_options';

const store = useStore();

const product = computed(() => store.getters['product/getProduct']);
const errors = computed(() => store.getters['product/getErrors']);

const { productOwnersOptions } = useProductOwners();

const courses = computed(
  () => store.getters['courses/getCoursesOmitPagination'],
);
const coursesIdsOptions = computed(() =>
  useCustomSelectOptions(courses.value, 'id', 'name'),
);
</script>
