<template>
  <form
    class="input-group courses-search"
    @submit.prevent="setSearch"
  >
    <input
      v-model="search"
      class="form-control bg-white"
      :placeholder="placeholder"
      :aria-label="placeholder"
      :disabled="disabled"
    />
    <button
      class="btn btn-outline-primary"
      type="submit"
      :disabled="disabled"
    >
      Найти
    </button>
  </form>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

defineProps({
  placeholder: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
});

const search = ref(route.query.search);

watch(route, (newRoute) => {
  search.value = newRoute.query.search;
});

function setSearch() {
  if (route.query.search !== search.value) {
    router.push({ query: { ...route.query, search: search.value, page: 1 } });
  }
}
</script>

<style scoped>
.courses-search {
  max-width: 450px;
}
</style>
