<template>
  <form @submit.prevent>
    <CourseType />
    <CustomSelect
      id="container-linked-courses"
      v-model:model-value="container.linked_courses_ids"
      label="Какие тарифы входят"
      :options="coursesOptions"
      multiple
      :errors="errors.linked_courses_ids"
      required
      class="mb-3"
    />
    <div class="row">
      <div class="col-6 mb-3">
        <label
          for="container-name"
          class="form-label"
        >
          Название*
        </label>
        <input
          id="container-name"
          v-model.trim="container.name"
          type="text"
          class="form-control"
          :class="errors.name && 'is-invalid'"
        />
        <span
          v-for="(error, index) in errors.name"
          :key="index"
          class="invalid-feedback"
        >
          {{ error }}
        </span>
      </div>
    </div>
    <div class="row">
      <CourseUrl
        course-item-type="container"
        class="col-6 mb-3"
      />
    </div>
    <div class="row">
      <CustomSelect
        id="container-po-user-id"
        v-model:model-value="container.po_user_id"
        label="Директор"
        required
        :options="poUserIdOptions"
        :errors="errors.po_user_id"
        class="col-6 mb-3"
        tip="Список пользователей с ролью slurm_manager"
      />
    </div>
    <div class="row">
      <NumberInput
        id="container-expire-days"
        v-model:model-value="container.expire_days"
        label="Срок доступа к курсам в днях"
        required
        tip="Сколько дней курсы доступны к прохождению"
        :errors="errors.expire_days"
        class="col-6 mb-3"
      />
    </div>
  </form>
</template>

<script setup>
import CustomSelect from '@/components/CustomSelect';
import CourseType from '@/components/courses/CourseType';
import CourseUrl from '@/components/courses/CourseUrl';
import NumberInput from '@/components/NumberInput';

import { computed, watch } from 'vue';
import { useStore } from 'vuex';

import { useCustomSelectOptions } from '@/composables/use_custom_select_options';

const store = useStore();

const container = computed(() => store.getters['container/getContainer']);

const courses = computed(
  () => store.getters['courses/getCoursesOmitPagination'],
);
const coursesOptions = computed(() =>
  useCustomSelectOptions(courses.value, 'id', 'name'),
);

const errors = computed(() => store.getters['container/getErrors']);

watch(
  () => container.value.linked_courses_ids,
  (value) => {
    if (['bundle', 'container'].includes(container.value.course_type)) {
      container.value.name = value
        .map(
          (linkedCoursesId) =>
            courses.value.find((course) => course.id === linkedCoursesId).name,
        )
        .join(' + ');
    }
  },
  { deep: true },
);

const productOwners = computed(() => store.getters['course/getProductOwners']);
const poUserIdOptions = computed(() => {
  return useCustomSelectOptions(productOwners.value, 'id', 'full_name');
});
</script>
