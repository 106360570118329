<template>
  <b-tabs v-model="tabValue">
    <b-tab
      v-for="tab in tabs"
      :key="tab.value"
    >
      <template #title>
        <TabTitle
          :title="tab.title"
          :errors="errors"
          :keys="tab.fieldKeys"
        />
      </template>
      <component
        :is="tab.component"
        class="mt-3"
        course-item-type="container"
      />
    </b-tab>
  </b-tabs>
</template>

<script setup>
import TabTitle from '@/components/TabTitle';
import ContainerMain from '@/components/courses/ContainerMain';
import CoursePrice from '@/components/courses/CoursePrice';
import ContainerPromotion from '@/components/courses/ContainerPromotion';
import CourseParticipations from '@/components/courses/CourseParticipations';

import { useRouteTabs } from '@/composables/use_route_tabs';

import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();

const errors = computed(() => store.getters['container/getErrors']);

const props = defineProps({ isNew: { type: Boolean, default: false } });

let tabs = [
  {
    value: 'main',
    title: 'Основное',
    fieldKeys: [
      'displayed_type',
      'linked_courses_ids',
      'name',
      'url',
      'po_user_id',
      'expire_days',
    ],
    component: ContainerMain,
    isNew: true,
  },
  {
    value: 'price',
    title: 'Цена',
    fieldKeys: ['price'],
    component: CoursePrice,
    isNew: true,
  },
  {
    value: 'promotion',
    title: 'Продвижение',
    fieldKeys: [
      'external_url',
      'description',
      'icon_url',
      'experience_level',
      'direction_ids',
      'tags',
      'extended_description',
    ],
    component: ContainerPromotion,
    isNew: true,
  },
  {
    value: 'students',
    title: 'Студенты',
    fieldKeys: [],
    component: CourseParticipations,
    isNew: false,
  },
];

if (props.isNew) tabs = tabs.filter((tab) => tab.isNew);

const { tabValue } = useRouteTabs(tabs, 'main');
</script>
