import { apiClient } from '../../custom_functions/api_client';

export default {
  namespaced: true,

  state: {
    speakers: null,
  },

  getters: {
    getSpeakers: (state) => state.speakers,
  },

  actions: {
    async fetchSpeakers({ commit }) {
      const response = await apiClient.get('/v2/speakers');
      commit('updateSpeakers', response.data.speakers);
    },
  },

  mutations: {
    updateSpeakers(state, speakers) {
      state.speakers = speakers;
    },
  },
};
