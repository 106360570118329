<template>
  <form
    v-if="unit.stand_type"
    @submit.prevent="saveUnit"
  >
    <div class="row">
      <div class="btn-group col-6 mb-2">
        <label
          class="btn"
          :class="
            unit.stand_type === 'kts' ? 'btn-primary' : 'btn-outline-primary'
          "
        >
          <input
            v-model="unit.stand_type"
            type="radio"
            class="d-none"
            value="kts"
            name="unit-stand-type"
          />
          Стенд KTS
        </label>
        <label
          id="unit-stand-type-kts-course-wide"
          class="btn custom-disabled"
          :class="
            unit.stand_type === 'kts_course_wide'
              ? 'btn-primary'
              : 'btn-outline-primary'
          "
        >
          <input
            type="radio"
            class="d-none"
            value="kts_course_wide"
            name="unit-stand-type"
            disbaled
          />
          KTS: общий стенд на курс
          <b-tooltip target="unit-stand-type-kts-course-wide">
            В разработке
          </b-tooltip>
        </label>
        <label
          class="btn"
          :class="
            unit.stand_type === 'selectel'
              ? 'btn-primary'
              : 'btn-outline-primary'
          "
        >
          <input
            v-model="unit.stand_type"
            type="radio"
            class="d-none"
            value="selectel"
            name="unit-stand-type"
          />
          Стенд Selectel
        </label>
      </div>
    </div>
    <transition
      name="fade"
      mode="out-in"
    >
      <div v-if="showKtsStands">
        <div class="row">
          <NumberInput
            id="unit-hours"
            v-model:model-value="unit.hours"
            label="Объём часов"
            tip="Через столько часов площадки студентов остановятся и удалятся"
            class="col-6"
          />
        </div>
        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label for="unit-kts-stand-namespace">namespace стенда</label>
              <input
                id="unit-kts-stand-namespace"
                v-model.lazy="unit.kts_stand_namespace"
                type="text"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <NumberInput
            id="unit-after-stand-session"
            v-model:model-value="unit.after_stand_sessions"
            label="Количество попыток начать сначала"
            class="col-6"
          />
        </div>
      </div>
      <div
        v-else
        key="selestl-stands-form"
      >
        <div class="row">
          <NumberInput
            id="unit-hours"
            v-model:model-value="unit.hours"
            label="Объём часов"
            tip="Через столько часов площадки студентов остановятся и удалятся"
            class="col-6"
          />
        </div>
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="unit-script-start">Скрипт создания стенда</label>
              <input
                id="unit-script-start"
                v-model.lazy="unit.script_start"
                type="text"
                class="form-control"
              />
              <small class="text-muted form-text">
                ssh://user@host/~/script.sh
              </small>
            </div>
            <div class="mb-3">
              <label for="unit-script-stop">Скрипт уничтожения стенда</label>
              <input
                id="unit-script-stop"
                v-model.lazy="unit.script_stop"
                type="text"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <NumberInput
            id="unit-after-stand-session"
            v-model:model-value="unit.after_stand_sessions"
            label="Количество запусков стендов"
            class="col-6"
          />
        </div>
      </div>
    </transition>
    <button
      class="btn btn-outline-primary"
      type="sumbit"
    >
      Сохранить
    </button>
  </form>
</template>

<script>
import NumberInput from '@/components/NumberInput.vue';
import { makeToast } from '@/custom_functions/custom_functions';

export default {
  name: 'UnitStands',

  components: { NumberInput },

  data() {
    return {
      unit: { stand_type: 'kts', hours: 0, after_stand_sessions: 0 },
    };
  },

  computed: {
    showKtsStands() {
      return this.unit.stand_type.includes('kts');
    },
  },

  created() {
    const currentUnit = this.$store.getters['unit/getUnit'];
    if (!currentUnit.stand_type)
      this.unit.stand_type =
        currentUnit.start_script && currentUnit.end_script ? 'selectel' : 'kts';
    this.unit = { ...this.unit, ...currentUnit };
  },

  methods: {
    async saveUnit() {
      await this.$store.dispatch('unit/saveUnit', this.unit);
      makeToast('Изменения в модуле сохранены', 'success');
    },
  },
};
</script>

<style scoped>
.custom-disabled {
  opacity: 0.7;
  cursor: default !important;
}
.custom-disabled:hover,
.custom-disabled:focus,
.custom-disabled:active {
  background-color: white !important;
  color: var(--bs-primary) !important;
  cursor: default !important;
}
</style>
