<template>
  <div class="row">
    <NumberInput
      id="course-expire-days"
      v-model:model-value="course.expire_days"
      :errors="errors.expire_days"
      class="col-6"
      label="Срок доступа к курсу в днях"
      tip="Сколько дней курс доступен к прохождению"
      required
    />
  </div>
</template>

<script setup>
import NumberInput from '@/components/NumberInput.vue';

import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();

const course = computed(() => store.getters['course/getCourse']);
const errors = computed(() => store.getters['course/getErrors']);
</script>
