<template>
  <div class="d-flex">
    <div class="me-2">
      <b-dropdown
        id="meta"
        text="Действия"
      >
        <b-dropdown-item @click="runSlurmJob()">SlurmJob</b-dropdown-item>
      </b-dropdown>
    </div>

    <div
      class="btn-group me-3"
      role="group"
    >
      <button
        v-for="filter in filters"
        :key="filter.value"
        class="btn"
        :class="
          params.filter === filter.value ? 'btn-primary' : 'btn-outline-primary'
        "
        @click="setSessionsFilter(filter.value)"
      >
        {{ filter.title }}
      </button>
    </div>

    <form
      class="input-group searching-form"
      @submit.prevent="findSessions"
    >
      <input
        v-model="query"
        placeholder="Почта или логин студента"
        type="text"
        class="form-control"
      />
      <button
        class="btn btn-primary"
        type="submit"
      >
        Поиск
      </button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'StandsSessionsSettings',

  props: {
    params: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      query: null,
      filters: [
        { title: 'Запущенные', value: 'running' },
        { title: 'Остановленные', value: 'stopped' },
        { title: 'С ошибками', value: 'failed' },
        { title: 'Все', value: null },
      ],
    };
  },

  methods: {
    async runSlurmJob() {
      await this.$apiClient.post('/v2/meta/cron/slurm_job');
      makeToast('Создание стендов для интенсивов запущено', 'primary');
    },

    async setSessionsFilter(filter) {
      this.params.filter = filter;
      if (this.$route.params.page != 1)
        await this.$router.replace({ params: { page: 1 } });
      this.$store.dispatch('standsSessions/fetchSessions', this.params);
    },

    async findSessions() {
      this.params.query = this.query;
      if (this.$route.params.page != 1)
        await this.$router.replace({ params: { page: 1 } });
      this.$store.dispatch('standsSessions/fetchSessions', this.params);
    },
  },
};
</script>

<style scoped>
.searching-form {
  width: 500px;
}
</style>
