import { apiClient } from '@/custom_functions/api_client';
import { validateUnit } from '@/custom_functions/validation_functions';
import moment from 'moment';

export default {
  namespaced: true,

  state: {
    unit: null,
    errors: {},
  },

  getters: {
    getUnit: (state) => state.unit,
    getUnitLessons: (state) => (state.unit ? state.unit.lessons : null),
    getUnitId: (state) => (state.unit ? state.unit.id : null),
    getErrors: (state) => state.errors,
  },

  actions: {
    async fetchUnit({ commit }, id) {
      commit('updateErrors', {});
      const response = await apiClient.get(`/v2/units/${id}`);
      commit('updateUnit', response.data.unit);
    },

    async saveUnit({ commit, state }, unit) {
      commit('updateErrors', validateUnit(unit));
      if (!Object.keys(state.errors).length) {
        if (unit.stand_type) {
          if (unit.stand_type.includes('kts'))
            unit.script_start = unit.script_stop = null;
          else unit.kts_stand_namespace = null;
        } else
          unit.script_start =
            unit.script_stop =
            unit.kts_stand_namespace =
              null;

        unit.has_submissions_deadline = unit.deadline.hasSubmissionsDeadline;

        if (unit.deadline.hasSubmissionsDeadline) {
          if (unit.deadline.hasSubmissionsDeadline && !unit.deadline.timeFrom)
            unit.deadline.timeFrom = '00:00:00';
          if (unit.deadline.hasSubmissionsDeadline && !unit.deadline.timeTo)
            unit.deadline.timeTo = '00:00:00';
          unit.submissions_start_at = `${moment(unit.deadline.dateFrom).format(
            'YYYY-MM-DD',
          )}T${unit.deadline.timeFrom}+03:00`;
          unit.submissions_deadline_at = `${moment(unit.deadline.dateTo).format(
            'YYYY-MM-DD',
          )}T${unit.deadline.timeTo}+03:00`;
          unit.accept_rework_submissions_after_deadline =
            unit.deadline.acceptReworkSubmissionsAfterDeadline;
        } else {
          unit.submissions_start_at = null;
          unit.submissions_deadline_at = null;
          unit.accept_rework_submissions_after_deadline = null;
        }

        if (unit.autoPublication.hasSubmissionsAutoPublication) {
          unit.available_at = `${moment(unit.autoPublication.dateFrom).format(
            'YYYY-MM-DD',
          )}T${unit.autoPublication.timeFrom}+03:00`;
        } else {
          unit.available_at = null;
        }

        delete unit.autoPublication;
        delete unit.deadline;

        const response = await apiClient.patch(`/v2/units/${unit.id}`, {
          unit,
        });

        commit('updateUnit', response.data.unit);
      }
    },

    async saveLessonsOrder({ commit, state }, lessons) {
      if (lessons) {
        const newLessons = lessons.map((lesson, index) =>
          Object.assign({}, lesson, { weight: index + 1 }),
        );
        const response = await apiClient.patch(
          `/v2/units/${state.unit.id}/lessons/reorder`,
          {
            lessons: newLessons,
          },
        );
        commit('updateLessons', response.data.lessons);
      }
    },

    async saveLessonsStatus({ commit, state }, payload) {
      if (payload && payload.ids && payload.status) {
        const response = await apiClient.patch(
          `/v2/units/${state.unit.id}/lessons`,
          {
            lessons: { id: payload.ids, publication_status: payload.status },
          },
        );
        commit('updateLessons', response.data.lessons);
      }
    },

    async deleteLessons({ commit, state }, ids) {
      if (ids) {
        const response = await apiClient.delete(
          `/v2/units/${state.unit.id}/lessons`,
          {
            data: { lessons: { id: ids } },
          },
        );
        commit('updateLessons', response.data.lessons);
      }
    },

    async createUnit({ commit, state }, unit) {
      commit('updateErrors', validateUnit(unit));

      let newUnit = {
        course_id: unit.courseId,
        name: unit.unitName,
        description: unit.unitDescription,
        memo: unit.unitMemo,
        publication_status: unit.publicationStatus,
      };

      if (unit.dateFrom)
        newUnit.date_from = moment(`${unit.dateFrom} ${unit.timeFrom}:00`);
      if (unit.dateTo)
        newUnit.date_to = moment(`${unit.dateTo} ${unit.timeTo}:00`);

      newUnit.has_submissions_deadline = unit.deadline.hasSubmissionsDeadline;

      if (unit.deadline.hasSubmissionsDeadline) {
        if (unit.deadline.hasSubmissionsDeadline && !unit.deadline.timeFrom)
          unit.deadline.timeFrom = '00:00:00';
        if (unit.deadline.hasSubmissionsDeadline && !unit.deadline.timeTo)
          unit.deadline.timeTo = '00:00:00';
        newUnit.submissions_start_at = `${moment(unit.deadline.dateFrom).format(
          'YYYY-MM-DD',
        )}T${unit.deadline.timeFrom}+03:00`;
        newUnit.submissions_deadline_at = `${moment(
          unit.deadline.dateTo,
        ).format('YYYY-MM-DD')}T${unit.deadline.timeTo}+03:00`;
        newUnit.accept_rework_submissions_after_deadline =
          unit.deadline.acceptReworkSubmissionsAfterDeadline;
      }

      if (unit.autoPublication.hasSubmissionsAutoPublication) {
        newUnit.available_at = `${moment(unit.autoPublication.dateFrom).format(
          'YYYY-MM-DD',
        )}T${unit.autoPublication.timeFrom}+03:00`;
      }

      if (!Object.keys(state.errors).length) {
        const response = await apiClient.post('/v2/units', {
          unit: { ...newUnit },
        });
        commit('updateUnit', response.data.unit);
      }
    },
  },

  mutations: {
    updateUnit(state, unit) {
      state.unit = unit;
    },

    updateLessons(state, lessons) {
      state.unit.lessons = lessons;
    },

    updateErrors(state, errors) {
      state.errors = errors;
    },
  },
};
