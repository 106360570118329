<template>
  <div class="container">
    <div
      class="d-flex flex-row justify-content-between align-items-center mb-4"
    >
      <h2>Новый юнит</h2>
      <div class="d-flex flex-row">
        <CustomSelect
          id="publication-status"
          v-model:model-value="publicationStatus"
          :options="statuses"
          class="me-3 publication-status"
        />
        <button
          class="btn btn-outline-primary"
          type="submit"
          form="unit-form"
        >
          Сохранить
        </button>
      </div>
    </div>
    <form
      v-if="course"
      id="unit-form"
      class="form-margin"
      @submit.prevent="createUnit"
    >
      <div class="col-6 mb-3">
        <label class="mb-2">Название</label>
        <input
          v-model.lazy="unitName"
          type="text"
          class="form-control"
        />
      </div>

      <div
        v-if="course.course_type === 'slurm'"
        class="mb-3"
      >
        <label class="mb-2">Дата начала</label>

        <div class="row">
          <div class="col-md-6">
            <b-form-datepicker
              v-model="dateFrom"
              placeholder=""
              locale="ru"
              class="form-control"
            />
          </div>

          <div class="col-md-6">
            <input
              v-model.lazy="timeFrom"
              type="time"
              class="form-control"
              optional
            />
          </div>
        </div>
      </div>

      <div
        v-if="course.course_type === 'slurm'"
        class="mb-3"
      >
        <label class="mb-2">Дата конца</label>

        <div class="row">
          <div class="col-md-6">
            <b-form-datepicker
              v-model="dateTo"
              placeholder=""
              locale="ru"
              class="form-control"
            />
          </div>

          <div class="col-md-6">
            <input
              v-model.lazy="timeTo"
              type="time"
              class="form-control"
              optional
            />
          </div>
        </div>
      </div>

      <div class="mb-3 col-6">
        <label class="mb-2">Описание</label>
        <textarea
          v-model.lazy="unitDescription"
          class="form-control"
          rows="3"
          required
        />
      </div>

      <div class="mb-5">
        <label class="mb-2">Памятка</label>
        <div class="col-6">
          <Editor
            id="unitMemo"
            v-model:model-value="unitMemo"
          />
        </div>
      </div>

      <UnitDeadline
        v-if="
          ['stepik', 'video'].includes(course.course_type) &&
          course.displayed_type === 'online_course'
        "
        v-model:model-value="deadline"
        :errors="errors"
        class="mb-5"
      />

      <UnitAutoPublication
        v-if="
          ['stepik', 'video'].includes(course.course_type) &&
          course.displayed_type === 'online_course' &&
          publicationStatus === 'draft'
        "
        v-model:model-value="autoPublication"
        :errors="errors"
      />
    </form>
    <div
      v-else
      class="loader text-center"
    >
      <b-spinner />
    </div>
  </div>
</template>

<script>
import CustomSelect from '@/components/CustomSelect.vue';
import UnitAutoPublication from '@/components/units/UnitAutoPublication.vue';
import UnitDeadline from '@/components/units/UnitDeadline.vue';
import { makeToast } from '@/custom_functions/custom_functions';
import { getPublicationStatusesForSelect } from '@/custom_functions/publication_statuses';
import { validateUnit } from '@/custom_functions/validation_functions';

export default {
  name: 'UnitsNew',

  components: {
    UnitDeadline,
    UnitAutoPublication,
    CustomSelect,
  },

  data() {
    return {
      statuses: null,

      publicationStatus: 'draft',
      unitName: '',
      unitDescription: null,
      unitMemo: '',
      dateTo: null,
      dateFrom: null,
      timeFrom: '10:00',
      timeTo: '19:00',
      course: null,
      autoPublication: {
        hasSubmissionsAutoPublication: false,
        dateFrom: null,
        timeFrom: '08:00',
      },
      deadline: {
        hasSubmissionsDeadline: false,
        dateFrom: null,
        timeFrom: null,
        dateTo: null,
        timeTo: null,
        acceptReworkSubmissionsAfterDeadline: null,
        errors: {},
      },
    };
  },

  computed: {
    errors() {
      return this.$store.getters['unit/getErrors'];
    },
  },

  created() {
    this.getCourse();
    this.statuses = getPublicationStatusesForSelect({ except: ['archived'] });
  },

  watch: {
    publicationStatus(status) {
      if (status !== 'draft') {
        this.autoPublication.hasSubmissionsAutoPublication = false;
        this.autoPublication.dateFrom = null;
        this.autoPublication.timeFrom = '08:00';
      }
    },

    autoPublication: {
      handler(value) {
        if (Object.keys(this.errors).length)
          this.$store.commit('unit/updateErrors', validateUnit(value));
      },
      deep: true,
    },
  },

  methods: {
    makeToast,

    async createUnit() {
      const newUnit = {
        unitName: this.unitName,
        unitDescription: this.unitDescription,
        unitMemo: this.unitMemo,
        courseId: this.$route.query.courseId,
        publicationStatus: this.publicationStatus,
        autoPublication: { ...this.autoPublication },
        deadline: { ...this.deadline },
      };

      await this.$store.dispatch('unit/createUnit', newUnit);

      if (!Object.keys(this.errors).length) {
        makeToast('Юнит сохранён', 'success');
        const unit = this.$store.getters['unit/getUnit'];
        await this.$router.replace(
          `/units/${unit.id}/edit?course=${this.$route.query.courseUrl}`,
        );
      }
    },

    async getCourse() {
      let response = await this.$apiClient.get(
        `/v2/courses/${this.$route.query.courseId}`,
      );
      this.course = response.data.course;
    },
  },
};
</script>

<style scoped>
.publication-status {
  min-width: 150px;
}

.form-margin {
  margin-bottom: 200px;
}
</style>
