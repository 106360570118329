<template>
  <RevertToast
    v-for="toast in revertToasts"
    :key="toast.id"
    :comment="toast.comment"
    :button-text="toast.buttonText"
    :time="toast.time"
    :model-value="true"
    @action="toast.action"
    @revert="toast.revert"
    @update:model-value="store.commit('toast/deleteRevertToast', toast.id)"
  />
</template>

<script setup>
import RevertToast from '@/components/RevertToast';

import { computed, getCurrentInstance, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const toastText = computed(() => store.getters['toast/getToastText']);

const toastOptions = computed(() => store.getters['toast/getToastOptions']);

const revertToasts = computed(() => store.getters['toast/getRevertToasts']);

const instance = getCurrentInstance();

watch(toastText, (value) => {
  if (value) {
    const bvToast = instance.ctx._bv__toast;
    bvToast.toast(value, toastOptions.value);
    store.commit('toast/changeToast', { text: null });
  }
});
</script>
