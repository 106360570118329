export function useCustomSelectOptions(options, value, name) {
  if (options) {
    if (value && name) {
      return options.map((option) => ({
        value: option[value],
        name: option[name],
      }));
    } else return options.map((option) => ({ value: option, name: option }));
  } else return null;
}
