<template>
  <div>
    <b-modal
      id="conflict-modal"
      v-model="shownModal"
      title="Кто-то изменил ресурс, пока вы его редактировали"
      size="xl"
      hide-footer
    >
      <div class="my-4">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Ваши изменения</th>
              <th>Изменения пользователя {{ theirChanges.user }}</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <div
                  v-for="(change, field) in omitSystemFields(myChanges)"
                  :key="field"
                  class="my-2"
                >
                  <ConflictFields
                    v-if="change[0] && change[1]"
                    :value1="change[0]"
                    :value2="change[1]"
                    :field="field"
                  />
                </div>
              </td>
              <td>
                <div
                  v-for="(change, field) in omitSystemFields(
                    theirChanges.changeset,
                  )"
                  :key="field"
                  class="my-2"
                >
                  <ConflictFields
                    v-if="change[0] && change[1]"
                    :value1="change[0]"
                    :value2="change[1]"
                    :field="field"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="d-flex justify-content-between">
          <b-button
            variant="primary"
            @click="applyMyChanges"
          >
            Применить мои изменения
          </b-button>
          <b-button
            variant="primary"
            @click="cancelMyChanges"
          >
            Отменить мои изменения и открыть актуальную версию
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ConflictFields from './ConflictFields';

export default {
  name: 'ConflictModal',
  components: { ConflictFields },
  props: {
    myChanges: [Object, Array],
    theirChanges: Object,
    modelValue: { type: Boolean, default: false },
  },

  data() {
    return { shownModal: false };
  },

  created() {
    this.shownModal = this.modelValue;
  },

  watch: {
    modelValue(value) {
      this.shownModal = value;
    },

    shownModal(value) {
      this.$emit('update:modelValue', value);
    },
  },

  methods: {
    omitSystemFields(changes) {
      if (changes)
        return (({ updated_at, lock_version, ...rest }) => rest)(changes); // eslint-disable-line no-unused-vars
    },

    applyMyChanges() {
      this.$emit('myChangesSelected', this.myChanges);
      this.shownModal = false;
    },

    cancelMyChanges() {
      this.$router.go();
    },
  },
};
</script>
