<template>
  <div class="container">
    <transition
      appear
      name="fade"
    >
      <div class="card mx-auto mt-5 border-0">
        <div class="card-header border-0 bg-secondary text-white text-center">
          Авторизуйтесь, пожалуйста
        </div>

        <div class="card-body">
          <form v-on:submit.prevent="handleSubmit">
            <div class="mb-3">
              <input
                v-model.lazy="email"
                type="email"
                name="email"
                class="form-control"
                placeholder="Email"
                autocomplete="email"
                autofocus
              />
            </div>

            <div class="mb-3">
              <input
                v-model.lazy="password"
                type="password"
                name="password"
                class="form-control"
                placeholder="Пароль"
                autocomplete="current-password"
              />
            </div>

            <button
              class="btn btn-secondary w-100"
              type="submit"
            >
              Войти
            </button>
          </form>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { makeToast } from '@/custom_functions/custom_functions';

export default {
  metaInfo: {
    title: 'Авторизация — Слёрм',
  },

  name: 'login', // eslint-disable-line vue/component-definition-name-casing

  data() {
    return {
      email: '',
      password: '',
    };
  },

  methods: {
    async handleSubmit() {
      if (this.password.length === 0 || this.email.length === 0) {
        this.$bvToast.toast('Ошибка. Данные не заполнены', {
          title: 'Уведомление',
          variant: 'danger',
          autoHideDelay: 6000,
        });
        return;
      }

      const response = await this.$apiClient.post('v2/authentication', {
        login: this.email,
        password: this.password,
      });
      if (
        response.headers['x-user-role'] === 'student' ||
        response.headers['x-user-role'] === 'reviewer'
      )
        makeToast(
          'Ошибка доступа. Запросите роль администратора в техподдержке',
          'danger',
        );
      this.$router.push(this.$route.query.nextUrl || '/');
    },
  },
};
</script>

<style scoped>
.card {
  max-width: 25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
}
</style>
