<template>
  <div class="mb-3">
    <table
      v-if="prices.length"
      class="table"
    >
      <thead>
        <tr>
          <th
            class="text-start"
            style="width: 10%"
          >
            Цена, ₽
          </th>
          <th
            class="text-center"
            style="width: 15%"
          >
            Действует до (включительно)
          </th>
          <th style="width: 35%" />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(price, index) in prices"
          :key="index"
        >
          <td class="text-start">
            <transition
              mode="out-in"
              name="fade"
            >
              <NumberInput
                v-if="
                  (mode == 'edit' || mode == 'new') &&
                  indexOfEditablePrice == index
                "
                v-model:model-value="price.value"
                class="mb-0"
              />
              <span
                v-else
                class="fw-bold my-1 d-block"
              >
                {{ price.value }}
              </span>
            </transition>
          </td>
          <td class="text-center">
            <transition
              mode="out-in"
              name="fade"
            >
              <b-form-datepicker
                v-if="
                  (mode == 'edit' || mode == 'new') &&
                  indexOfEditablePrice == index
                "
                v-model="price.end_date"
                :min="minEndDate(price)"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                locale="ru"
                placeholder="Выберите дату"
              />
              <span
                v-else
                class="my-1 d-block"
              >
                {{ formatDate(price.end_date) }}
              </span>
            </transition>
          </td>
          <td class="text-end">
            <transition
              mode="out-in"
              name="fade"
            >
              <div
                v-if="
                  (mode == 'edit' || mode == 'new') &&
                  indexOfEditablePrice == index
                "
              >
                <button
                  type="button"
                  class="btn btn-outline-secondary me-3"
                  @click="cancelChanges(index)"
                >
                  Отменить
                </button>
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="saveChanges(index)"
                >
                  Применить
                </button>
              </div>
              <div v-else>
                <button
                  :disabled="mode == 'edit' || mode == 'new'"
                  type="button"
                  class="btn me-3"
                  :class="
                    mode == 'edit' || mode == 'new'
                      ? 'btn-outline-secondary'
                      : 'btn-outline-primary'
                  "
                  @click="enableEditingMode(price, index)"
                >
                  Редактировать
                </button>
                <button
                  :disabled="mode == 'edit' || mode == 'new'"
                  type="button"
                  class="btn"
                  :class="
                    mode == 'edit' || mode == 'new'
                      ? 'btn-outline-secondary'
                      : 'btn-outline-danger'
                  "
                  @click="deletePrice(index)"
                >
                  Удалить
                </button>
              </div>
            </transition>
          </td>
        </tr>
      </tbody>
    </table>
    <button
      type="button"
      class="btn btn-outline-primary"
      :disabled="mode !== 'show'"
      @click.prevent="addPrice()"
    >
      Добавить цену по предзаказу
    </button>
  </div>
</template>

<script>
import { formatDate, makeToast } from '../../custom_functions/custom_functions';
import NumberInput from '@/components/NumberInput.vue';

export default {
  name: 'SeasonPrices',

  props: {
    modelValue: Array,
    initialPrices: { type: Array, default: () => [] },
    courseId: Number,
  },

  components: { NumberInput },

  data() {
    return {
      prices: [],
      mode: 'show',
      indexOfEditablePrice: -1,
      oldPrice: {},
    };
  },

  created() {
    if (this.initialPrices.length) this.prices = Array.from(this.initialPrices);
  },

  methods: {
    formatDate,

    addPrice() {
      let newPrice = {
        value: '',
        start_date: null,
        end_date: null,
        course_id: this.courseId,
      };
      let lastPrice = this.prices[this.prices.length - 1];
      newPrice.start_date = this.prices.length
        ? this.nextDate(lastPrice.end_date)
        : new Date();
      this.prices.push(newPrice);
      this.mode = 'new';
      this.indexOfEditablePrice = this.prices.length - 1;
    },

    minEndDate(price) {
      if (price && price.start_date) return new Date(price.start_date);
      return new Date();
    },

    async deletePrice(index) {
      let nextPrice = this.prices[index + 1];
      if (nextPrice) nextPrice.start_date = this.prices[index].start_date;
      if (!this.initialPrices.length) {
        this.prices.splice(index, 1);
        this.$emit('update:modelValue', this.prices);
      } else {
        await this.$apiClient.delete(
          `/v2/season_prices/${this.prices[index].id}`,
        );
        if (nextPrice) {
          const requestData = { season_price: nextPrice };
          await this.$apiClient.patch(
            `/v2/season_prices/${nextPrice.id}`,
            requestData,
          );
        }
        this.prices.splice(index, 1);
      }
    },

    async saveChanges(index) {
      let price = this.prices[index];
      let nextPrice = this.prices[index + 1];
      if (this.validationPrice(price)) {
        if (nextPrice) nextPrice.start_date = this.nextDate(price.end_date);
        if (!this.initialPrices.length)
          this.$emit('update:modelValue', this.prices);
        else {
          const requestDataPrice = { season_price: price };
          if (this.mode === 'new') {
            const response = await this.$apiClient.post(
              '/v2/season_prices',
              requestDataPrice,
            );
            this.prices[index] = response.data.season_price;
          }
          if (this.mode === 'edit') {
            if (nextPrice) {
              const requestDataNextPrice = { season_price: nextPrice };
              await this.$apiClient.patch(
                `/v2/season_prices/${nextPrice.id}`,
                requestDataNextPrice,
              );
            }
            await this.$apiClient.patch(
              `/v2/season_prices/${price.id}`,
              requestDataPrice,
            );
          }
        }
        this.disableEditingMode();
      }
    },

    cancelChanges(index) {
      if (this.mode == 'edit') Object.assign(this.prices[index], this.oldPrice);
      else if (this.mode == 'new') this.prices.splice(-1, 1);
      this.disableEditingMode();
    },

    enableEditingMode(price, index) {
      this.mode = 'edit';
      this.indexOfEditablePrice = index;
      Object.assign(this.oldPrice, price);
    },

    disableEditingMode() {
      this.oldPrice = {};
      this.mode = 'show';
      this.indexOfEditablePrice = -1;
    },

    nextDate(currentDate) {
      return new Date(Number(new Date(currentDate)) + 1000 * 60 * 60 * 24);
    },

    validationPrice(price) {
      if (
        price.value > 0 &&
        price.end_date &&
        new Date(price.end_date) > new Date(price.start_date)
      )
        return true;
      if (price.value <= 0) {
        makeToast('Введите цену', 'danger');
      }
      if (!price.end_date) {
        makeToast('Введите дату, до которой действует цена', 'danger');
      }
      if (new Date(price.end_date) <= new Date(price.start_date)) {
        makeToast(
          'Дата окончания действия цены должна быть больше даты предыдущей цены',
          'danger',
        );
      }
      return false;
    },
  },
};
</script>
