<template>
  <div
    v-if="webinar && questions"
    class="container"
  >
    <h2 class="mb-3">{{ webinar.name }}</h2>
    <b-tabs>
      <b-tab
        title="Основное"
        active
      >
        <WebinarsForm
          :webinar="webinar"
          class="mt-3 mb-3"
        />
        <div>
          <button
            class="btn btn-primary me-3"
            @click="saveWebinar"
          >
            Сохранить
          </button>
          <button
            class="btn btn-danger me-3"
            @click="$bvModal.show('delete-webinar-modal')"
          >
            Удалить
          </button>
          <router-link
            class="btn btn-secondary"
            to="/webinars/page_1"
          >
            Отменить
          </router-link>

          <b-modal
            id="delete-webinar-modal"
            hide-footer
          >
            <template v-slot:modal-title>Подтвердите действие</template>
            <div class="d-block text-center">
              <h4>Вебинар «{{ webinar.name }}» будет удалён</h4>
            </div>
            <b-button
              class="mt-3"
              block
              variant="danger"
              @click="deleteWebinar"
            >
              Удалить
            </b-button>
            <b-button
              class="mt-3"
              block
              @click="$bvModal.hide('delete-webinar-modal')"
            >
              Отменить
            </b-button>
          </b-modal>
        </div>
      </b-tab>

      <b-tab :title="`Вопросы от студентов (${questions.length})`">
        <WebinarsQuestions
          :questions="questions"
          class="mt-4"
        />
      </b-tab>
    </b-tabs>
  </div>
  <div
    v-else
    class="loader text-center"
  >
    <b-spinner />
  </div>
</template>

<script>
import WebinarsForm from '@/components/webinars/WebinarsForm.vue';
import WebinarsQuestions from '@/components/webinars/WebinarsQuestions.vue';
import {
  makeToast,
  webinarPostData,
} from '@/custom_functions/custom_functions';
import { validateWebinar } from '@/custom_functions/validation_functions';

export default {
  name: 'WebinarsEdit',

  components: { WebinarsForm, WebinarsQuestions },

  computed: {
    webinar() {
      const webinar = this.$store.getters['webinars/getCurrentWebinar'];
      if (webinar) {
        if (webinar.speakers) {
          webinar.speaker_ids = [];
          webinar.speakers.forEach((speaker) =>
            webinar.speaker_ids.push(speaker.id),
          );
        }
        if (typeof webinar.description !== 'string') webinar.description = '';
        return webinar;
      }
    },

    questions() {
      return this.$store.getters['webinars/getCurrentWebinarQuestions'];
    },
  },

  created() {
    this.$store.dispatch('webinars/fetchCurrentWebinar', this.$route.params.id);
    this.$store.dispatch(
      'webinars/fetchCurrentWebinarQuestions',
      this.$route.params.id,
    );
    this.$store.dispatch('speakers/fetchSpeakers');
  },

  methods: {
    async saveWebinar() {
      if (validateWebinar(this.webinar)) {
        const axiosConfig = {
          headers: { 'Content-Type': 'multipart/form-data' },
        };
        await this.$apiClient.patch(
          `/v2/webinars/${this.$route.params.id}`,
          webinarPostData(this.webinar),
          axiosConfig,
        );
        await this.$router.replace('/webinars/page_1');
        makeToast(
          `Изменения в вебинаре "${this.webinar.name}" сохранены`,
          'primary',
        );
      }
    },

    async deleteWebinar() {
      await this.$apiClient.delete(`v2/webinars/${this.$route.params.id}`);
      await this.$router.replace('/webinars/page_1');
      makeToast(`Вебинар "${this.webinar.name}" удалён`, 'primary');
    },
  },
};
</script>
