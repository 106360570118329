import { makeToast } from '@/custom_functions/custom_functions';

export default function validateStep(step) {
  if (
    (step.type === 'TextTaskStep' && step.data.regexp) ||
    (step.type === 'SortStep' &&
      step.data.variants.every((variant) => variant.text)) ||
    (step.type === 'QuizStep' &&
      step.data.variants.some((variant) => variant.value) &&
      step.data.variants.every((variant) => variant.text)) ||
    (step.type === 'MatchStep' &&
      step.data.variants.questions.every((q) => q.text) &&
      step.data.variants.answers.every((a) => a.text)) ||
    (step.type === 'InteractStep' && step.data.script_check) ||
    step.type === 'TextStep' ||
    step.type === 'VideoStep' ||
    step.type === 'DeferredStep'
  )
    return true;
  if (step.type === 'TextTaskStep' && !step.data.regexp) {
    makeToast('Заполните правило проверки ответа.', 'danger');
  }
  if (
    (step.type === 'QuizStep' || step.type === 'SortStep') &&
    !step.data.variants.every((variant) => variant.text)
  ) {
    makeToast('Заполните все варианты ответа.', 'danger');
  }
  if (
    step.type === 'QuizStep' &&
    !step.data.variants.some((variant) => variant.value)
  ) {
    makeToast('Выберите правильный вариант ответа', 'danger');
  }
  if (
    step.type === 'MatchStep' &&
    !(
      step.data.variants.questions.every((q) => q.text) &&
      step.data.variants.answers.every((a) => a.text)
    )
  ) {
    makeToast('Заполните все варианты вопросов и ответов.', 'danger');
  }
  if (step.type === 'InteractStep' && !step.data.script_check) {
    makeToast('Введите путь к проверяющему скрипту.', 'danger');
  }
  return false;
}
