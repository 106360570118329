<template>
  <form
    class="form-margin"
    @submit.prevent="saveUnit"
  >
    <div class="col-6 mb-3">
      <label
        for="unit-publication-status"
        class="mb-2"
      >
        Статус
      </label>
      <div class="input-group">
        <select
          id="unit-publication-status"
          v-model.lazy="unit.publication_status"
          class="form-control"
        >
          <option value="draft">Черновик</option>
          <option value="published">Опубликован</option>
          <option value="hidden">Приватный</option>
        </select>
      </div>
    </div>

    <div class="col-6 mb-3">
      <label
        for="unit-name"
        class="mb-2"
      >
        Название
      </label>
      <input
        id="unit-name"
        v-model.lazy="unit.name"
        type="text"
        class="form-control"
      />
    </div>

    <template v-if="course.course_type === 'slurm'">
      <div class="mb-3">
        <label>Дата начала</label>
        <div class="row">
          <div class="col-md-6">
            <b-form-datepicker
              v-model="dateFrom"
              locale="ru"
              class="form-control"
            />
          </div>
          <div class="col-md-6">
            <input
              v-model.lazy="timeFrom"
              type="time"
              class="form-control"
            />
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label class="mb-2">Дата конца</label>
        <div class="row">
          <div class="col-md-6">
            <b-form-datepicker
              v-model="dateTo"
              locale="ru"
              class="form-control"
            />
          </div>
          <div class="col-md-6">
            <input
              v-model.lazy="timeTo"
              type="time"
              class="form-control"
            />
          </div>
        </div>
      </div>
    </template>

    <div class="col-6 mb-3">
      <label
        for="unit-description"
        class="mb-2"
      >
        Описание
      </label>
      <textarea
        id="unit-description"
        v-model.lazy="unit.description"
        class="form-control"
        rows="3"
      />
    </div>

    <div class="col-6 mb-5">
      <label
        for="unit-memo"
        class="mb-2"
      >
        Памятка
      </label>
      <Editor
        id="unit-memo"
        v-model:model-value="unit.memo"
        placeholder="Описание памятки"
      />
    </div>

    <UnitDeadline
      v-if="
        ['stepik', 'video'].includes(course.course_type) &&
        course.displayed_type === 'online_course'
      "
      v-model:model-value="unit.deadline"
      :errors="errors"
      class="mb-5"
    />

    <UnitAutoPublication
      v-if="isStream && unit.publication_status === 'draft'"
      v-model:model-value="unit.autoPublication"
      :errors="errors"
      class="mb-5"
    />

    <button
      class="btn btn-outline-primary"
      type="submit"
    >
      Сохранить
    </button>
  </form>
</template>

<script>
import UnitAutoPublication from '@/components/units/UnitAutoPublication.vue';
import UnitDeadline from '@/components/units/UnitDeadline.vue';
import { makeToast } from '@/custom_functions/custom_functions';
import { validateUnit } from '@/custom_functions/validation_functions';
import moment from 'moment';

export default {
  name: 'UnitMainSettings',

  components: {
    UnitDeadline,
    UnitAutoPublication,
  },

  computed: {
    unit() {
      const unit = this.$store.getters['unit/getUnit'];
      if (unit && typeof unit.memo !== 'string') unit.memo = '';

      const deadline = {
        hasSubmissionsDeadline: unit.has_submissions_deadline,
        acceptReworkSubmissionsAfterDeadline:
          unit.accept_rework_submissions_after_deadline,
      };

      if (unit.submissions_start_at && unit.submissions_deadline_at) {
        deadline.dateFrom = moment(unit.submissions_start_at).format(
          'YYYY-MM-DD',
        );
        deadline.dateTo = moment(unit.submissions_deadline_at).format(
          'YYYY-MM-DD',
        );
        deadline.timeFrom = moment(unit.submissions_start_at)
          .utcOffset('GMT+03:00')
          .format('HH:mm:ss');
        deadline.timeTo = moment(unit.submissions_deadline_at)
          .utcOffset('GMT+03:00')
          .format('HH:mm:ss');
      }

      unit.deadline = deadline;

      const autoPublication = {
        hasSubmissionsAutoPublication:
          unit.publication_status === 'draft'
            ? Boolean(unit.available_at)
            : false,
      };

      if (autoPublication.hasSubmissionsAutoPublication) {
        autoPublication.dateFrom = moment(unit.available_at)
          .utcOffset('GMT+03:00')
          .format('YYYY-MM-DD');
        autoPublication.timeFrom = moment(unit.available_at)
          .utcOffset('GMT+03:00')
          .format('HH:mm:ss');
      } else {
        autoPublication.dateFrom = null;
        autoPublication.timeFrom = '08:00';
      }

      unit.autoPublication = autoPublication;

      return unit;
    },

    course() {
      return this.$store.getters['course/getCourse'];
    },

    dateFrom: {
      get() {
        return this.getDate(this.unit.date_from);
      },
      set(value) {
        this.setDate(value, this.timeFrom, 'date_from');
      },
    },

    timeFrom: {
      get() {
        return this.getTime(this.unit.date_from);
      },
      set(value) {
        this.setDate(this.dateFrom, value, 'date_from');
      },
    },

    dateTo: {
      get() {
        return this.getDate(this.unit.date_to);
      },
      set(value) {
        this.setDate(value, this.timeTo, 'date_to');
      },
    },

    timeTo: {
      get() {
        return this.getTime(this.unit.date_to);
      },
      set(value) {
        this.setDate(this.dateTo, value, 'date_to');
      },
    },

    errors() {
      return this.$store.getters['unit/getErrors'];
    },

    isStream() {
      return (
        ['stepik', 'video'].includes(this.course.course_type) &&
        this.course.displayed_type === 'online_course'
      );
    },
  },

  watch: {
    unit: {
      handler(value) {
        if (Object.keys(this.errors).length)
          this.$store.commit('unit/updateErrors', validateUnit(value));
      },
      deep: true,
    },
  },

  methods: {
    getDate(date) {
      return date ? moment(date).format('YYYY-MM-DD') : null;
    },

    getTime(date) {
      return date ? moment(date).format('HH:mm') : null;
    },

    setDate(date, time, key) {
      if (date && time) this.unit[key] = `${date}T${time}:00.000+03:00`;
    },

    async saveUnit() {
      await this.$store.dispatch('unit/saveUnit', { ...this.unit });
      if (!Object.keys(this.errors).length)
        makeToast('Изменения в модуле сохранены', 'success');
    },
  },
};
</script>

<style scoped>
.form-margin {
  margin-bottom: 200px;
}
</style>
