<template>
  <form>
    <div class="row">
      <div class="col-6 mb-3">
        <label
          for="product-name"
          class="form-label"
        >
          Название курса*
        </label>
        <input
          id="product-name"
          v-model.trim="product.name"
          :class="errors.name && 'is-invalid'"
          type="text"
          class="form-control"
        />
        <span
          v-for="(error, index) in errors.name"
          :key="index"
          class="invalid-feedback"
        >
          {{ error }}
        </span>
        <span class="form-text">
          Например: Apache Kafka. Название без потока, тарифа и типа курса.
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-6 mb-3">
        <label
          for="product-description"
          class="form-label"
        >
          Краткое описание для каталога и главной*
        </label>
        <textarea
          id="product-description"
          v-model.trim="product.description"
          :class="errors.description && 'is-invalid'"
          class="form-control"
          maxlength="100"
        />
        <span
          v-for="(error, index) in errors.description"
          :key="index"
          class="invalid-feedback"
        >
          {{ error }}
        </span>
        <span class="form-text">Максимум 100 символов</span>
      </div>
    </div>
    <div class="row">
      <ProductIconInput
        id="background-image-url"
        v-model:model-value="product.background_image_url"
        label="Иллюстрация к курсу"
        imageVariant="rectangle"
        tip="Размер 240х180 px, формат SVG"
        class="col-6 mb-3"
      />
    </div>
    <div class="row">
      <ProductIconInput
        id="product-icon-url"
        v-model:model-value="product.icon_url"
        label="Иконка технологии"
        tip="Размер 40х40 px, формат SVG или PNG"
        class="col-6 mb-3"
      />
    </div>
    <div class="row">
      <CustomSelect
        id="product-experience-level"
        v-model:model-value="product.experience_level"
        :options="experienceLevelOptions"
        :errors="errors.experience_level"
        label="Уровень подготовки"
        class="col-6 mb-3"
      />
    </div>
    <div class="row">
      <CustomSelect
        id="product-direction-ids"
        v-model:model-value="product.direction_ids"
        :options="directionIdOptions"
        :errors="errors.direction_ids"
        label="Направления"
        tip="Можно выбрать несколько направлений.
          Советуем заполнить,
          чтобы студент увидел этот курс,
          когда фильтрует каталог по направлениям."
        class="col-12 mb-3"
        multiple
        required
      />
    </div>
    <div class="row">
      <div class="col-12 mb-3">
        <label
          for="product-external-url"
          class="form-label"
        >
          Ссылка на курс на Тильде{{ requiredIfPublishedLabel }}
        </label>
        <input
          id="product-external-url"
          v-model.trim="product.external_url"
          :class="errors.external_url && 'is-invalid'"
          type="text"
          class="form-control"
        />
        <span
          v-for="(error, index) in errors.external_url"
          :key="index"
          class="invalid-feedback"
        >
          {{ error }}
        </span>
        <span class="form-text">
          На эту страницу будет вести карточка курса. Если у вас закрытый курс,
          и вы не хотите показывать его в каталоге, оставьте поле незаполненным.
        </span>
      </div>
    </div>
  </form>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import { useDirections } from '@/composables/courses/use_directions';

import ProductIconInput from '@/components/courses/products/ProductIconInput';
import CustomSelect from '@/components/CustomSelect';

const store = useStore();

const product = computed(() => store.getters['product/getProduct']);

const errors = computed(() => store.getters['product/getErrors']);

const { directionIdOptions } = useDirections();

const experienceLevelOptions = [
  { value: 1, name: 'Войти в IT' },
  { value: 2, name: 'С опытом' },
];

const requiredIfPublishedLabel = computed(() => {
  return product.value.publication_status === 'published' ? '*' : '';
});
</script>
