import { apiClient } from '@/custom_functions/api_client';
import { fetchApiClient } from '@/custom_functions/fetch_api_client';

export default {
  namespaced: true,

  state: {
    containers: null,
    containersTotalPages: null,
    httpProcess: false,
    hiddenContainerIds: [],
  },

  getters: {
    getContainers: (state) => state.containers,
    getContainersTotalPages: (state) => state.containersTotalPages,
    getHttpProcess: (state) => state.httpProcess,
    getHiddenContainerIds: (state) => state.hiddenContainerIds,
  },

  actions: {
    async fetchContainers({ commit }, params) {
      commit('updateHttpProcess', true);
      commit('updateContainers', null);
      commit('updateContainersTotalPages', null);

      const queryParams = { ...params };
      let searchString = '';

      if (params && params.search) {
        delete queryParams.search;
        searchString = `?filter_query[id_or_name_cont]=${params.search}`;
      }

      const response = await apiClient.get(`/v2/courses${searchString}`, {
        params: { ...queryParams, scope: ['bundle', 'subscription'] },
      });

      commit('updateContainers', response.data.courses);
      commit('updateContainersTotalPages', response.data.meta.total_pages);
      commit('updateHttpProcess', false);
    },

    async deleteContainer({ commit }, id) {
      commit('updateHttpProcess', true);
      await fetchApiClient.delete(`/v2/courses/${id}`, { keepalive: true });
      commit('removeContainer', id);
      commit('updateHttpProcess', false);
    },
  },

  mutations: {
    updateContainers(state, value) {
      state.containers = value;
    },

    updateContainersTotalPages(state, value) {
      state.containersTotalPages = value;
    },

    removeContainer(state, id) {
      state.containers.splice(
        state.containers.findIndex((container) => container.id === id),
        1,
      );
    },

    updateHttpProcess(state, value) {
      state.httpProcess = value;
    },

    addIdToHiddenContainerIds(state, id) {
      state.hiddenContainerIds.push(id);
    },

    removeIdFromHiddenContainerIds(state, id) {
      state.hiddenContainerIds.splice(
        state.hiddenContainerIds.findIndex((containerId) => containerId === id),
        1,
      );
    },
  },
};
