<template>
  <div>
    <div class="mb-3">
      <label
        for="step-text"
        class="mb-2"
      >
        <b>{{ formatStepType(step.type) }}</b>
      </label>
      <input
        v-model="step.name"
        type="text"
        class="form-control mb-3"
        placeholder="Наименование шага (макс. 80 символов)"
        maxlength="80"
      />
      <div>
        <Editor
          id="step-text"
          v-model:model-value="step.text"
          :height="editorHeight"
          placeholder="Новый шаг"
        />
        <div
          v-if="this.step.type === 'VideoStep'"
          class="mt-4"
        >
          <div class="row">
            <FileInput
              id="file-input-for-presentation"
              v-model:model-value="presentationFileUrl"
              label="Презентация к видео"
              tip="Формат PDF"
              class="col-5"
              :validate="hasPdfExtension"
            />
            <FileInput
              id="file-input-for-file-transcript"
              v-model:model-value="textTranscriptFileUrl"
              label="Файл конспекта к видео"
              tip="Формат PDF"
              class="col-5"
              :validate="hasPdfExtension"
            />
          </div>
          <div class="form-check form-switch mt-4 mb-0">
            <input
              id="text-transcript"
              v-model="step.display_text_transcript"
              class="form-check-input"
              type="checkbox"
            />
            <label
              class="form-check-label"
              for="text-transcript"
            >
              Текстовый конспект к видео
            </label>
          </div>
          <div
            v-if="step.display_text_transcript"
            class="mt-3"
          >
            <Editor
              id="step-transcript"
              v-model:model-value="step.text_transcript"
              :height="editorHeight"
              placeholder="Текст конспекта"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <input
        v-model.lazy="step.duration"
        type="number"
        class="form-control w-25"
        placeholder="Длительность в минутах"
      />
    </div>

    <StepAttemptsLimit
      v-if="hasAttemptsLimit"
      v-model:model-value="step.attempts_limit"
      class="mb-3"
    />

    <component
      :is="dataComponent"
      v-if="dataComponent"
      :step="step"
    />
  </div>
</template>

<script>
import InteractStep from '@/components/lessons/InteractStep.vue';
import MatchStep from '@/components/lessons/MatchStep.vue';
import QuizStep from '@/components/lessons/QuizStep.vue';
import SortStep from '@/components/lessons/SortStep.vue';
import StepAttemptsLimit from '@/components/lessons/StepAttemptsLimit.vue';
import TextTaskStep from '@/components/lessons/TextTaskStep.vue';
import { formatStepType } from '@/custom_functions/custom_functions';
import FileInput from '../FileInput.vue';

export default {
  name: 'StepContent',

  components: {
    StepAttemptsLimit,
    TextTaskStep,
    InteractStep,
    QuizStep,
    SortStep,
    MatchStep,
    FileInput,
  },

  data() {
    return {
      presentationFileUrl:
        this.modelValue.presentation_file_url ||
        this.modelValue.presentation_file?.url,
      textTranscriptFileUrl:
        this.modelValue.text_transcript_file_url ||
        this.modelValue.text_transcript_file?.url,
    };
  },

  props: { modelValue: { type: Object, required: true } },

  computed: {
    step: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:model-value', value);
      },
    },

    editorHeight() {
      if (
        this.step.type === 'TextStep' ||
        this.step.type === 'VideoStep' ||
        this.step.type === 'DeferredStep'
      )
        return 500;
      else return 400;
    },

    hasAttemptsLimit() {
      return this.step.type !== 'TextStep' && this.step.type !== 'VideoStep';
    },

    dataComponent() {
      if (
        this.step.type === 'TextTaskStep' ||
        this.step.type === 'InteractStep' ||
        this.step.type === 'QuizStep' ||
        this.step.type === 'SortStep' ||
        this.step.type === 'MatchStep'
      )
        return this.step.type;
      return null;
    },
  },

  methods: {
    formatStepType,

    hasPdfExtension(file) {
      const errors = [];

      if (!'application/pdf'.includes(file.type)) {
        errors.push(
          'Формат картинки не поддерживается. Поддерживаемый формат: PDF',
        );
      }

      return errors;
    },
  },

  watch: {
    presentationFileUrl(value) {
      this.step.presentation_file_url = value;
    },

    textTranscriptFileUrl(value) {
      this.step.text_transcript_file_url = value;
    },
  },
};
</script>

<style>
.translate-hint {
  margin-left: 40px;
}
</style>
