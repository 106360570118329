<template>
  <div v-if="changingStep && step">
    <StepsList
      class="mb-3"
      :step-changed="stepChanged"
    />

    <StepContent
      :key="step.id"
      v-model:model-value="changingStep"
      class="my-3"
    />

    <div class="d-flex no-block align-items-center">
      <button
        class="btn btn-outline-danger me-2"
        @click="$bvModal.show('modal-delete-step')"
      >
        Удалить
      </button>
      <b-modal
        id="modal-delete-step"
        hide-footer
        title="Подтвердите действие"
      >
        <div class="d-flex flex-column text-center">
          <span
            v-if="lesson.steps.length <= 1"
            class="h5"
          >
            При удалении последнего шага, удалится весь урок.
          </span>
          <span class="h4">Удалить шаг?</span>
        </div>
        <div class="mt-3">
          <b-button
            class="me-2"
            block
            variant="outline-danger"
            @click="deleteStep()"
          >
            Удалить
          </b-button>
          <b-button
            block
            variant="outline-secondary"
            @click="$bvModal.hide('modal-delete-step')"
          >
            Отменить
          </b-button>
        </div>
      </b-modal>

      <button
        class="btn btn-outline-primary me-2"
        @click="$bvModal.show('modal-reset')"
      >
        Сбросить
      </button>
      <b-modal
        id="modal-reset"
        hide-footer
        title="Подтвердите действие"
      >
        <div class="d-block text-center">
          <span class="h4">Сбросить изменения в шаге?</span>
        </div>
        <div class="mt-3">
          <b-button
            class="me-2"
            block
            variant="outline-primary"
            @click="resetStep"
          >
            Подтвердить
          </b-button>
          <b-button
            block
            variant="outline-secondary"
            @click="$bvModal.hide('modal-reset')"
          >
            Отменить
          </b-button>
        </div>
      </b-modal>

      <button
        class="btn btn-outline-primary me-2"
        @click="saveStep"
      >
        Сохранить
      </button>
    </div>

    <b-alert
      v-model="stepChanged"
      class="position-fixed fixed-bottom m-0 rounded-0 d-flex justify-content-center align-items-center"
      variant="danger"
      :fade="true"
    >
      <span class="me-4">Пожалуйста, сохраните шаг после изменений.</span>
      <button
        class="btn btn-outline-primary btn-sm mx-2"
        @click="$bvModal.show('modal-reset')"
      >
        Сбросить
      </button>
      <button
        class="btn btn-primary btn-sm mx-2"
        @click="saveStep"
      >
        Сохранить
      </button>
    </b-alert>
    <ConflictModal
      :my-changes="myChanges"
      :their-changes="theirChanges"
      @myChangesSelected="applyMyChanges"
    />
  </div>
</template>

<script>
import ConflictModal from '@/components/conflicts/ConflictModal.vue';
import StepContent from '@/components/lessons/StepContent.vue';
import StepsList from '@/components/lessons/StepsList.vue';
import { makeToast } from '@/custom_functions/custom_functions';
import { validateStep } from '@/custom_functions/validation_functions';
import { nextTick } from 'vue';

export default {
  name: 'LessonSteps',

  components: { ConflictModal, StepsList, StepContent },

  data() {
    return {
      changingStep: null,
      myChanges: [],
      theirChanges: {},
    };
  },

  computed: {
    lesson() {
      return this.$store.getters['lesson/getLesson'];
    },

    step() {
      const step = this.$store.getters['lesson/getStep'](
        this.$route.params.stepId,
      );
      if (step && typeof step.text !== 'string') step.text = '';
      return step;
    },

    stepChanged() {
      return JSON.stringify(this.changingStep) !== JSON.stringify(this.step);
    },
  },

  watch: {
    step() {
      if (this.step) this.changingStep = JSON.parse(JSON.stringify(this.step));
    },
  },

  created() {
    if (this.step) this.changingStep = JSON.parse(JSON.stringify(this.step));
  },

  methods: {
    async deleteStep() {
      this.$bvModal.hide('modal-delete-step');
      await this.$apiClient.delete(`/v2/steps/${this.step.id}`);
      const { course, unit } = this.$route.query;
      if (this.lesson.steps.length <= 1) {
        await this.$apiClient.delete(`/v2/lessons/${this.lesson.id}`);
        this.$router.push(`/courses/${course}/edit?tab=content`);
      } else {
        this.lesson.steps.splice(this.lesson.steps.indexOf(this.step), 1);
        const lastStepIndex = this.lesson.steps.length - 1;
        this.$router.push(
          `/lessons/${this.lesson.id}/step/${this.lesson.steps[lastStepIndex].id}?course=${course}&unit=${unit}`,
        );
      }
    },

    resetStep() {
      this.$bvModal.hide('modal-reset');
      this.changingStep = JSON.parse(JSON.stringify(this.step));
    },

    async saveStep() {
      if (validateStep(this.changingStep)) {
        if (this.step.type === 'SortStep') {
          this.changingStep.data.variants.forEach((variant, index) => {
            variant.value = index + 1;
          });
        }
        const requestData = { step: { ...this.step, ...this.changingStep } };
        try {
          const response = await this.$apiClient.patch(
            `/v2/steps/${this.step.id}`,
            requestData,
            { skipErrorHandling: true },
          );
          const newLockVersion = response.data.step.lock_version;
          const changingStep = {
            ...this.changingStep,
            lock_version: newLockVersion,
          };
          this.$store.commit('lesson/updateStep', changingStep);
          makeToast(
            'Изменения успешно сохранены, вы можете перейти к следующему шагу.',
            'success',
          );
        } catch (e) {
          const { status } = e.response;
          if (status === 409) {
            this.myChanges = e.response.data.my_changes;
            this.theirChanges = e.response.data.their_changes;
            nextTick(() => {
              this.$bvModal.show('conflict-modal');
            });
          } else makeToast('Не удалось сохранить изменения.', 'danger');
        }
      }
    },

    applyMyChanges() {
      delete this.step.lock_version;
      this.saveStep();
    },
  },
};
</script>
