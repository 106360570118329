<template>
  <form
    class="input-group"
    @submit.prevent="search"
  >
    <input
      v-model="query"
      type="search"
      placeholder="Курсы и вебинары"
      class="form-control search-input"
    />
    <button
      class="btn search-btn"
      type="submit"
    >
      <font-awesome-icon icon="magnifying-glass" />
    </button>
  </form>
</template>

<script>
export default {
  data() {
    return {
      query: '',
    };
  },

  name: 'SearchInput',

  methods: {
    search() {
      const path = `/search?query=${this.query.trim()}`;
      if (decodeURI(this.$route.fullPath) !== path) this.$router.push(path);
      this.query = '';
    },
  },
};
</script>

<style scoped>
.search-input,
.search-input:focus,
.search-btn {
  background-color: #1f2225;
  border-color: #353a3f;
  color: white;
}

.search-btn {
  color: #3d85f7;
}

.search-btn:focus,
.search-btn:hover {
  background-color: #3d85f7;
  color: white;
}
</style>
