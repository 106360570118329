export default {
  namespaced: true,

  state: { toastText: null, toastVariant: null, revertToasts: [] },

  getters: {
    getToastText: (state) => state.toastText,
    getToastOptions: (state) => ({
      title: 'Уведомление от учебного центра «Слёрм»',
      variant: state.toastVariant,
      noAutoHide: state.toastVariant === 'danger',
      autoHideDelay: 9000,
      append: true,
    }),
    getRevertToasts: (state) => state.revertToasts,
  },

  mutations: {
    changeToast(state, { text, variant }) {
      state.toastText = text;
      state.toastVariant = variant;
    },

    addRevertToast(
      state,
      {
        comment = 'Вы можете отменить последнее действие',
        buttonText = 'Отменить',
        time = 10,
        action = () => null,
        revert = () => null,
      },
    ) {
      state.revertToasts.push({
        comment,
        buttonText,
        time,
        action,
        revert,
        id: Number(new Date()),
      });
    },

    deleteRevertToast(state, id) {
      state.revertToasts.splice(
        state.revertToasts.findIndex((toast) => toast.id === id),
        1,
      );
    },
  },
};
