<template>
  <tr
    v-show="!isContainerHidden"
    class="table-row"
  >
    <th class="ps-3 align-middle">{{ container.id }}</th>
    <td class="text-primary align-middle position-relative">
      <router-link
        class="stretched-link text-decoration-none"
        :to="`/courses/containers/${container.id}/edit`"
      >
        {{ container.name }}
      </router-link>
    </td>
    <td
      :class="currentPublicationStatusClass"
      class="align-middle"
    >
      {{ publicationStatusString }}
    </td>
    <td class="align-middle">{{ productOwnerName }}</td>
    <td class="align-middle pe-3">
      <b-dropdown
        variant="link"
        toggle-class="text-decoration-none text-body"
        no-caret
        right
      >
        <template #button-content>
          <font-awesome-icon icon="ellipsis" />
        </template>
        <b-dropdown-item :to="`/courses/containers/${container.id}/edit`">
          <font-awesome-icon
            icon="pen-to-square"
            class="me-2"
          />
          Изменить
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item-button
          :disabled="deleteButtonDisabled"
          @click="removeContainer(container)"
        >
          <font-awesome-icon
            icon="trash"
            class="me-2"
          />
          Удалить
        </b-dropdown-item-button>
      </b-dropdown>
    </td>
  </tr>
</template>

<script setup>
import { usePublicationStatusFilter } from '@/composables/courses/use_publication_status_filter';
import { useProductOwners } from '@/composables/courses/use_product_owners';

import { useStore } from 'vuex';

import { computed } from 'vue';

const props = defineProps({ container: { type: Object, required: true } });

const { formatPublicationStatus, publicationStatusClass } =
  usePublicationStatusFilter();

const publicationStatusString = computed(() => {
  return formatPublicationStatus(props.container.publication_status);
});

const currentPublicationStatusClass = computed(() => {
  return publicationStatusClass(props.container.publication_status);
});

const { getProductOwnerNameById } = useProductOwners();

const productOwnerName = computed(() =>
  getProductOwnerNameById(props.container.po_user_id),
);

const store = useStore();

const httpProcess = computed(() => store.getters['containers/getHttpProcess']);

const isContainerHidden = computed(() => {
  return store.getters['containers/getHiddenContainerIds'].includes(
    props.container.id,
  );
});

async function removeContainer() {
  store.commit('containers/addIdToHiddenContainerIds', props.container.id);

  store.commit('toast/addRevertToast', {
    comment:
      props.container.course_type === 'subscription'
        ? `Подписка «${props.container.name}» удалена`
        : `Комплект «${props.container.name}» удален`,
    buttonText:
      props.container.course_type === 'subscription'
        ? 'Восстановить подписку'
        : 'Восстановить комплект',
    action: () =>
      store.dispatch('containers/deleteContainer', props.container.id),
    revert: () =>
      store.commit(
        'containers/removeIdFromHiddenContainerIds',
        props.container.id,
      ),
  });
}

const deleteButtonDisabled = computed(() => {
  return props.container.participations_count || httpProcess.value;
});
</script>

<style scoped>
.table-row {
  height: 62px;
}
</style>
