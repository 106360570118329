export default function validateZoomUrl(value) {
  const errors = [];
  if (
    value &&
    !/^https:\/\/[A-Za-z0-9\-]*\.?zoom.us(\/meeting)?\/(register\/)?[A-Za-z0-9]+(([\/=\-?_])?[A-Za-z0-9]+)*$/.test(
      value,
    )
  ) {
    // eslint-disable-line no-useless-escape
    errors.push('Не является ссылкой на Zoom');
  }
  return errors;
}
