<template>
  <div
    v-if="webinars"
    class="container"
  >
    <div class="mb-4">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <div class="d-flex align-items-center">
          <h2 class="mb-0 me-5">Вебинары</h2>
          <form
            class="input-group campaign-search"
            @submit.prevent="search"
          >
            <input
              v-model="query"
              placeholder="Поиск по названию"
              type="search"
              class="form-control"
            />
            <button
              type="submit"
              class="btn btn-primary"
            >
              Найти
            </button>
          </form>
        </div>
        <router-link
          to="/webinars/new"
          class="btn btn-primary"
        >
          Добавить вебинар
        </router-link>
      </div>
      <div class="table-responsive">
        <table class="table table-hover table-responsive-lg">
          <thead>
            <tr>
              <th>Название</th>
              <th class="text-center">Дата и время проведения</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="webinar in webinars"
              :key="webinar.id"
            >
              <td>
                <router-link :to="`/webinars/${webinar.id}/edit`">
                  {{ webinar.name }}
                </router-link>
              </td>
              <td class="text-center">{{ formatDate(webinar.start_time) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <b-pagination
      v-if="totalPages > 1"
      v-model="$route.params.page"
      :total-rows="webinars.length * totalPages"
      :per-page="webinars.length"
      class="position-sticky"
      style="bottom: 10px"
      @change="goToPage($event)"
    />
  </div>

  <div
    v-else
    class="loader text-center"
  >
    <b-spinner />
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'WebinarsIndex',

  data() {
    return { query: '' };
  },

  computed: {
    webinars() {
      return this.$store.getters['webinars/getWebinars'];
    },

    totalPages() {
      return this.$store.getters['webinars/getTotalPages'];
    },
  },

  created() {
    this.$store.dispatch('webinars/fetchWebinars', {
      page: this.$route.params.page,
      search: this.query,
    });
  },

  methods: {
    async goToPage(page) {
      if (this.$route.params.page !== page) {
        await this.$router.replace({ params: { page: page } });
        this.$store.dispatch('webinars/fetchWebinars', {
          page: this.$route.params.page,
          search: this.query,
        });
      }
    },

    async search() {
      if (this.$route.params.page !== 1)
        await this.$router.replace({ params: { page: 1 } });
      this.$store.dispatch('webinars/fetchWebinars', {
        page: 1,
        search: this.query,
      });
    },

    formatDate(value) {
      return moment(value)
        .locale('ru')
        .utcOffset('GMT+03:00')
        .format('D MMMM YYYY в HH:mm');
    },
  },
};
</script>
