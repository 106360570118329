<template>
  <div
    v-if="
      container &&
      courses &&
      directions &&
      productOwners &&
      tags &&
      participations &&
      containers
    "
    class="container"
  >
    <CoursesItemHeader
      class="mb-4"
      courses-item-type="container"
      @save="editContainer"
    />
    <ContainerTabs />
    <ConflictModal
      v-if="conflict"
      v-model:model-value="shownConflictModal"
      :my-changes="conflict.my_changes"
      :their-changes="conflict.their_changes"
    />
  </div>
  <div
    v-else
    class="loader text-center"
  >
    <b-spinner />
  </div>
</template>

<script setup>
import CoursesItemHeader from '@/components/courses/CoursesItemHeader';
import ContainerTabs from '@/components/courses/ContainerTabs';
import ConflictModal from '@/components/conflicts/ConflictModal';

import { useStore } from 'vuex';
import { computed, watch, ref } from 'vue';
import { useRoute } from 'vue-router';

import { makeToast } from '@/custom_functions/custom_functions';

const store = useStore();
const route = useRoute();

store.dispatch('container/fetchContainer', route.params.id);
const container = computed(() => store.getters['container/getContainer']);

store.dispatch('courses/fetchCoursesOmitPagination');
const courses = computed(
  () => store.getters['courses/getCoursesOmitPagination'],
);

const hasErrors = computed(() => store.getters['container/getHasErrors']);

async function editContainer() {
  await store.dispatch('container/editContainer');
  if (!hasErrors.value && !conflict.value) {
    makeToast(
      container.value.course_type === 'subscription'
        ? 'Подписка сохранена'
        : 'Комплект сохранен',
      'success',
    );
  }
}

watch(
  container,
  () => {
    if (hasErrors.value) store.dispatch('container/validateContainer');
  },
  { deep: true },
);

store.dispatch('directions/fetchDirections');
const directions = computed(() => store.getters['directions/getDirections']);

store.dispatch('course/fetchProductOwners');
const productOwners = computed(() => store.getters['course/getProductOwners']);

store.dispatch('tags/fetchTagsSettings');
const tags = computed(() => store.getters['tags/getTags']);

store.dispatch('course/fetchParticipations', { id: route.params.id });
const participations = computed(
  () => store.getters['course/getParticipations'],
);

const conflict = computed(() => store.getters['container/getConflict']);

const shownConflictModal = ref(false);

watch(conflict, (value) => {
  if (value) shownConflictModal.value = true;
});

store.dispatch('containers/fetchContainers');
const containers = computed(() => store.getters['containers/getContainers']);
</script>
