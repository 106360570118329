<template>
  <div>
    <b-button
      variant="outline-primary"
      class="w-100"
      @click="showModal = true"
    >
      Изменить урок
    </b-button>

    <b-modal
      v-model="showModal"
      hide-footer
      title="Редактирование урока"
    >
      <div class="mb-3">
        <label
          for="lesson-title"
          class="mb-2"
        >
          Название урока:
        </label>
        <input
          id="lesson-title"
          v-model.lazy="lesson.title"
          type="text"
          class="form-control"
        />
      </div>
      <div class="mb-3">
        <label
          for="lesson-description"
          class="mb-2"
        >
          Описание урока:
        </label>
        <input
          id="lesson-description"
          v-model.lazy="lesson.description"
          type="text"
          class="form-control"
        />
      </div>
      <div class="mb-3 mb-4">
        <label
          for="lesson-publication-status"
          class="mb-2"
        >
          Статус:
        </label>
        <CustomSelect
          id="lesson-publication-status"
          v-model:model-value.lazy="lesson.publication_status"
          :options="statuses"
          class="mb-3"
        />
      </div>

      <b-button
        variant="outline-primary"
        block
        class="me-2"
        @click="saveLesson"
      >
        Сохранить
      </b-button>
      <b-button
        block
        variant="outline-secondary"
        @click="showModal = false"
      >
        Отменить
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { makeToast } from '../../custom_functions/custom_functions';
import CustomSelect from '@/components/CustomSelect.vue';

export default {
  name: 'LessonSettings',

  components: {
    CustomSelect,
  },

  data() {
    return {
      showModal: false,
      statuses: [
        { value: 'draft', name: 'Черновик' },
        { value: 'published', name: 'Опубликован' },
        { value: 'hidden', name: 'Приватный' },
      ],
    };
  },

  computed: {
    lesson() {
      return this.$store.getters['lesson/getLesson'];
    },
  },

  methods: {
    async saveLesson() {
      await this.$apiClient.patch(`/v2/lessons/${this.lesson.id}`, {
        lesson: this.lesson,
      });
      this.showModal = false;
      makeToast('Изменения в уроке сохранены.', 'success');
    },
  },
};
</script>
