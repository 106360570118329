import { apiClient } from '@/custom_functions/api_client';
import Consumer from '../../custom_functions/action_cable';

export default {
  namespaced: true,

  state: {
    currentSession: null,
    sessions: null,
    sessionsTotalPages: null,
    sessionsConsumer: null,
    sessionsParams: {},
  },

  getters: {
    getCurrentSession(state) {
      return state.currentSession;
    },

    getSessions(state) {
      return state.sessions;
    },

    getSessionsTotalPages(state) {
      return state.sessionsTotalPages;
    },
  },

  actions: {
    async fetchCurrentSession({ commit }, id) {
      const response = await apiClient.get(`/v2/stand_sessions/${id}`);
      commit('updateCurrentSession', response.data.stand_session);
    },

    async fetchSessions({ commit }, payload) {
      commit('updateSessionsParams', payload);
      let params = {};

      if (payload.filter) params['scope'] = payload.filter;

      if (payload.sort) {
        params['sorting_keys'] = [];
        if (payload.sort.sortBy)
          params['sorting_keys'].push(payload.sort.sortBy);
        if (payload.sort.order) params['sorting_keys'].push(payload.sort.order);
      }

      if (payload.query) params['email_or_login'] = payload.query;

      if (payload.page > 1) params['page'] = payload.page;

      const response = await apiClient.get('/v2/stand_sessions', { params });

      commit('updateSessions', response.data.stand_sessions);
      commit('updateSessionsTotalPages', response.data.meta.total_pages);
    },

    connectSessionsConsumer({ commit, state, dispatch }) {
      commit(
        'updateSessionsConsumer',
        new Consumer(
          () => {
            dispatch('fetchSessions', state.sessionsParams);
          },
          { channel: 'StandsSessionsChannel' },
        ),
      );
      state.sessionsConsumer.connect();
    },

    disconnectSessionsConsumer({ commit, state }) {
      state.sessionsConsumer.disconnect();
      commit('updateSessionsConsumer', null);
    },
  },

  mutations: {
    updateCurrentSession(state, session) {
      state.currentSession = session;
    },

    updateSessions(state, sessions) {
      state.sessions = sessions;
    },

    updateSessionsTotalPages(state, totalPages) {
      state.sessionsTotalPages = totalPages;
    },

    updateSessionsConsumer(state, consumer) {
      state.sessionsConsumer = consumer;
    },

    updateSessionsParams(state, params) {
      state.sessionsParams = params;
    },
  },
};
