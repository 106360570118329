import { apiClient } from '@/custom_functions/api_client';
import { validateProduct } from '@/custom_functions/validation_functions';

export default {
  namespaced: true,

  state: { product: null, errors: {}, conflict: null, httpProcess: false },

  getters: {
    getProduct: (state) => state.product,
    getErrors: (state) => state.errors,
    getHasErrors: (state) => !!Object.keys(state.errors).length,
    getConflict: (state) => state.conflict,
    getHttpProcess: (state) => state.httpProcess,
  },

  actions: {
    async fetchProduct({ commit }, id) {
      commit('updateProduct', null);
      const response = await apiClient.get(`/v2/products/${id}`);
      commit('updateProduct', {
        ...response.data.product,
        course_ids: response.data.product.courses.map((course) => course.id),
      });
    },

    createProduct({ commit }) {
      const product = { publication_status: 'draft' };
      commit('updateProduct', product);
    },

    async saveNewProduct({ commit, dispatch, state }) {
      dispatch('validateProduct');
      if (!Object.keys(state.errors).length) {
        commit('updateHttpProcess', true);
        const response = await apiClient.post('/v2/products', {
          product: state.product,
        });
        commit('updateProduct', {
          ...response.data.product,
          course_ids: response.data.product.courses.map((course) => course.id),
        });
        commit('updateHttpProcess', false);
      }
    },

    async saveProduct({ commit, state, dispatch }) {
      dispatch('validateProduct');
      if (!Object.keys(state.errors).length) {
        try {
          commit('updateHttpProcess', true);
          const response = await apiClient.patch(
            `/v2/products/${state.product.id}`,
            { product: state.product },
          );
          commit('updateProduct', {
            ...response.data.product,
            course_ids: response.data.product.courses.map(
              (course) => course.id,
            ),
          });
        } catch (e) {
          if (e.response.status === 409)
            commit('updateConflict', e.response.data);
          throw e;
        } finally {
          commit('updateHttpProcess', false);
        }
      }
    },

    validateProduct({ commit, state, rootState }) {
      commit(
        'updateErrors',
        validateProduct(state.product, {
          otherProducts: rootState.products.products,
        }),
      );
    },
  },

  mutations: {
    updateProduct(state, value) {
      state.product = value;
    },

    updateErrors(state, value) {
      state.errors = value;
    },

    updateConflict(state, value) {
      state.conflict = value;
    },

    updateHttpProcess(state, value) {
      state.httpProcess = value;
    },
  },
};
