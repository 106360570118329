<template>
  <div v-if="lessons && lessons.length">
    <table class="table table-hover table-responsive-lg border-bottom">
      <thead>
        <tr>
          <th scope="col">Название</th>
          <th scope="col">ID</th>
          <th scope="col">Статус</th>
          <th scope="col">Описание</th>
          <th scope="col">
            <input
              v-model="checkedAll"
              type="checkbox"
            />
          </th>
        </tr>
      </thead>
      <draggable
        v-model:model-value="lessons"
        tag="tbody"
      >
        <template #item="{ element }">
          <tr
            :class="lessonBorderClass(element.id)"
            class="base-row"
          >
            <td>
              <font-awesome-icon
                icon="grip-vertical"
                class="drag-icon me-2 text-muted"
              />
              <router-link
                :to="`/lessons/${element.id}/step/${element.first_step_id}?course=${courseId}&unit=${unitId}`"
              >
                {{ element.title }}
              </router-link>
            </td>
            <td>{{ element.id }}</td>
            <td>{{ formatLessonStatus(element.publication_status) }}</td>
            <td>{{ element.description }}</td>
            <td>
              <input
                v-model="checkedIds"
                :value="element.id"
                type="checkbox"
              />
            </td>
          </tr>
        </template>
      </draggable>
    </table>

    <div class="d-flex justify-content-between align-items-baseline">
      <button
        class="btn btn-outline-primary"
        @click="saveLessonsOrder"
      >
        Сохранить порядок
      </button>
      <transition name="fade">
        <div
          v-if="checkedIds.length"
          class="d-flex align-items-baseline"
        >
          <span class="px-3 btn-sm p-0">
            {{ formatElementsCount(checkedIds.length) }}
          </span>
          <b-dropdown
            toggle-class="p-0 bg-transparent border-0 text-primary"
            class="px-3"
            toggle-tag="button"
            no-caret
          >
            <template v-slot:button-content>
              <u class="btn-sm p-0">
                {{ formatLessonStatus(currentStatus) || 'Выбрать статус' }}
                <svg
                  width="9"
                  height="6"
                  viewBox="0 0 9 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L4.5 4.5L8 1"
                    stroke="#307DF6"
                  />
                </svg>
              </u>
            </template>
            <b-dropdown-item @click="changeLessonsStatus('published')">
              Опубликован
            </b-dropdown-item>
            <b-dropdown-item @click="changeLessonsStatus('draft')">
              Черновик
            </b-dropdown-item>
            <b-dropdown-item @click="changeLessonsStatus('hidden')">
              Приватный
            </b-dropdown-item>
          </b-dropdown>
          <button
            class="btn btn-link btn-sm p-0 px-3"
            @click.prevent="shownModal = true"
          >
            Удалить
          </button>
          <b-modal
            v-model="shownModal"
            hide-footer
            title="Подтвердите действие"
          >
            <div class="d-block text-center">
              <h4>Следующие уроки будут удалены:</h4>
              <span
                v-for="id in checkedIds"
                :key="id"
              >
                {{ lessons.find((lesson) => lesson.id === id).title }}
                <br />
              </span>
            </div>
            <div class="d-flex flex-row">
              <button
                class="d-flex mt-3 me-2 btn btn-danger btn-block"
                @click="deleteLessons"
              >
                Удалить
              </button>
              <button
                class="d-flex mt-3 btn btn-secondary btn-block"
                @click="shownModal = false"
              >
                Отменить
              </button>
            </div>
          </b-modal>
        </div>
      </transition>
    </div>
  </div>
  <div
    v-else
    class="d-flex flex-column align-items-start"
  >
    <span class="text-muted mb-3">
      Уроки еще не созданы. Перейдите в содержание курса, чтобы добавить урок.
    </span>
    <router-link
      :to="`/courses/${courseId}/edit?tab=content`"
      class="btn btn-sm btn-primary"
    >
      Перейти в содержание
    </router-link>
  </div>
</template>

<script>
import {
  formatLessonStatus,
  formatElementsCount,
  makeToast,
} from '@/custom_functions/custom_functions';

export default {
  name: 'UnitLessonsEdit',

  data() {
    return {
      checkedAll: false,
      checkedIds: [],
      lessons: null,
      shownModal: false,
    };
  },

  computed: {
    currentStatus() {
      if (this.checkedIds.length) {
        const status = this.lessons.find(
          (lesson) => lesson.id === this.checkedIds[0],
        ).publication_status;
        if (
          this.checkedIds.every(
            (id) =>
              this.lessons.find((lesson) => lesson.id === id)
                .publication_status === status,
          )
        )
          return status;
      }
      return '';
    },

    unitLessons() {
      return this.$store.getters['unit/getUnitLessons'];
    },

    unitId() {
      return this.$store.getters['unit/getUnitId'];
    },

    courseId() {
      return this.$store.getters['course/getCourse'].id;
    },
  },

  watch: {
    checkedAll: {
      handler(val) {
        if (val) this.checkedIds = this.lessons.map((lesson) => lesson.id);
        else if (this.checkedIds.length === this.lessons.length)
          this.checkedIds = [];
      },
      deep: true,
    },

    checkedIds() {
      if (this.checkedIds.length === this.lessons.length && !this.checkedAll)
        this.checkedAll = true;
      else if (this.checkedIds.length < this.lessons.length && this.checkedAll)
        this.checkedAll = false;
    },
    unitLessons(lessons) {
      this.lessons = lessons;
    },
  },

  created() {
    this.lessons = this.unitLessons;
  },

  methods: {
    formatLessonStatus,
    formatElementsCount,

    async saveLessonsOrder() {
      await this.$store.dispatch('unit/saveLessonsOrder', this.lessons);
      makeToast('Порядок уроков сохранён', 'success');
    },

    async changeLessonsStatus(status) {
      await this.$store.dispatch('unit/saveLessonsStatus', {
        ids: this.checkedIds,
        status,
      });
      makeToast('Статус уроков сохранён', 'success');
    },

    async deleteLessons() {
      await this.$store.dispatch('unit/deleteLessons', this.checkedIds);
      makeToast('Уроки удалены', 'success');
      this.checkedIds = [];
    },

    lessonBorderClass(lessonId) {
      return this.checkedIds.includes(lessonId) ? 'checked-row-primary' : '';
    },
  },
};
</script>

<style scoped>
.drag-icon {
  cursor: grab;
}

.base-row {
  border-width: 0px 0px 0px 3px;
  border-color: var(--bs-white);
}
.checked-row-primary {
  border-width: 0px 0px 0px 3px;
  border-color: var(--bs-primary);
}

td {
  border-bottom: 1px solid var(--bs-border-color);
}
</style>
