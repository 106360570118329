<template>
  <div>
    <input
      :id="id"
      :accept="formatsString"
      class="d-none"
      type="file"
      @change="setIcon"
    />

    <div class="d-flex align-items-center">
      <b-modal
        v-model="shownIconModal"
        hide-footer
        :title="label"
      >
        <div class="d-flex justify-content-center">
          <img
            :src="iconUrl"
            class="icon-modal"
            :alt="label"
          />
        </div>
      </b-modal>

      <div class="w-100">
        <label
          :for="id"
          class="form-label icon-label"
        >
          {{ label }}
        </label>

        <div class="d-flex align-items-center">
          <button
            class="btn btn-link me-3 icon-button align-items-center"
            type="button"
            :disabled="!modelValue"
            @click="showIcon"
          >
            <img
              :src="iconUrl"
              :alt="label"
              class="icon-img"
              :class="{ 'icon-img-rectangle': isImageVariantRectangle }"
            />
          </button>

          <label
            class="input-group"
            :for="id"
          >
            <span class="form-control d-flex flex-row justify-content-between">
              {{ inputText }}
              <div v-if="modelValue">
                <button
                  class="btn btn-sm btn-danger"
                  @click="resetIcon"
                >
                  <font-awesome-icon icon="xmark" />
                </button>
              </div>
            </span>
            <span class="btn btn-outline-secondary">{{ buttonText }}</span>
          </label>
        </div>
        <span class="form-text icon-tip">{{ tip }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { makeToast, readFile } from '@/custom_functions/custom_functions';

import { computed, ref, toRefs } from 'vue';

const props = defineProps({
  modelValue: {
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  tip: {
    type: String,
    default: '',
  },
  imageVariant: {
    type: String,
    default: 'square',
    validator: (value) => ['square', 'rectangle'].includes(value),
  },
});
const { modelValue } = toRefs(props);

const emits = defineEmits(['update:modelValue']);

const shownIconModal = ref(false);

const formats = ['image/jpeg', 'image/png', 'image/svg+xml'];

const formatsString = formats.join(', ');

const isImageVariantRectangle = computed(
  () => props.imageVariant === 'rectangle',
);

const imageStub = computed(() =>
  props.imageVariant === 'rectangle'
    ? require('@/assets/image-stub-rectangle.jpg')
    : require('@/assets/image-stub.jpg'),
);

const iconUrl = computed(() => {
  if (modelValue.value) {
    if (modelValue.value.includes('data:image')) return modelValue.value || '';
    return `${process.env.VUE_APP_API_URL}/${modelValue.value}`;
  }
  return imageStub.value;
});

const showIcon = () => {
  shownIconModal.value = true;
};

function validateFile(file) {
  const errors = [];
  if (!formats.includes(file.type)) {
    errors.push(
      `Формат картинки не поддерживается. Поддерживаемые форматы ${formatsString}`,
    );
  } else if (file.size > 102400) {
    errors.push('Размер файла не должен превышать 100кб');
  }

  return errors;
}

function setIcon(event) {
  if (event.target.files.length === 0) return;

  const file = event.target.files[0];
  const errors = validateFile(file);
  if (errors.length > 0) return makeToast(errors.join(','), 'danger');

  readFile(file, (result) => emits('update:modelValue', result));
}

function resetIcon(event) {
  event.preventDefault();

  emits('update:modelValue', null);
}

const inputText = computed(() =>
  modelValue.value ? 'Файл выбран' : 'Файл не выбран',
);

const buttonText = computed(() =>
  modelValue.value ? 'Изменить файл' : 'Выбрать файл',
);
</script>

<style lang="scss" scoped>
$icon-max-width: 54px;

.icon-modal {
  max-width: 100%;
}
.icon-label,
.icon-tip {
  margin-left: calc($icon-max-width + 1rem);
}
.icon-button {
  flex: 0 0 $icon-max-width;
  border: 0;
  padding: 0;
  --bs-btn-disabled-opacity: 1;
}
.icon-img {
  height: 40px;
  width: 40px;
}
.icon-img-rectangle {
  width: $icon-max-width;
}
</style>
