<template>
  <div>
    <CustomSelect
      id="product-id"
      v-model:model-value="params.product_id"
      :options="productsOptions"
      label="Курс"
      placeholder="Можно найти по названию"
      :search="
        (query) => store.dispatch('products/fetchProductsForSelect', query)
      "
      required
      class="mb-3"
    />
  </div>
</template>

<script setup>
import { computed, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { useCustomSelectOptions } from '@/composables/use_custom_select_options';
import CustomSelect from '@/components/CustomSelect.vue';

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({}),
  },
  report: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const store = useStore();
store.dispatch('products/fetchProducts');

const params = ref({ ...props.modelValue });

watch(
  params.value,
  (newValue) => {
    emit('update:modelValue', newValue);
  },
  { deep: true },
);

const productsOptions = computed(() => {
  const products = store.getters['products/getProducts'];
  return useCustomSelectOptions(products || [], 'id', 'name');
});
</script>
