<template>
  <div>
    <button
      class="btn btn-outline-primary mb-3"
      @click="$bvModal.show('export-course-units-modal')"
    >
      Экспортировать юниты
    </button>
    <b-modal
      id="export-course-units-modal"
      title="Экспортировать в курс"
      size="md"
      hide-footer
      no-close-on-backdrop
    >
      <div class="my-4">
        <form>
          <div class="mb-3">
            <v-select
              v-model:model-value="course"
              :options="courses"
              label="name"
              class="v-select-style"
              placeholder="Название курса"
              @input="courseSelected"
            >
              <template #search="{ attributes, events }">
                <input
                  class="vs__search"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </div>

          <div class="mb-3">
            <label>Выберите юниты для экспорта</label>

            <v-select
              v-model:model-value="units_ids"
              :options="course_units"
              :reduce="(unit) => unit.id"
              label="name"
              class="v-select-style"
              multiple
              taggable
            >
              <template #search="{ attributes, events }">
                <input
                  class="vs__search"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </div>
        </form>

        <b-button
          v-if="course"
          class="mt-3 me-2"
          block
          variant="outline-secondary"
          @click="selectAllUnits"
        >
          Выбрать все
        </b-button>

        <b-button
          class="mt-3"
          block
          variant="outline-primary"
          @click="exportUnitsToCourse"
        >
          Экспортировать юниты
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { makeToast } from '../../custom_functions/custom_functions';
export default {
  name: 'ExportCourseUnitsButton',
  components: {},
  props: {
    src_course: Object,
    courses: Array,
    course_units: Array,
  },
  data() {
    return {
      units_ids: [],
      course: null,
    };
  },
  methods: {
    async exportUnitsToCourse() {
      if (this.course == null) {
        return makeToast('Необходимо выбрать курс', 'danger');
      }

      if (this.units_ids.length <= 0) {
        return makeToast('Необходимо выбрать юниты для экспорта!', 'danger');
      }

      await this.$apiClient
        .post('/v2/courses/' + this.course.id + '/copy_units', {
          src_course_id: this.src_course.id,
          dst_course_id: this.course.id,
          units_ids: this.units_ids,
        })
        .then((result) => {
          this.$bvModal.hide('export-course-units-modal');
          this.$router.push(
            `/courses/${result.data.course.url}/edit?tab=content`,
          );
          this.$router.go();
        });
    },
    courseSelected(value) {
      this.course = value;
      this.units_ids = [];
    },
    selectAllUnits() {
      this.units_ids = this.course_units.map((unit) => unit.id);
    },
  },
};
</script>
