import { apiClient } from '@/custom_functions/api_client';

const BASE_USERS_URL = '/v2/users';

export default {
  namespaced: true,

  state: {
    user: null,
    users: null,
  },

  getters: {
    getUser(state) {
      return state.user;
    },
    getUsers(state) {
      return state.users;
    },
  },

  actions: {
    async fetchUsers({ commit }) {
      const response = await apiClient.get(BASE_USERS_URL);

      commit('updateUsers', response.data.users);
    },

    async fetchUsersByEmail({ commit }, email) {
      const url = BASE_USERS_URL;
      const payload = {
        params: {
          'q[email_cont]': email,
        },
      };

      const response = await apiClient.get(url, payload);

      commit('updateUsers', response.data.users);
    },

    async fetchUsersByQuery({ commit }, query) {
      const url = BASE_USERS_URL;
      const payload = {
        params: {
          'q[email_or_first_name_or_last_name_or_login_cont]': query,
        },
      };
      const response = await apiClient.get(url, payload);

      commit('updateUsers', response.data.users);
    },

    async fetchUserById({ commit }, id) {
      commit('updateUser', null);

      const url = `${BASE_USERS_URL}/${id}`;

      const response = await apiClient.get(url);
      commit('updateUser', response.data.user);
    },
  },

  mutations: {
    updateUsers(state, users) {
      state.users = users;
    },

    updateUser(state, user) {
      state.user = user;
    },
  },
};
