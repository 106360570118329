import DirectionsIndex from '@/views/directions/Index';

export default [
  {
    path: '/directions',
    name: 'directions_index',
    component: DirectionsIndex,
    meta: {
      requiresAuth: true,
    },
  },
];
