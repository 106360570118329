import { apiClient } from '@/custom_functions/api_client';

export default {
  namespaced: true,

  state: { currentUser: null },

  getters: {
    getCurrentUser(state) {
      return state.currentUser;
    },

    isAdmin(state) {
      return state.currentUser?.role === 'admin';
    },
  },

  actions: {
    async fetchCurrentUser({ commit }) {
      const response = await apiClient.get('/v2/profile');
      commit('updateCurrentUser', response.data.user);
    },
  },

  mutations: {
    updateCurrentUser(state, user) {
      state.currentUser = user;
    },
  },
};
