<template>
  <div class="container">
    <div v-if="step">
      <Breadcrumbs
        v-if="breadcrumbsItems"
        :items="breadcrumbsItems"
        class="mb-2"
      />
      <h1 class="h2 fw-bold mb-3">
        {{ formatAnswersCount(step.attempts.length) }} к уроку "{{
          step.lesson.title
        }}" шагу {{ step.index }}
      </h1>
      <div class="row">
        <div class="col-6">
          <ReviewerAnswersPagination />
        </div>
      </div>
      <hr />
      <ReviewerAnswersSetting
        :settings="settings"
        class="mb-3"
        :no-attempts="!attempts.length"
      />
      <table
        v-if="attempts.length"
        class="table table-striped"
      >
        <thead>
          <tr>
            <th>Логин студента</th>
            <th>Имя студента</th>
            <th>ID решения</th>
            <th>Дата решения</th>
            <th>Статус решения</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="attempt in attempts"
            :key="attempt.id"
          >
            <td>{{ attempt.student_login }}</td>
            <td>{{ attempt.student_full_name }}</td>
            <td>
              <router-link
                :to="`/reviewers/${$route.params.reviewerId}/steps/${step.id}/answers/${attempt.id}`"
                :class="attempt.correct !== null && 'text-secondary'"
              >
                <u>Решение #{{ attempt.id }}</u>
              </router-link>
            </td>
            <td>
              {{ formatCreatedDate(attempt.created_at) }}
              <template v-if="attempt.correct === null">
                <span
                  v-if="attemptIsExpired(attempt.created_at)"
                  :id="`expired-attempts-${attempt.id}`"
                  class="text-danger me-1"
                >
                  <font-awesome-icon icon="circle-exclamation" />
                  <b-tooltip
                    :target="`expired-attempts-${attempt.id}`"
                    triggers="hover"
                  >
                    Время для проверки истекло
                  </b-tooltip>
                </span>
                <span
                  v-else-if="attemptIsRotting(attempt.created_at)"
                  :id="`rotting-attempts-${attempt.id}`"
                  class="text-warning me-1"
                >
                  <font-awesome-icon icon="circle-exclamation" />
                  <b-tooltip
                    :target="`rotting-attempts-${attempt.id}`"
                    triggers="hover"
                  >
                    Меньше суток до окончания срока проверки
                  </b-tooltip>
                </span>
              </template>
            </td>
            <td :class="attemptStatusClass(attempt)">
              {{ attemptStatusString(attempt) }}
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-else
        class="card p-5"
      >
        <div class="card-body p-5 text-center h3 mb-0 text-muted">
          {{ stubText }}
        </div>
      </div>
    </div>
    <div
      v-else
      class="loader text-center"
    >
      <b-spinner />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import {
  formatAnswersCount,
  attemptStatusString,
  attemptIsRotting,
  attemptIsExpired,
} from '@/custom_functions/custom_functions';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ReviewerAnswersPagination from '@/components/reviewers/ReviewerAnswersPagination.vue';
import ReviewerAnswersSetting from '@/components/reviewers/ReviewerAnswersSetting.vue';

export default {
  name: 'ReviewerAnswers',

  components: {
    Breadcrumbs,
    ReviewerAnswersPagination,
    ReviewerAnswersSetting,
  },

  data() {
    return {
      settings: { search: null, sort: 'first-old', filter: 'awaiting' },
    };
  },

  computed: {
    step() {
      return this.$store.getters['reviewers/getStep'];
    },

    breadcrumbsItems() {
      if (this.step) {
        return [
          {
            title: this.step.course.name,
            link: `/reviewers/${this.$route.params.reviewerId}?course=${this.step.course.url}`,
          },
          {
            title: this.step.unit.name,
            link: `/reviewers/${this.$route.params.reviewerId}?course=${this.step.course.url}&unit=${this.step.unit.id}`,
          },
          {
            title: this.step.lesson.title,
            link: `/reviewers/${this.$route.params.reviewerId}?course=${this.step.course.url}&unit=${this.step.unit.id}`,
          },
          { title: `Шаг ${this.step.index}`, link: '' },
        ];
      }
      return null;
    },

    attempts() {
      if (this.step) {
        const { filter, sort } = this.settings;
        const search = this.settings.search
          ? this.settings.search.toLowerCase()
          : null;
        return this.step.attempts
          .filter((attempt) => {
            const matchSearch =
              +attempt.id === +search ||
              attempt.student_login.toLowerCase().includes(search) ||
              attempt.student_full_name.toLowerCase().includes(search);
            const matchFilter =
              (filter === 'awaiting' && attempt.correct === null) ||
              (filter === 'revision' && attempt.correct === false) ||
              (filter === 'success' && attempt.correct) ||
              filter === 'all';
            return (search ? matchSearch : true) && matchFilter;
          })
          .sort((a1, a2) =>
            sort === 'first-old'
              ? +new Date(a2.created_at) - +new Date(a1.created_at)
              : +new Date(a1.created_at) - +new Date(a2.created_at),
          );
      }
      return null;
    },

    stubText() {
      if (this.settings.search) return 'Совпадений не найдено';
      if (this.settings.filter === 'awaiting')
        return 'Нет непроверенных решений';
      if (
        this.settings.filter === 'revision' ||
        this.settings.filter === 'success'
      )
        return 'Вы еще не оценили ни одного решения';
      return 'Нет решений';
    },
  },

  watch: {
    $route() {
      this.$store.dispatch('reviewers/fetchStep', this.$route.params);
      this.$store.dispatch(
        'reviewers/fetchReviewer',
        this.$route.params.reviewerId,
      );
    },
  },

  created() {
    this.$store.dispatch('reviewers/fetchStep', this.$route.params);
    this.$store.dispatch(
      'reviewers/fetchReviewer',
      this.$route.params.reviewerId,
    );
  },

  methods: {
    formatAnswersCount,
    attemptStatusString,
    attemptIsRotting,
    attemptIsExpired,

    formatCreatedDate(date) {
      return moment(date).locale('ru').utcOffset('+3:00').fromNow();
    },

    attemptStatusClass(attempt) {
      if (attempt.correct === null) return 'text-primary';
      if (!attempt.correct) return 'text-warning';
      if (attempt.correct) return 'text-success';
      return '';
    },
  },
};
</script>
