import * as ActionCable from '@rails/actioncable';

export default class Consumer {
  constructor(onMessage, config) {
    this.onMessage = onMessage;
    this.config = config;
  }

  connect() {
    this.rawConsumer = Consumer.createRawConsumer();
    this.createRawSubscription();
  }

  disconnect() {
    this.rawConsumer.disconnect();
  }

  static createRawConsumer() {
    return ActionCable.createConsumer(`${process.env.VUE_APP_API_URL}/cable`);
  }

  createRawSubscription() {
    return this.rawConsumer.subscriptions.create(this.config, {
      received: this.onMessage,
    });
  }
}
