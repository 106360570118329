import { apiClient } from '@/custom_functions/api_client';
import { fetchApiClient } from '@/custom_functions/fetch_api_client';

export default {
  namespaced: true,

  state: {
    products: null,
    productsTotalPages: null,
    httpProcess: false,
    hiddenProductIds: [],
  },

  getters: {
    getProducts: (state) => {
      return state.products
        ? state.products.sort((a, b) => {
            return !!b.favorite === !!a.favorite
              ? b.id - a.id
              : !!b.favorite - !!a.favorite;
          })
        : null;
    },
    getProductsTotalPages: (state) => state.productsTotalPages,
    getHttpProcess: (state) => state.httpProcess,
    getHiddenProductIds: (state) => state.hiddenProductIds,
  },

  actions: {
    async fetchProducts({ commit }, params) {
      commit('updateHttpProcess', true);
      commit('updateProducts', null);
      commit('updateProductsTotalPages', null);

      const queryParams = { ...params, sorting_keys: ['created_at', 'desc'] };
      let searchString = '?filter_query[product_type_eq]=course';

      if (params && params.search) {
        delete queryParams.search;
        searchString += `&filter_query[name_cont]=${params.search}`;
      }

      const response = await apiClient.get(`/v2/products${searchString}`, {
        params: queryParams,
      });

      commit('updateProducts', response.data.products);
      commit('updateProductsTotalPages', response.data.meta.total_pages);
      commit('updateHttpProcess', false);
    },

    async fetchProductsForSelect({ commit }, search) {
      if (search) {
        const queryParams = { sorting_keys: ['created_at', 'desc'] };
        let searchString = `?filter_query[product_type_eq]=course&filter_query[name_cont]=${search}`;
        const response = await apiClient.get(`/v2/products${searchString}`, {
          params: queryParams,
        });
        commit('updateProducts', response.data.products);
        commit('updateProductsTotalPages', response.data.meta.total_pages);
      }
    },

    async deleteProduct({ commit }, id) {
      commit('updateHttpProcess', true);
      await fetchApiClient.delete(`/v2/products/${id}`, { keepalive: true });
      commit('removeProduct', id);
      commit('updateHttpProcess', false);
    },

    async postFavoriteProduct({ commit }, id) {
      commit('updateHttpProcess', true);
      await apiClient.post(`/v2/products/${id}/favorite`);
      commit('updateFavoriteProduct', { id, favorite: true });
      commit('updateHttpProcess', false);
    },

    async deleteFavoriteProduct({ commit }, id) {
      commit('updateHttpProcess', true);
      await apiClient.delete(`/v2/products/${id}/favorite`);
      commit('updateFavoriteProduct', { id, favorite: false });
      commit('updateHttpProcess', false);
    },
  },

  mutations: {
    updateProducts(state, value) {
      state.products = value;
    },

    updateProductsTotalPages(state, value) {
      state.productsTotalPages = value;
    },

    removeProduct(state, id) {
      state.products.splice(
        state.products.findIndex((product) => product.id === id),
        1,
      );
    },

    updateHttpProcess(state, value) {
      state.httpProcess = value;
    },

    updateFavoriteProduct(state, value) {
      const index = state.products.findIndex(
        (product) => product.id === value.id,
      );
      state.products[index].favorite = value.favorite;
    },

    addIdToHiddenProductIds(state, id) {
      state.hiddenProductIds.push(id);
    },

    removeIdFromHiddenProductIds(state, id) {
      state.hiddenProductIds.splice(
        state.hiddenProductIds.findIndex((productId) => productId === id),
        1,
      );
    },
  },
};
