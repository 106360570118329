<template>
  <div
    v-if="course && products && productOwners && speakers && tags"
    class="container"
  >
    <CoursesItemHeader
      courses-item-type="course"
      class="mb-4"
      is-new
      @save="saveCourse"
    />
    <CourseTabs is-new />
  </div>
  <div
    v-else
    class="loader text-center"
  >
    <b-spinner />
  </div>
</template>

<script setup>
import CoursesItemHeader from '@/components/courses/CoursesItemHeader';
import CourseTabs from '@/components/courses/CourseTabs';

import { computed, watch } from 'vue';

import { makeToast } from '@/custom_functions/custom_functions';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();
const route = useRoute();
const router = useRouter();

store.dispatch('course/createCourse', route.params.id);
const course = computed(() => store.getters['course/getCourse']);
const hasErrors = computed(() => store.getters['course/getHasErrors']);

store.dispatch('products/fetchProducts');
const products = computed(() => store.getters['products/getProducts']);

store.dispatch('course/fetchProductOwners');
const productOwners = computed(() => store.getters['course/getProductOwners']);

store.dispatch('speakers/fetchSpeakers');
const speakers = computed(() => store.getters['speakers/getSpeakers']);

store.dispatch('tags/fetchTagsSettings');
const tags = computed(() => store.getters['tags/getTags']);

watch(
  course,
  () => {
    if (hasErrors.value) {
      store.dispatch('course/validateCourse');
    }
  },
  { deep: true },
);

async function saveCourse() {
  await store.dispatch('course/saveCourse');
  if (!hasErrors.value) {
    makeToast('Курс сохранен', 'success');
    router.replace(`/courses/${course.value.id}/edit`);
  }
}
</script>
