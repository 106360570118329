import { computed } from 'vue';
import { useStore } from 'vuex';

export function useDirections() {
  const store = useStore();

  const directionIdOptions = computed(() => {
    const directions = store.getters['directions/getDirections'];
    return directions
      ? directions.map((direction) => ({
          name: direction.name,
          value: direction.id,
        }))
      : null;
  });

  return { directionIdOptions };
}
