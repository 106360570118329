<template>
  <div class="container">
    <h2 class="mb-4">Новая зона</h2>
    <b-form>
      <b-form-group
        label="Значение"
        label-for="zone_value"
        :invalid-feedback="zoneValue.error"
        :state="zoneValue.valid"
        class="mb-2"
      >
        <b-form-input
          id="zone_value"
          v-model="zoneValue.value"
          type="text"
          :state="zoneValue.valid"
          trim
          class="mt-2"
          @blur="validZoneValue"
        />
      </b-form-group>
      <b-form-group
        label="Расположение"
        label-for="zone_location"
        :invalid-feedback="zoneLocation.error"
        :state="zoneLocation.valid"
        class="mb-2"
      >
        <b-form-input
          id="zone_location"
          v-model="zoneLocation.value"
          type="text"
          :state="zoneLocation.valid"
          trim
          class="mt-2"
          @blur="validZoneLocation"
        />
      </b-form-group>
      <b-form-group
        label="Лимит площадок"
        label-for="zone_limit"
        :invalid-feedback="zoneLimit.error"
        :state="zoneLimit.valid"
        class="mb-3"
      >
        <b-form-input
          id="zone_limit"
          v-model="zoneLimit.value"
          min="1"
          type="number"
          :state="zoneLimit.valid"
          trim
          class="mt-2"
          @blur="validZoneLimit"
        />
      </b-form-group>
      <button
        class="btn btn-outline-primary"
        type="submit"
        @click.prevent="createZone"
      >
        Сохранить
      </button>
    </b-form>
  </div>
</template>

<script>
export default {
  name: 'ZonesNew',

  data() {
    return {
      zoneValue: {
        value: null,
        valid: null,
        error: null,
      },
      zoneLocation: {
        value: null,
        valid: null,
        error: null,
      },
      zoneLimit: {
        value: null,
        valid: null,
        error: null,
      },
      zones: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      await this.getZones();
    },

    async createZone() {
      const validZoneValue = this.validZoneValue();
      const validZoneLocation = this.validZoneLocation();
      const validZoneLimit = this.validZoneLimit();

      if (validZoneValue && validZoneLocation && validZoneLimit) {
        let postData = {
          stand_zone: {
            value: this.zoneValue.value,
            location: this.zoneLocation.value,
            limit: this.zoneLimit.value,
          },
        };

        await this.$apiClient.post('/v2/stand_zones', postData);

        await this.$router.replace('/zones');

        this.$root.$bvToast.toast('Зона сохранена', {
          title: 'Уведомление',
          variant: 'primary',
          autoHideDelay: 3000,
        });
      }
    },

    async getZones() {
      const response = await this.$apiClient.get('/v2/stand_zones');

      this.zones = response.data.stand_zones;
    },

    validZoneValue() {
      if (!this.zoneValue.value)
        this.zoneValue.error = 'Значение не может быть пустым';
      else if (
        this.zones.find((zone) => {
          return zone.value == this.zoneValue.value;
        })
      ) {
        this.zoneValue.error = 'Зона с таким значением уже существует';
      } else {
        this.zoneValue.valid = true;
        return true;
      }
      this.zoneValue.valid = false;
      return false;
    },

    validZoneLocation() {
      if (!this.zoneLocation.value) {
        this.zoneLocation.valid = false;
        this.zoneLocation.error = 'Расположение не может быть пустым';
        return false;
      } else {
        this.zoneLocation.valid = true;
        return true;
      }
    },

    validZoneLimit() {
      if (!this.zoneLimit.value)
        this.zoneLimit.error = 'Лимит площадок не может быть пустым';
      else if (isNaN(this.zoneLimit.value))
        this.zoneLimit.error = 'Лимит площадок должен быть числом';
      else if (Number(this.zoneLimit.value) <= 0)
        this.zoneLimit.error = 'Лимит площадок должен быть больше нуля';
      else {
        this.zoneLimit.valid = true;
        return true;
      }
      this.zoneLimit.valid = false;
      return false;
    },
  },
};
</script>
