<template>
  <div>
    <div class="btn-group btn-group-sm mb-3">
      <button
        class="btn"
        :class="multipleSwitchClass(step.data.multipleAnswers)"
        @click="changeQuizeStepMultiple(false)"
      >
        Один
      </button>
      <button
        class="btn"
        :class="multipleSwitchClass(!step.data.multipleAnswers)"
        @click="changeQuizeStepMultiple(true)"
      >
        Несколько
      </button>
    </div>
    <draggable
      v-model:model-value.lazy="step.data.variants"
      item-key="id"
    >
      <template #item="{ element }">
        <div
          class="mb-3 card flex-row p-3 row align-items-center justify-content-end"
        >
          <div
            class="col-1 mb-3 d-flex align-items-center justify-content-around"
          >
            <font-awesome-icon
              icon="grip-vertical"
              class="text-muted drag-icon"
            />
            <input
              v-if="step.data.multipleAnswers"
              v-model="element.value"
              type="checkbox"
              :value="true"
            />
            <input
              v-else
              v-model="selected"
              type="radio"
              :value="element.id"
            />
          </div>

          <div class="col-11 flex-grow-1 d-flex align-items-center mb-3">
            <input
              v-model="element.text"
              class="form-control"
              placeholder="Вариант ответа"
              type="text"
            />

            <button
              class="ms-2 btn btn-link text-danger"
              @click.prevent="deleteVariant(element)"
            >
              <font-awesome-icon icon="xmark" />
            </button>
          </div>

          <div class="col-11 d-flex align-items-center">
            <button
              v-if="element.tip === null"
              class="btn btn-link p-0"
              @click.prevent="element.tip = ''"
            >
              <font-awesome-icon
                icon="plus"
                size="sm"
              />
              включить пояснение
            </button>
            <template v-else>
              <input
                v-model.lazy="element.tip"
                class="form-control"
                placeholder="Текст пояснения"
              />
              <button
                class="ms-2 btn btn-link text-danger"
                @click.prevent="element.tip = null"
              >
                <font-awesome-icon icon="xmark" />
              </button>
            </template>
          </div>
        </div>
      </template>
    </draggable>

    <button
      class="btn btn-outline-primary btn-sm"
      @click="addVariant"
    >
      + добавить вариант
    </button>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'QuizStep',

  props: { step: { type: Object, required: true } },

  data() {
    return { selected: null };
  },

  watch: {
    selected(selected) {
      this.step.data.variants.forEach((variant) => {
        variant.value = variant.id == selected;
      });
    },

    step(value) {
      if (value && value.data.multipleAnswers === false) {
        const selected = value.data.variants.find((variant) => variant.value);
        if (selected) this.selected = selected.id;
      }
    },
  },

  created() {
    if (this.step && this.step.data.multipleAnswers === false) {
      const selected = this.step.data.variants.find((variant) => variant.value);
      if (selected) this.selected = selected.id;
    }
  },

  methods: {
    multipleSwitchClass(value) {
      return value ? 'btn-outline-secondary' : 'btn-primary';
    },

    changeQuizeStepMultiple(value) {
      this.step.data.multipleAnswers = value;
    },

    deleteVariant(element) {
      this.step.data.variants = this.step.data.variants.filter((variant) => {
        if (JSON.stringify(variant) !== JSON.stringify(element)) return variant;
      });
    },

    addVariant() {
      this.step.data.variants.push({
        id: uuidv4(),
        text: '',
        value: false,
        tip: null,
      });
    },
  },
};
</script>

<style>
.drag-icon {
  cursor: grab;
}
</style>
