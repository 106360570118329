import { apiClient } from '@/custom_functions/api_client';
import { fetchApiClient } from '@/custom_functions/fetch_api_client';
import { getCourseTypesWithoutContainers } from '@/custom_functions/course_types';

export default {
  namespaced: true,

  state: {
    courses: null,
    coursesOmitPagination: null,
    coursesTotalPages: null,
    httpProcess: false,
    hiddenCourseIds: [],
  },

  getters: {
    getCourses: (state) => state.courses,
    getCoursesOmitPagination: (state) => state.coursesOmitPagination,
    getCoursesTotalPages: (state) => state.coursesTotalPages,
    getHttpProcess: (state) => state.httpProcess,
    getHiddenCourseIds: (state) => state.hiddenCourseIds,
  },

  actions: {
    async fetchCourses({ commit }, params) {
      commit('updateHttpProcess', true);
      commit('updateCourses', null);
      commit('updateCoursesTotalPages', null);

      const queryParams = { ...params };
      let searchString = '';

      if (params) {
        if (params.search) {
          delete queryParams.search;
          searchString = `?filter_query[id_or_name_cont]=${params.search}`;
        }
        if (params.product_id) {
          queryParams.product_ids = [queryParams.product_id];
          delete queryParams.product_id;
        }
      }

      const response = await apiClient.get(`/v2/courses${searchString}`, {
        params: { ...queryParams, scope: getCourseTypesWithoutContainers() },
      });

      commit('updateCourses', response.data.courses);
      commit('updateCoursesTotalPages', response.data.meta.total_pages);
      commit('updateHttpProcess', false);
    },

    // получение значений для селектов
    async fetchCoursesOmitPagination({ commit }, payload) {
      commit('updateHttpProcess', true);

      const params = {
        omit_pagination: true,
        search: payload?.query || null,
        // Если хочется больше полей - стоит подумать о пагинации, иначе медленно
        fields: ['id', 'name', ...(payload?.fields || [])],
      };

      if (payload?.withoutContainers)
        params.scope = getCourseTypesWithoutContainers();

      const response = await apiClient.get('/v2/courses', { params });
      commit('updateCoursesOmitPagination', response.data.courses);
      commit('updateHttpProcess', false);
    },

    async deleteCourse({ commit }, id) {
      commit('updateHttpProcess', true);
      await fetchApiClient.delete(`/v2/courses/${id}`, { keepalive: true });
      commit('removeCourse', id);
      commit('updateHttpProcess', false);
    },

    async archiveCourse({ commit }, course) {
      commit('updateHttpProcess', true);
      await apiClient.patch(`/v2/courses/${course.id}`, {
        course: { ...course, publication_status: 'archived' },
      });
      commit('removeCourse', course.id);
      commit('updateHttpProcess', false);
    },

    async removeCourseFromArchive({ commit }, course) {
      commit('updateHttpProcess', true);
      await apiClient.patch(`/v2/courses/${course.id}`, {
        course: { ...course, publication_status: 'draft' },
      });
      commit('removeCourse', course.id);
      commit('updateHttpProcess', false);
    },

    async copyCourse({ commit, state }, payload) {
      commit('updateHttpProcess', true);
      const response = await apiClient.post(
        `/v2/courses/${payload.sourceCourseId}/copy`,
        payload.params,
      );
      if (state.courses)
        commit('updateCourses', [response.data.course, ...state.courses]);
      commit('updateHttpProcess', false);
    },
  },

  mutations: {
    updateCourses(state, value) {
      state.courses = value;
    },

    updateCoursesTotalPages(state, value) {
      state.coursesTotalPages = value;
    },

    removeCourse(state, id) {
      state.courses.splice(
        state.courses.findIndex((course) => course.id === id),
        1,
      );
    },

    updateHttpProcess(state, value) {
      state.httpProcess = value;
    },

    addIdToHiddenCourseIds(state, id) {
      state.hiddenCourseIds.push(id);
    },

    removeIdFromHiddenCourseIds(state, id) {
      state.hiddenCourseIds.splice(
        state.hiddenCourseIds.findIndex((courseId) => courseId === id),
        1,
      );
    },

    updateCoursesOmitPagination(state, value) {
      state.coursesOmitPagination = value;
    },
  },
};
