<template>
  <div>
    <input
      :id="id"
      :accept="formatsString"
      class="d-none"
      type="file"
      @change="setFile"
    />

    <div class="d-flex align-items-center w-100">
      <label
        :for="id"
        class="form-label w-100"
      >
        {{ label }}

        <div class="input-group mt-2 mb-1">
          <span class="form-control d-flex justify-content-between">
            {{ inputText }}
            <div v-if="modelValue">
              <button
                class="btn btn-sm btn-danger px-1 py-0"
                @click="resetFile"
              >
                <font-awesome-icon icon="xmark" />
              </button>
            </div>
          </span>
          <span class="btn btn-outline-secondary">{{ buttonText }}</span>
        </div>
        <span class="form-text">{{ tip }}</span>
      </label>
    </div>
  </div>
</template>

<script setup>
import { makeToast } from '@/custom_functions/custom_functions';
import { computed, toRefs } from 'vue';
import { readFile } from '../custom_functions/custom_functions';

const props = defineProps({
  modelValue: {
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  tip: {
    type: String,
    default: '',
  },
  validate: {
    type: Function,
    default: () => [],
  },
});

const { modelValue } = toRefs(props);
const emits = defineEmits(['update:modelValue']);

const formats = ['*']; // Позволяет загружать любые файлы
const formatsString = formats.join(', ');

function validateFile(file) {
  return props.validate(file);
}

function setFile(event) {
  if (event.target.files.length === 0) return;

  const file = event.target.files[0];
  const errors = validateFile(file);
  if (errors.length > 0) return makeToast(errors.join(','), 'danger');

  readFile(file, (result) => emits('update:modelValue', result));
}

function resetFile(event) {
  event.preventDefault();
  emits('update:modelValue', null);
}

const inputText = computed(() =>
  modelValue.value ? 'Файл выбран' : 'Файл не выбран',
);

const buttonText = computed(() =>
  modelValue.value ? 'Изменить файл' : 'Выбрать файл',
);
</script>

<style scoped>
/* Добавьте стили при необходимости */
</style>
