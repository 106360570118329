import { apiClient } from '@/custom_functions/api_client';

export default {
  namespaced: true,

  state: {
    currentWebinar: null,
    currentWebinarQuestions: null,
    webinars: null,
    totalPages: null,
  },

  getters: {
    getCurrentWebinar(state) {
      return state.currentWebinar;
    },

    getCurrentWebinarQuestions(state) {
      return state.currentWebinarQuestions;
    },

    getWebinars(state) {
      return state.webinars;
    },

    getTotalPages(state) {
      return state.totalPages;
    },
  },

  actions: {
    async fetchCurrentWebinar({ commit }, webinarId) {
      const response = await apiClient.get(`/v2/webinars/${webinarId}`);
      commit('updateCurrentWebinar', response.data.webinar);
    },

    async fetchCurrentWebinarQuestions({ commit }, webinarId) {
      const response = await apiClient.get(
        `/v2/webinars/${webinarId}/questions`,
      );
      commit('updateCurrentWebinarQuestions', response.data.questions);
    },

    async fetchWebinars({ commit }, payload) {
      let response;
      if (payload)
        response = await apiClient.get('v2/webinars', {
          params: { page: payload.page || 1, search: payload.search },
        });
      else response = await apiClient.get('v2/webinars');
      commit('updateWebinars', response.data.webinars);
      commit('updateTotalPages', response.data.meta.total_pages);
    },
  },

  mutations: {
    updateCurrentWebinar(state, webinar) {
      state.currentWebinar = webinar;
    },

    updateCurrentWebinarQuestions(state, questions) {
      state.currentWebinarQuestions = questions;
    },

    updateWebinars(state, webinars) {
      state.webinars = webinars;
    },

    updateTotalPages(state, totalPages) {
      state.totalPages = totalPages;
    },
  },
};
