<template>
  <div
    v-if="sessions"
    class="px-3"
  >
    <div
      class="d-flex no-block align-items-center justify-content-between mb-4"
    >
      <h2 class="mb-0">Сессии</h2>
      <StandsSessionsSettings :params="params" />
    </div>

    <table
      class="table table-sm table-responsive-lg mb-4"
      :class="{ 'table-hover': sessions.length }"
    >
      <thead>
        <tr>
          <th
            v-for="item in tableHeaderData"
            :key="item.name"
            scope="col"
          >
            <a
              v-if="item.name"
              href="#"
              class="sort-button text-primary"
              @click.prevent="item.name && sortSessions(item.name)"
            >
              {{ item.title }}
              <font-awesome-icon :icon="renderCarret(item.name)" />
            </a>
            <span
              v-else
              class="sort-button text-primary"
            >
              {{ item.title }}
            </span>
          </th>
        </tr>
      </thead>
      <tbody v-if="sessions.length">
        <tr
          v-for="session in sessions"
          :key="session.id"
          class="align-middle"
        >
          <th scope="row">
            <router-link :to="`/stands_sessions/${session.id}`">
              {{ session.id }}
            </router-link>
          </th>
          <td>
            {{ session.login }}
            <br />
            {{ session.student_email }}
          </td>
          <td>
            {{ session.zone_value }}
            <br />
            {{ session.stand_ip }}
          </td>
          <td>
            <router-link :to="`/courses/${session.course_url}/edit`">
              {{ session.course_name }}
            </router-link>
          </td>
          <td>{{ session.unit_name }}</td>
          <td class="small">{{ formatDateTime(session.date_from) }}</td>
          <td class="small">{{ formatDateTime(session.date_to) }}</td>
          <td class="small">{{ formatDateTime(session.started_at) }}</td>
          <td class="small">
            <span v-show="session.state !== 'running'">
              {{ formatDateTime(session.stopped_at) }}
            </span>
            <b-button
              v-show="session.state === 'running'"
              :id="'stop-session-' + session.id"
              size="sm"
              variant="outline-primary"
              @click="stopSession(session.unit_id, session.participation_id)"
            >
              <font-awesome-icon
                icon="stop"
                size="sm"
              />
            </b-button>
            <b-tooltip
              :target="'stop-session-' + session.id"
              triggers="hover"
              boundary="window"
            >
              Остановить стенд
            </b-tooltip>
          </td>
          <td>{{ formatSessionState(session.state) }}</td>
        </tr>
      </tbody>

      <tbody v-else>
        <tr>
          <td
            colspan="9"
            class="text-muted"
          >
            Нет сессий с выбранными параметрами
          </td>
        </tr>
      </tbody>
    </table>

    <b-pagination
      v-if="totalPages > 1"
      v-model="$route.params.page"
      :total-rows="sessions.length * totalPages"
      :per-page="sessions.length"
      class="position-sticky pagination"
      @change="goToPage($event)"
    />
  </div>

  <div
    v-else
    key="loader"
    class="loader text-center"
  >
    <b-spinner />
  </div>
</template>

<script>
import {
  formatDateTime,
  formatSessionState,
} from '@/custom_functions/custom_functions.js';
import StandsSessionsSettings from '@/components/stands_sessions/StandsSessionsSettings.vue';

export default {
  name: 'StandsSessionsIndex',

  components: { StandsSessionsSettings },

  data() {
    return {
      params: { filter: 'running', sort: null, query: null, page: 1 },
      tableHeaderData: [
        { title: 'ID', name: 'id' },
        { title: 'Студент', name: 'user_login' },
        { title: 'IP', name: 'user_stand_ip' },
        { title: 'Курс', name: 'course_name' },
        { title: 'Задание', name: 'unit_name' },
        { title: 'Дата начала', name: 'date_from' },
        { title: 'Дата конца', name: 'date_to' },
        { title: 'Стартовала', name: 'created_at' },
        { title: 'Остановилась', name: 'stopped_at' },
        { title: 'Статус', name: null },
      ],
    };
  },

  computed: {
    sessions() {
      return this.$store.getters['standsSessions/getSessions'];
    },

    totalPages() {
      return this.$store.getters['standsSessions/getSessionsTotalPages'];
    },
  },

  created() {
    if (this.$route.params.page)
      this.params.page = Number(this.$route.params.page);
    this.$store.dispatch('standsSessions/fetchSessions', this.params);
    this.$store.dispatch('standsSessions/connectSessionsConsumer');
  },

  unmounted() {
    this.$store.dispatch('standsSessions/disconnectSessionsConsumer');
  },

  watch: {
    $route(value) {
      if (this.params.page !== Number(value.params.page))
        this.params.page = Number(value.params.page);
    },
  },

  methods: {
    formatSessionState,
    formatDateTime,

    async sortSessions(sortBy) {
      if (this.params.sort && this.params.sort.sortBy === sortBy)
        this.params.sort.order =
          this.params.sort.order === 'asc' ? 'desc' : 'asc';
      else this.params.sort = { sortBy: sortBy, order: 'asc' };
      if (this.$route.params.page != 1)
        await this.$router.replace({ params: { page: 1 } });
      this.$store.dispatch('standsSessions/fetchSessions', this.params);
    },

    async goToPage(page) {
      if (page !== Number(this.$route.params.page)) {
        await this.$router.replace({ params: { page } });
        this.$store.dispatch('standsSessions/fetchSessions', this.params);
      }
    },

    renderCarret(value) {
      if (
        this.params.sort &&
        this.params.sort.order === 'asc' &&
        this.params.sort.sortBy === value
      )
        return 'caret-up';
      return 'caret-down';
    },

    async stopSession(unitId, participationId) {
      await this.$apiClient.delete('/v2/stand_sessions', {
        params: { unit_id: unitId, participation_id: participationId },
      });
      this.$store.dispatch('standsSessions/fetchSessions', this.params);
    },
  },
};
</script>

<style scoped>
.sort-button svg {
  opacity: 0;
  transition: 0.3s;
  margin-bottom: 1px;
}

.sort-button:hover svg {
  opacity: 1;
}

.pagination {
  bottom: 10px;
}
</style>
